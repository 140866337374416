import {  SET_LAYERS_GEOSERVER_PUBLIC } from '../actions/actionTypes';

const initialStateLayersGeoserverPublic = {
  layersGeoserverPublic: [],
  workspacesPublic: [],
  treeMenuPublic: []
};



export const layersGeoserverPublicReducer = (state = initialStateLayersGeoserverPublic , action) => {

  switch (action.type) {
    case SET_LAYERS_GEOSERVER_PUBLIC:
      return { ...state, layersGeoserverPublic: action.payload.layersGeoserverPublic, workspacesPublic: action.payload.workspacesPublic, treeMenuPublic: action.payload.treeMenuPublic};
    default:
      return state;
  }
}
