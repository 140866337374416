import WMTS, { optionsFromCapabilities } from 'ol/source/WMTS.js';
import WMTSCapabilities from 'ol/format/WMTSCapabilities.js';
import proj4 from 'proj4';
import { register } from 'ol/proj/proj4';
import { text } from '@fortawesome/fontawesome-svg-core';

const mountLayerTimeseries = (keywords) => {
  const keyword = keywords.filter(k => (k.includes("LAYER_TIMESERIES")));
  if (keyword.length > 0) {
    try {
      return {
        supported: true,
        year_start: parseInt(keywords.filter(k => (k.includes("LAYER_TIMESERIES_START_")))[0].replace("LAYER_TIMESERIES_START_", "").substring(0, 4)),
        year_end: parseInt(keywords.filter(k => (k.includes("LAYER_TIMESERIES_END_")))[0].replace("LAYER_TIMESERIES_END_", "").substring(0, 4))
      }

    } catch (error) {
      return { supported: false }
    }

  }
  return { supported: false }

}
export const loadGetCapabilities = (params) => {
  if (params.props.layersGeoserver.length > 0) return true;

  let layersGeoserver = [];
  const url = `${process.env.REACT_APP_GEOSERVER_URL}/ows?service=wms&version=1.3.0&request=GetCapabilities`
  const username = window.sessionStorage.getItem('username');
  const password = window.sessionStorage.getItem('password');

  let headers = {};

  if (username && password) {
    headers = {
      'Authorization': `Basic ${btoa(`${username}:${password}`)}`,
    }
  }

  fetch(url, { headers: new Headers(headers) })
    .then((response) => response.text())
    .then((text) => {
      let parser = new DOMParser();
      let xmlDoc = parser.parseFromString(text, "text/xml");
      const layers = Array.from(xmlDoc.getElementsByTagName('Layer'));
      let tree = [];
      let layersTotal = []
      let workspacesTotal = []
      let treeTotal = []

      proj4.defs(
        'EPSG:31984',
        '+proj=utm +zone=24 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs'
      );
      register(proj4);

      layers.forEach(function (layer, index) {
        try {
          function addNewItem(item, parent) {
            function checkNotExist(subitens, item) {
              return !subitens.find(j => j.item == item)
            }

            function searchForItem(item, tree) {
              tree.filter(i => {
                if (i.id == parent && checkNotExist(i.subitens, item)) {
                  i.subitens.push({
                    item: item,
                    parent: parent,
                    id: parent + '-' + item,
                    subitens: [],
                  })
                }
                if (i.subitens.length > 0) {
                  return searchForItem(item, i.subitens)
                }
              })
            }

            if (parent == null && checkNotExist(tree, item)) {
              let pi = tree.push({
                item: item,
                parent: null,
                id: item,
                subitens: []
              })
            }

            searchForItem(item, tree);
          }

          function buildItems(itens, level, total, parent) {
            try {
              if (level > 0) {
                if (parent == null) {
                  parent = itens[level - 1]
                } else {
                  parent = parent + '-' + itens[level - 1]
                }
              }

              addNewItem(itens[level], parent)

              level = level + 1
              if (level < total) {
                buildItems(itens, level, total, parent)
              }
            } catch (error) {
              // console.error(error)
            }
          }

          if (index !== 0) {
            let keywordList = [];
            for (let i = 0; i < layer.getElementsByTagName('Keyword').length; i++) {
              keywordList.push(layer.getElementsByTagName('Keyword')[i].textContent)
            }

            let listaComMenus = keywordList.filter(menu => menu.match(/^menu_/));
            var listaComMenusSeparados = [];

            listaComMenus.forEach(itemComMenu => {
              let itemSemMenuOpcao = itemComMenu.split('_');
              itemSemMenuOpcao.shift();

              listaComMenusSeparados.push(itemSemMenuOpcao);
            });

            listaComMenusSeparados.forEach(function (item) {
              let lvl = 0
              buildItems(item, lvl, item.length, null)
            });

            let workspaceGeoserver = layer.getElementsByTagName('Name')[0].textContent.split(':')[0]
            let workspaceLevels = workspaceGeoserver.split('-')
            let workspaceLevelsLength = workspaceLevels.length
            let workspace = workspaceLevels.pop()
            let parentWorkspace = workspaceLevels.pop()

            let legendUrl = null;
            if (layer.getElementsByTagName('LegendURL').length > 0) {
              legendUrl = layer.getElementsByTagName('LegendURL')[0].getElementsByTagName('OnlineResource')[0].attributes['xlink:href'].value
              // Tratamento para a camada está usando uma legenda customizada
              if (legendUrl.match(/http/g).length > 1) {
                legendUrl = legendUrl.match(/\/(http.*)/g)[0].substring(1, legendUrl.length) + "#"
              }
            }

            let style = null;
            if (layer.getElementsByTagName('Style').length > 0) {
              style = layer.getElementsByTagName('Style')[0].getElementsByTagName('Name')[0].textContent
            }

            if (keywordList.includes(params.filter)) {
              let dataLink = []
              if (keywordList.includes('USE_DATAURL_LINK')) {
                for (let i = 0; i < layer.getElementsByTagName('DataURL').length; i++) {
                  dataLink.push(layer.getElementsByTagName('DataURL')[i].getElementsByTagName('OnlineResource')[0].attributes['xlink:href'].value)
                }
              }
              
              let metadataUrl = null;
              let metadataUrlXml = null;
              let metadataId = null;
              let options = null
              if (layer.getElementsByTagName('MetadataURL').length > 0) {
                metadataUrl = layer.getElementsByTagName('MetadataURL')[0].getElementsByTagName('OnlineResource')[0].attributes['xlink:href'].value
                if (metadataUrl.match(/metadata\/(.*)/)) {
                  metadataId = metadataUrl.match(/metadata\/(.*)/)[1]
                  metadataUrlXml = `${process.env.REACT_APP_GEONETWORK_URL}/api/records/${metadataId}/formatters/xml`
                }
              }
              
              layersGeoserver.push({
                layerName: layer.getElementsByTagName('Title')[0].textContent,
                layerSource: layer.getElementsByTagName('Name')[0].textContent,
                workspaceGeoserver: workspaceGeoserver,
                workspace: workspace,
                parentWorkspace: parentWorkspace,
                workspaceLevelsLength: workspaceLevelsLength,
                layerTitle: layer.getElementsByTagName('Title')[0].textContent,
                abstract: layer.getElementsByTagName('Abstract')[0].textContent,
                legendUrl: legendUrl,
                style: style,
                timeseries: mountLayerTimeseries(keywordList),
                keywordList: keywordList,
                orderList: keywordList,
                dataLink: dataLink,
                metadata: {
                  metadataUrl: metadataUrl,
                  metadataUrlXml: metadataUrlXml,
                  identifier: metadataId
                },
                options: options
              })

              layersTotal.push({
                layerName: layer.getElementsByTagName('Title')[0].textContent,
                layerSource: layer.getElementsByTagName('Name')[0].textContent,
                workspaceGeoserver: workspaceGeoserver,
                workspace: workspace,
                parentWorkspace: parentWorkspace,
                workspaceLevelsLength: workspaceLevelsLength,
                layerTitle: layer.getElementsByTagName('Title')[0].textContent,
                abstract: layer.getElementsByTagName('Abstract')[0].textContent,
                legendUrl: legendUrl,
                style: style,
                timeseries: mountLayerTimeseries(keywordList),
                keywordList: keywordList,
                orderList: keywordList,
                dataLink: dataLink,
                metadata: {
                  metadataUrl: metadataUrl,
                  metadataUrlXml: metadataUrlXml,
                  identifier: metadataId
                },
                options: options
              })
            }
          }
        } catch (e) { }

        const comparar = function (a, b) {
          if (a.layerName < b.layerName) {
            return -1;
          }
          if (a.layerName > b.layerName) {
            return 1;
          }
          // a deve ser igual a b
          return 0;
        }

        let workspaces = new Set(layersGeoserver.map(layer => layer['workspaceGeoserver'].split('-')[0]).sort());
        params.props.setLayersGeoserver({
          workspaces: [...workspaces],
          layersGeoserver: layersGeoserver.sort(comparar),
          treeMenu: tree
        });

        workspacesTotal = workspaces
        treeTotal = tree

        if (params.forceMapComponentUpdate) params.forceMapComponentUpdate()
      })

      let layersWMTSIndex = []

      layersTotal.forEach((layer, i) => {
        if (layer.keywordList.includes('WMTS_VISUALIZADOR')) {
          layersWMTSIndex.push(i)
        }
      })


      fetch(`${process.env.REACT_APP_GEOSERVER_URL}/gwc/service/wmts?service=WMTS&version=1.1.1&request=GetCapabilities`, { headers: new Headers(headers) })
        .then((response) => {
          return response.text()
        })
        .then((text) => {
          const comparar = function (a, b) {
            if (a.layerName < b.layerName) {
              return -1;
            }
            if (a.layerName > b.layerName) {
              return 1;
            }
            // a deve ser igual a b
            return 0;
          }

          const parser = new WMTSCapabilities();
          let result = parser.read(text)
          let options;
          
          

          layersWMTSIndex.forEach((index) => {
            options = optionsFromCapabilities(result, { layer: layersTotal[index].layerSource, matrixSet: 'EPSG:31984', });
            layersTotal[index].options = options
          })
          
          // console.log(layersGeoserver)
          
          params.props.setLayersGeoserver({
            workspaces: [...workspacesTotal],
            layersGeoserver: layersTotal.sort(comparar),
            treeMenu: treeTotal
          });

          if (params.forceMapComponentUpdate) params.forceMapComponentUpdate()
        })
    })
    .catch(function (err) {
      // console.error(err)
    });

}