import React from 'react';
import ListGroup from '../../ListGroup.js'
import ListItem from '../../ListItem'
import { capitalize } from '../../../../utils/camelcase.js';


const builderMenu = (subitem, layersGeoserver, props,display) => {
  const { active } = props;

  let menu = `menu_${subitem.id.replaceAll('-','_')}`
  const layers_inside_menu = layersGeoserver.filter((i) => i.keywordList.includes(menu))
  const layers_inside_menu_skip_download = layers_inside_menu.filter((i) => (i.keywordList.includes('SKIP_WMS') && i.keywordList.includes('SKIP_WFS')))
  // console.log(layers_inside_menu)
  // console.log(layers_inside_menu_skip_download)
  let display_group = props.display == undefined ? false : props.display
  
  if (layers_inside_menu.length == 0 || hasSubGroups(subitem)) {
    display_group = true
  } else if (layers_inside_menu.length > layers_inside_menu_skip_download.length){
    display_group = true
  }
  // props.display = display_group


  if (hasSubGroups(subitem)) {

    return subitem.subitens.map((item) =>
    <ListGroup key={item.id} groupName={capitalize(item.item)} active={active} display={display_group} menu_id={subitem.id}>
        {builderMenu(item, layersGeoserver, props,display_group)}
        {builderGroupItems(item, layersGeoserver, props)}
      </ListGroup>)
  }
}

const hasSubGroups = (level) => level.subitens.length > 0;

const setTextAbstractMetadata = (layer, metadataLayers) => {
  let textAbstractMetadata;
  if (metadataLayers.length > 0) {
    try {
      textAbstractMetadata = metadataLayers.find((i) => (i.identifier === layer.metadata.identifier)).description
    } catch {
      textAbstractMetadata = ''
    }
  }
  return textAbstractMetadata
}

const renderListItem = (layer, props) => {
  const { updateActiveLayers, showCheckbox, showDownloads,
    showAbstractInfo, showLinkGeoservicos, metadataLayers, activeLayers, menuType, slug} = props;
  let dataLink = null
  if(layer.keywordList.includes('USE_DATAURL_LINK')){
    dataLink = layer.dataLink
  }
  let requestType = layer.keywordList.includes('WFS_VISUALIZADOR') ? 'WFS' : 'WMS';
  let choropleth = layer.keywordList.includes('CHOROPLETH_VISUALIZADOR');
  let { abstract } = layer;

  if (menuType == "CamadasAtivasListMenu") {
    let layerAtivas = activeLayers.filter((activeLayer) => {
      return activeLayer.values_.layerSource == layer.layerSource
    });
  
    if (layerAtivas.length > 0) {
      
      return renderItem(layer, updateActiveLayers, requestType, choropleth, 
        showCheckbox, showDownloads, showLinkGeoservicos, showAbstractInfo, abstract, metadataLayers , slug);
    } else {
      
      return <div></div>;
    }
  } else {
    
    return renderItem(layer, updateActiveLayers, requestType, choropleth, 
      showCheckbox, showDownloads, showLinkGeoservicos, showAbstractInfo, abstract, metadataLayers, slug);;
  }
}

const renderItem = (layer, updateActiveLayers, requestType, choropleth, 
  showCheckbox, showDownloads, showLinkGeoservicos, showAbstractInfo, abstract, metadataLayers, slug) => {
  
  let dataLink = null
  if(layer.keywordList.includes('USE_DATAURL_LINK')){
    dataLink = layer.dataLink
  }

  if(layer.keywordList.includes('SKIP_WFS') && layer.keywordList.includes('SKIP_WMS')){
    return (<></>);
  }

  return <ListItem
    key={`menu-${layer.layerSource.replace(':', '-')}`}
    idName={`menu-${layer.layerSource.replace(':', '-')}`}
    layerSource={layer.layerSource}
    layerName={layer.layerName}
    updateActiveLayers={updateActiveLayers}
    requestType={requestType}
    choropleth={choropleth}
    keywordList={layer.keywordList}
    showCheckbox={showCheckbox === undefined ? true : showCheckbox}
    showDownloads={showDownloads === undefined ? false : showDownloads}
    showLinkGeoservicos={showLinkGeoservicos === undefined ? false : showLinkGeoservicos}
    showAbstractInfo={showAbstractInfo === undefined ? false : showAbstractInfo}
    textAbstractMetadata={abstract || setTextAbstractMetadata(layer, metadataLayers)}
    slug={slug}
    dataLink={dataLink}
  />
}

const getLayersByKeyword = (itemId, layersGeoserver) => {
  // Ex: menu_Cartografia Base_AP_LEF
  let keyword = `menu_${itemId.replaceAll('-', '_')}`

  // Filtrar as camadas a partir de item dentro de layersGeoserver
  return layersGeoserver.filter((layer) => layer.keywordList.includes(keyword))
}

const builderGroupItems = (item, layersGeoserver, props) => {



  return getLayersByKeyword(item.id, layersGeoserver).map(i => <li>
    {renderListItem(i, props)}
  </li>)
}

export {
  builderMenu,
  builderGroupItems,
  renderListItem
}