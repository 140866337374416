
import React from 'react';
import './card.css';

class Card extends React.Component {
  render() {
    const {title, description, thumbnail, url} =  this.props;
  return (
    <div className="col-md-6 col-sm-12 col-lg-4">
      <div className="card mb-4 shadow card-metadata">
        <img className='img-fluid p-2' height="220" src={thumbnail}/>
        <div className="card-body">
          <h5 className="card-title">{title}</h5>
          <p className="card-text text-justify">
            {description}
          </p>
        </div>
        <div className='card-footer'>
          <div className="d-flex justify-content-between align-items-center ">
            <div className="btn-group">
              {/* <button type="button" className="btn btn-sm btn-outline-secondary">Ver</button> */}
              <a href={url} target="_blank" className="btn btn-sm btn-outline-secondary">Metadados</a>
            </div>
            {/* <small className="text-muted">{`Publicação: ${date}`}</small> */}
          </div>
        </div>
      </div>
    </div>
  );
  }
 }

 export default Card;