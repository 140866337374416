import React from 'react';
import HeaderNav from '../components/header';
import Carousel from '../components/home/Carousel';
import Card from '../components/home/Card';
import '../components/home/subnav.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setLayersGeoserverPublic } from '../actions/layersGeoservePublicActions';
import CamadasDisponiveisListMenu from '../components/SideNav/ListMenu/AdicionarCamada/CamadasDisponiveisListMenu';
import CamadasPublicasDisponiveisListMenu from '../components/SideNav/ListMenu/AdicionarCamada/CamadasPublicasDisponiveisListMenu';
import { loadMetadataGeoNetwork } from '../utils/loadMetadataGeoNetwork';
import { setMetadataLayers } from '../actions/metadataLayersActions';
import { loadGetCapabilities } from '../utils/loadGetCapabilities';
import { loadGetPublicCapabilities } from '../utils/loadGetPublicCapabilities';
import LoginForm from '../components/Login/Form';
import Navbar from '../components/home/Navbar';
import Footer from '../components/home/Footer';
import { withRouter } from 'react-router-dom';
import './Geoservicos.css'

import {
  NavLink
} from "react-router-dom";

class Camadas extends React.Component {

  constructor(props) {
    super(props)
    const InitialState = {
    }
    loadGetPublicCapabilities({ props: this.props, filter: 'ACCESSIBLE_VISUALIZADOR' });
    loadMetadataGeoNetwork({ props: this.props })
  }

  handleLogin = () => {
    this.setState({ last_login: Date.now() })
    // this.props.map.updateSize(); 
    // if (document.getElementById('close-card-pmf')) {
    //   document.getElementById('close-card-pmf').click();
    // }

    // FIXME: Resolve situation when not rerender components after login
    window.location.reload();
  }

  componentDidMount() {
    
    var checkExist = setInterval(function () {
      if (document.getElementsByClassName('list-group-flush').length > 1) {
        document.getElementsByClassName('list-group-flush')[1].classList.add('active')
        clearInterval(checkExist);
      }      
    }, 100);
  }
  
  
  render() {
    const { slug } = this.props.match.params
    return (
      <>
        <Navbar handleLogin={this.handleLogin}/>
        <div className="Geoservicos container bg-white">
          {/* <HeaderNav menu='home' customClass='fixed-top' handleLogin={this.handleLogin}/> */}
          <LoginForm handleLogin={this.handleLogin} />
          {/* <Carousel/> */}
          {/* <nav className="nav navbar-dark subnavbar-pmf justify-content-center"> */}
          {/* <div className='container'> */}
          {/* <NavLink activeClassName='active' exact={true} className="nav-link" to='/'>Mapas Disponíveis</NavLink>
            <NavLink activeClassName='active' exact={true} className="nav-link" to='/geoservicos'>Geoserviços</NavLink>
          <NavLink activeClassName='active' exact={true} className="nav-link" to='/downloads'>Downloads</NavLink> */}
          {/* </div> */}
          {/* </nav> */}
          <h1 className='text-center py-2'>Camadas Temáticas</h1>
          <div className='py-5'>
            <div className='container' >
              <div className='mx-auto'>
                <CamadasPublicasDisponiveisListMenu showAbstractInfo={true} slug={slug} showCheckbox={false} showLinkGeoservicos={true} />
              </div>
            </div>
          </div>
        </div>
        {/* <div className='container-fluid'>
          <Footer />
        </div> */}
      </>
    );
  }
}

const mapStateToProps = (store, ownProps) => {
  
  return { layersGeoserverPublic: store.layersGeoserverPublicState.layersGeoserverPublic, metadataLayers: store.metadataLayersState.metadata}
}
const mapDispatchToProps = dispatch =>
  bindActionCreators({ setLayersGeoserverPublic, setMetadataLayers }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Camadas));