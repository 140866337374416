import { UPDATE_MAP, TOGGLE_VISIBLE_LAYER, HIDE_LAYER, SHOW_LAYER, SET_OPACITY, SET_BBOX_MAP, SET_STYLE_LAYER} from '../actions/actionTypes';

const initialStateLayers = {
  layers: [],
  last_updated: null,
  bbox: []
};


export const layersReducer = (state = initialStateLayers , action) => {
  let layers = state.layers

  const renderSliderTimeseries = (layer) => {
    
    const { year_start, year_end } = layer.getProperties().timeseries;
    let year_length = year_end - year_start
    let dates = []
    for (let index = 0; index <= year_length; index++) {
      dates.push(`${year_start + index}-01-01T00:00:00.000Z`)
    }

    var sliderRange = document.getElementById("myRange");
    sliderRange.max = dates.length-1;
    var dateValue = document.getElementById("date_value");
    dateValue.innerHTML = dates[sliderRange.value].slice(0,4);

    // Update the current slider value (each time you drag the slider handle)
    sliderRange.oninput = function() {
      dateValue.innerHTML = dates[sliderRange.value].slice(0,4);
      layer.getSource().updateParams({'TIME': dates[this.value]});
    }
  }

  switch (action.type) {
    case SET_STYLE_LAYER:
      layers.forEach(function (layer) {
        if (layer.get('layerSource') === action.layerSource) {
          layer.set('currentStyle', action.style)
        }
      });
        return {...state, layers: layers, last_updated: new Date() };
    case TOGGLE_VISIBLE_LAYER:
      layers.forEach(function (layer) {
        if (layer.get('layerSource') === action.layerSource) {
          layer.setVisible(!layer.getVisible());         
          // Mostrar o slidecontainer quando a camada tiver o parametro TIME
          try {
            if (layer.getProperties().timeseries.supported) {
              const sliderContainer = document.getElementsByClassName("slidecontainer")[0];
              
              sliderContainer.style.display = sliderContainer.style.display == "block" ? "none" : "block"

              // document.getElementsByClassName("slidecontainer")[0].style.display = "block";
              renderSliderTimeseries(layer)
            }
          } catch (error) {
            // console.log('error')
          }
        }
      });
      return {...state, layers: layers, last_updated: new Date() };
    case HIDE_LAYER:
      layers.forEach(function (layer) {
        if (layer.get('layerSource') === action.layerSource) {
          layer.setVisible(false);
        }
      });
        return {...state, layers: layers, last_updated: new Date() };
    case SHOW_LAYER:
        layers.forEach(function (layer) {
          if (layer.get('layerSource') === action.layerSource) {
            layer.setVisible(true);
          }
        });
          return {...state, layers: layers, last_updated: new Date() };
    case SET_OPACITY:
      layers.forEach(function (layer) {
        if (layer.get('layerSource') === action.layerSource) {
          layer.setOpacity(action.opacity);
        }
      });
        return {...state, layers: layers, last_updated: new Date() };
    case UPDATE_MAP:
      return { ...state, layers: action.map.getLayers(), last_updated: new Date(), map: action.map };
    case SET_BBOX_MAP:
      return { ...state, bbox: action.bbox};
    default:
      return state;
  }
}
