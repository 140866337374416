import {  SET_LAYERS_GEOSERVER} from '../actions/actionTypes';

const initialStateLayersGeoserver = {
  layersGeoserver: [],
  workspaces: [],
  treeMenu: []
};



export const layersGeoserverReducer = (state = initialStateLayersGeoserver , action) => {

  switch (action.type) {
    case SET_LAYERS_GEOSERVER:
      return { ...state, layersGeoserver: action.payload.layersGeoserver, workspaces: action.payload.workspaces, treeMenu: action.payload.treeMenu};
    default:
      return state;
  }
}
