import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const SideButton = (props) => {
  return (
      <button
        type='button'
        id={props.idButton}
        className={`btn btn-sm btn-circle btn-circle-trigger-content btn-primary btn-pmf shadow ${props.btnClasses}`}
        data-contentid={props.dataContentId}
        onClick={ () => props.onClick(
          props.onClickParams.modalTitle,
          props.onClickParams.tabTitle,
          props.onClickParams.listMenu,
          props.onClickParams.search
        )}
      style={{bakgroundColor: '#4CBABF'}}>
        <FontAwesomeIcon  icon={props.icon} ></FontAwesomeIcon>
      </button>
    )
}

export default SideButton;