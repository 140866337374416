const palestrantes = [
  {
    evento: "Webinar IDE-SEFIN",
    titulo: "Apresentação da temática do Webinar e apresentação dos palestrantes (Adriana Leal)",
    descricao: "",
    data: "01/10/2020",
    url: "/videos/Webinar_IDE-SEFIN_01_Apresentacao.webm",
  },
  {
    evento: "Webinar IDE-SEFIN",
    titulo: "Parte 1 – As IDE e o CTM: Evolução e perspectivas (Prof. Dr. Diego Erba)",
    descricao: "Iniciamos o Webinar com a apresentação do Prof. Dr. Diego Erba que compartilhou com o público a evolução dos Cadastros Territoriais Multifinalitários (CTM), expondo experiências bem sucedidas ocorridas na América Latina que impactaram positivamente a consolidação do planejamento de cidades inteligentes, sobretudo com sua aplicação integrada às tecnologias de informação geográfica e observatórios de valores que beneficiam, por exemplo, a gestão de tributos imobiliários e outros serviços municipais.",
    data: "01/10/2020",
    url: "/videos/Webinar_IDE-SEFIN_02_Diego_Erba.webm",
  },
  {
    evento: "Webinar IDE-SEFIN",
    titulo: "Parte 2 – Projeto de Implantação da Infraestrutura de Dados Espaciais da SEFIN – IDE-SEFIN (Auditor Augusto Oliveira)",
    descricao: "Após explanação do Prof. Dr. Diego Erba, o gerente do projeto, Augusto Oliveira, apresentou informações gerais sobre a implantação da IDE-SEFIN, tais como: i) objetivos; ii) arquitetura e iii) camadas temáticas presentes no geoportal. Destacou a importância da IDE como plataforma para disponibilização de dados de origem da Secretaria Municipal das Finanças, sobretudo por ter sido idealizada como uma IDE temática, apresentando o viés tributário e cadastral; Salientou o apoio dado pela instituição na disponibilização de colaboradores de diferentes áreas da Secretaria e de recursos aplicados no projeto, sendo toda implantação feita com expedientes próprios, mediante capacitação da equipe para construção e implantação da infraestrutura.",
    data: "01/10/2020",
    url: "/videos/Webinar_IDE-SEFIN_03_Augusto_Oliveira.webm",
  },
  {
    evento: "Webinar IDE-SEFIN",
    titulo: "Parte 3 – Apresentação dos produtos da IDE-SEFIN: Portal da IDE-SEFIN, Visualizador de Mapas, Geosserviços e Catálogos de Metadados (Luan Victor)",
    descricao: "Logo após a apresentação do gerente, o responsável técnico, Luan Victor, apresentou os resultados da implantação da IDE-SEFIN, dentre os quais estão: Portal da IDE-SEFIN, Visualizador de Mapas, Geosserviços e Catálogos de Metadados. Foi divulgada a forma de acesso aos canais da IDE-SEFIN e demonstrado como os usuários da Prefeitura, que possuem acesso a plataforma, podem consumir as informações espaciais disponibilizadas no geoportal.",
    data: "01/10/2020",
    url: "/videos/Webinar_IDE-SEFIN_04_Luan_Noberto.webm",
  },
  {
    evento: "Webinar IDE-SEFIN",
    titulo: "Parte 4 – Desafio, difusão e divulgação: normas e acordos institucionais (Fernanda Farias)",
    descricao: "Encerrando a apresentação, a chefe do Cadastro Imobiliário do Município (CIM), Fernanda Farias, apresentou os marcos da evolução implantada pela Secretaria das Finanças na obtenção e produção de dados espaciais a partir de investimentos em geotecnologias aplicadas ao CIM , tais como o mapeamento aerofotogramétrico, sensoriamento remoto, utilização de sistema de informações geográficas (SIG) e a implantação do Sistema de informações Territoriais de Fortaleza (SITFOR), demonstrando a importância que a IDE tem como ferramenta de gestão, compartilhamento e integração desses dados com outros entes da Prefeitura Municipal de Fortaleza, órgãos governamentais, academia e sociedade em geral.",
    data: "01/10/2020",
    url: "/videos/Webinar_IDE-SEFIN_05_Fernanda_Gimenes.webm",
  },
  {
    evento: "Webinar IDE-SEFIN",
    titulo: "Perguntas e agradecimentos",
    descricao: "",
    data: "01/10/2020",
    url: "/videos/Webinar_IDE-SEFIN_06_Perguntas_Agradecimentos.webm",
  },
];

export default palestrantes;