import React, { useState } from "react";

import HeaderNav from "../header";
import LoginForm from "../Login/Form";
import { ResponsivePlayer, VideoList } from "../video";
import "./styles.css"
import palestrantes from "./videos_list"
import EventList from "./EventList"

const Eventos = (props) => {
  const [videoEscolhido, setVideoEscolhido] = useState(
    null
  )
  const [videos, setVideos] = useState(
    []
  )
  const [visible, setVisible] = useState(
    false
  )

  const escolheVideo = (video) => {
    setVideoEscolhido(video)
  }

  const handleLogin = () => {
    window.location.reload();
  };

  const listaEventos = [...new Set(palestrantes.map((palestrante, idx) => {
    return palestrante.evento
  }))];

  const escolheEvento = async (evento) => {
    setVisible(true)
    const videosEvento = palestrantes.filter((palestrante) => {
      return palestrante.evento == evento
    })
    await setVideoEscolhido(videosEvento[0])
    await setVideos(videosEvento)
    fixHeight()
  }

  const fixHeight = () => {
    const element = document.getElementsByClassName("video-list")[0];
    const element2 = document.getElementsByClassName("player-wrapper")[0];

    const height = element2.offsetHeight;

    element.style.height = height + "px";
  }
  
  return (
    <div className="geosservicos">
      <HeaderNav
        menu="home"
        customClass="fixed-top"
        handleLogin={handleLogin}
      />
      <LoginForm handleLogin={handleLogin} />
      <div className="py-5 bg-light">
        <div className="container">
          <div className='row text-justify'>
            <h1>Eventos</h1>
          </div>
          <EventList
            eventos={listaEventos}
            escolheEvento={escolheEvento}
          />
          <br/>
          <div
            className="row"
            style={{display: visible ? '' : 'none'}}
          >
            <div className="col-sm-9 col-12 px-0 mb-4">
              <ResponsivePlayer url={videoEscolhido ? videoEscolhido.url : ''} />
            </div>
            <div className="col-sm-3 col-12 order-sm-2 order-3 px-0 mb-4">
              <VideoList palestrantes={videos} escolheVideo={escolheVideo} />
            </div>
            <div className="col-12 order-sm-3 order-2 px-0 mb-4" style={{fontSize: '12px'}}>
              <b style={{fontSize: '15px'}}>{videoEscolhido ? videoEscolhido.titulo : ''}</b>
              <br/>
              {videoEscolhido ? videoEscolhido.descricao : ''}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Eventos;
