import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';

class Download extends React.Component {
  constructor (props) {
    super(props);
    if (this.props.currentGeoserverLayer) {
      if (this.props.currentGeoserverLayer['workspace'] === 'IMAGEAMENTO') {
        this.ows_base_url = ""
        this.wms_base_url = ""
      } else {
        this.ows_base_url = `${process.env.REACT_APP_GEOSERVER_URL}/${this.props.currentGeoserverLayer['workspace']}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${this.props.currentGeoserverLayer['layerSource']}&outputFormat=`
        this.wms_base_url = `${process.env.REACT_APP_GEOSERVER_URL}/${this.props.currentGeoserverLayer['workspace']}/wms/kml?layers=${this.props.currentGeoserverLayer['layerSource']}`
      }
    } else {
      this.ows_base_url = ""
      this.wms_base_url= ""
    }
  }


  clickDownload (url, type, file) {
    const username = window.sessionStorage.getItem('username')
    const password = window.sessionStorage.getItem('password')
    let credentials;
    if (username && password) 
    credentials = `Basic ${btoa(`${username}:${password}`)}`;

    const xhr = new XMLHttpRequest();
    xhr.open('GET', url);
    xhr.responseType = "blob";
    xhr.setRequestHeader('Authorization', credentials);
    xhr.setRequestHeader("Content-type", type);
    xhr.onload = function() {
      if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
            // var blob = new Blob([this.response], {type: type});
            //Create a link element, hide it, direct it towards the blob, and then 'click' it programatically
            let a = document.createElement("a");
            a.style = "display: none";
            document.body.append(a);
            //Create a DOMString representing the blob and point the link element towards it
            let url = window.URL.createObjectURL(this.response);
            a.href = url;
            a.download = file;
            //programatically click the link to trigger the download
            a.click();
            //release the reference to the file by revoking the Object URL
            window.URL.revokeObjectURL(url);
     }
    }
    xhr.send();
    //set the reponse type to blob since that's what we're expecting back
    // xhr.responseType = 'blob';
    // let formData = new FormData(this);
    // xhr.send(formData); 

    // xhr.onload = function(e) {
    //     if (this.status == 200) {
    //         // Create a new Blob object using the response data of the onload object
    //         var blob = new Blob([this.response], {type: type});
    //         //Create a link element, hide it, direct it towards the blob, and then 'click' it programatically
    //         let a = document.createElement("a");
    //         a.style = "display: none";
    //         document.body.appendChild(a);
    //         //Create a DOMString representing the blob and point the link element towards it
    //         let url = window.URL.createObjectURL(blob);
    //         a.href = url;
    //         a.download = 'myFile.pdf';
    //         //programatically click the link to trigger the download
    //         a.click();
    //         //release the reference to the file by revoking the Object URL
    //         window.URL.revokeObjectURL(url);
    //     }else{
    //         //deal with your error state here
    //     }
    // };
  }

  render () {
    if (this.ows_base_url) {
      return (
        <div>
          <div className="popover-title">Download:</div>
          <table className="table download-table">
            <thead>
              <tr>
                <th width="25%" scope="col">CSV</th>
                <th width="25%" scope="col">SHP</th>
                <th width="25%" scope="col">JSON</th>
                <th width="25%" scope="col">KML</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="align-middle">
                  <a href="#" onClick={() => this.clickDownload(`${this.ows_base_url}csv`, 'text/csv', `${this.props.currentGeoserverLayer['layerSource']}.csv`)}className="download-link" rel="noopener noreferrer">
                    <FontAwesomeIcon icon='download'/>
                  </a>
                </td>
                <td className="align-middle">
                  <a href="#" onClick={() => this.clickDownload(`${this.ows_base_url}SHAPE-ZIP`, 'octet/stream', `${this.props.currentGeoserverLayer['layerSource']}.zip`)} className="download-link" rel="noopener noreferrer">
                    <FontAwesomeIcon icon='download'/>
                  </a>
                </td>
                <td className="align-middle">
                  <a href="#" onClick={() => this.clickDownload(`${this.ows_base_url}json`, 'application/json', `${this.props.currentGeoserverLayer['layerSource']}.json`)} className="download-link" rel="noopener noreferrer">
                    <FontAwesomeIcon icon='download'/>
                  </a>
                </td>
                <td className="align-middle">
                  <a href="#" onClick={() => this.clickDownload(`${this.wms_base_url}`, 'text/xml', `${this.props.currentGeoserverLayer['layerSource']}.kml`)} className="download-link" rel="noopener noreferrer">
                    <FontAwesomeIcon icon='download'/>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }  else {
      return '';
    }
  }
}

const mapStateToProps = (store, ownProps) => {
  return {layers: store.layersState.layers, last_updated: store.layersState.last_updated, layersGeoserver: store.layersGeoserverState}
}

// const mapDispatchToProps = dispatch =>
//   bindActionCreators({setOpacity}, dispatch);

export default connect(mapStateToProps, null)(Download);