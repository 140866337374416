import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMessage, faEnvelope, faUser } from '@fortawesome/free-regular-svg-icons'
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons'
import logoNovaIDE from './ide_sefin_logo.png'
import LogoPmf from './logo-pmf.png'
import './Navbar.css'
import LoginForm from '../Login/Form'
import duvidas from './duvidas.svg'
import visualizador from './visualizador_map.svg'
import LocationDot from './location_dot.svg'
import SignOut from './signout.svg'
import MetadadosSVG from './metadados.svg'
import GeoservicosSVG from './georservicos.svg'
import DownloadsSVG from './downloads.svg'
import ManuaisSVG from './manuais.svg'
import NoticiasSVG from './noticias.svg'
import SobreSVG from './briefcase.svg'
import Visualizador from './visualizador.svg'
import {
  NavLink
} from "react-router-dom";
class Navbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menuOpened: false
    }
  }

  signOut() {
    setTimeout(function () {
      window.location.reload()
    }
      , 3500);
  }

  triggerClick = (className) => {
    document.getElementById(className).click()
  }


  showFormLogin = () => {
    const login_body = document.getElementById('login-body');
    login_body.classList.toggle("d-none");
  }

  logout = () => {
    window.sessionStorage.removeItem('username')
    window.sessionStorage.removeItem('password')
    this.props.handleLogin();
  }


  collapseBehavior = () => {
    const collapse = document.getElementById('navbarMobile')
    if (this.state.menuOpened) {
      this.setState({ menuOpened: false })
      document.body.parentElement.classList.remove('oversflow-y-hidden')
      document.body.classList.remove('oversflow-y-hidden')
    } else {
      this.setState({ menuOpened: true })
      document.body.parentElement.classList.add('oversflow-y-hidden')
      document.body.classList.add('oversflow-y-hidden')

    }
  }


  render() {
    const username = window.sessionStorage.getItem('username')
    const password = window.sessionStorage.getItem('password')
    let btn_login, btn_login_mobile;
    if (username && password) {
      btn_login = <>
        <li className="nav-item">
          <a className="nav-link nav-link-principal fs-5" href="#" >
            <FontAwesomeIcon icon={faUser} />
            <span className='span-navbar' style={{ fontSize: '16px', fontWeight: '600' }}>{username}</span>
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link nav-link-principal fs-5" href="#" onClick={this.logout} >
            {/* <FontAwesomeIcon icon='sign-out-alt'></FontAwesomeIcon> */}
            <img src={SignOut} style={{ maxWidth: '20px' }} />
            <span className='span-navbar' style={{ fontSize: '16px', fontWeight: '600' }}>Sair</span>
          </a>
        </li>
      </>

      btn_login_mobile = < >
        <li className="nav-item h-100" >
        </li>
        <li className="nav-item w-100 px-2 text-right mt-2  mb-2" >
          <a className="nav-link nav-link-principal fs-5" href="#" >
            <span className='span-navbar mx-2' style={{ fontSize: '16px', fontWeight: '600' }}>{username}</span>
            <FontAwesomeIcon icon={faUser} />
          </a>
        </li>
        <li className="nav-item w-100 px-2 text-right mt-2 mb-4 li_bottom" >
          <a className="nav-link nav-link-principal fs-5" href="#" onClick={this.logout} >
            {/* <FontAwesomeIcon icon='sign-out-alt'></FontAwesomeIcon> */}
            <span className='span-navbar mx-2' style={{ fontSize: '16px', fontWeight: '600' }}>Sair</span>
            <img src={SignOut} style={{ maxWidth: '20px' }} />
          </a>
        </li>
        <li className="nav-item w-100 px-2 text-right mt-2 mb-2 mb-2" >
        </li>
      </>

    } else {
      btn_login = <li className="nav-item mx-0">
        <a href="#" className="nav-link nav-link-principal fs-5" onClick={this.showFormLogin}>
          <FontAwesomeIcon icon={faUser} />
          <span className='span-navbar' style={{ fontSize: '16px', fontFamily: 'Palaquin', fontWeight: '600' }}>Entrar</span>
        </a>
      </li>

      btn_login_mobile = <>
        <li className="nav-item h-100" >
        </li>
        <li className="nav-item w-100 px-2 text-right mt-3 mb-2" >
          <a href="#" className="nav-link nav-link-principal fs-5" onClick={this.showFormLogin}>
            <span className='span-navbar mx-2' style={{ fontSize: '16px', fontWeight: '600' }}>Entrar</span>
            <FontAwesomeIcon icon={faUser} />
          </a>
        </li>
        <li className="nav-item w-100 px-2 text-right mt-3 mb-2 mb-2" >
        </li>
      </>
    }

    return (
      <>
        <nav className="navbar p-0 navbar-expand-lg navbar-light text-center text-light" style={{ minHeight: '60px', background: '#FFFFFF', borderTop: '4px solid #10949E' }}>

          <div className='float-right'>
            <a href='https://www.sefin.fortaleza.ce.gov.br/'>
              <img className='navbar-brand p-0 mx-2' src={LogoPmf} width={120} />
            </a>
            <a href='/'><img className="navbar-brand p-0 mx-2" src={logoNovaIDE} width={120} /></a>
          </div>

          <button className="mx-2 navbar-toggler" type="button" onClick={this.collapseBehavior} data-toggle="collapse" data-target="#navbarMobile" aria-controls="navbarMobile" aria-expanded="false" aria-label="navbarMobile">
            <span className="navbar-toggler-icon"></span>
          </button>

          {/* Barra menu visivel em Lg e MD */}
          <div className="mt-2 d-none d-lg-block mt-lg-0 ml-md-auto" id="">
            <ul className="navbar-nav navbar-nav-topo mt-lg-0 ml-md-auto" style={{ paddingInlineStart: '15px' }}>
              <li className="nav-item mx-0">
                <a href="/visualizador" className="nav-link nav-link-principal fs-5"><img src={visualizador} style={{ width: '20px' }} />
                  <span className='span-navbar' style={{ fontSize: '16px', fontFamily: 'Palaquin', fontWeight: '600' }}>Visualizador</span>
                </a>
              </li>
              <li className="nav-item mx-0">
                <a href="/duvidas" className="nav-link nav-link-principal fs-5"><img src={duvidas} style={{ width: '20px' }} />
                  <span className='span-navbar' style={{ fontSize: '16px', fontFamily: 'Palaquin', fontWeight: '600' }}>Dúvidas</span>
                </a>
              </li>
              <li className="nav-item mx-0">
                <a href="/#contato" className="nav-link nav-link-principal fs-5"><FontAwesomeIcon icon={faEnvelope} />
                  <span className='span-navbar' style={{ fontSize: '16px', fontFamily: 'Palaquin', fontWeight: '600' }}>Contato</span>
                </a>
              </li>
              {btn_login}
            </ul>
          </div>
          {/*  */}

        </nav>


        {/* Barra navbarMobile visivel somente no SM */}
        <div className="collapse width navbar-collapse d-lg-none " id="navbarMobile">
          <ul className="navbar-nav navbar-nav-topo mt-lg-0 float-right w-100 h-100 mx-3" style={{ padding: 0, position: 'relative', listStyle: 'none', whiteSpace: 'nowrap', fontWeight: '600', color: '#252525' }}>
            <div className='d-flex justify-content-between w-100 px-2' style={{ marginInlineStart: '10px' }}>
              <li className="nav-item mx-0 text-center mt-2">
                <a href="/geoservicos" className=" nav-link nav-link-principal fs-5">
                  <img src={GeoservicosSVG} style={{ filter: "grayscale(1) brightness(0)" }} width={20} />
                  <br />
                  <span className='span-navbar ' style={{ fontSize: '16px', fontWeight: '600' }}>Geoserviços</span>
                </a>
              </li>
              <li className="nav-item mx-0 text-center mt-2">
                <a href="/visualizador" className="nav-link nav-link-principal fs-5">
                  <img src={LocationDot} style={{ width: '20px' }} />
                  <br />
                  <span className='span-navbar ' style={{ fontSize: '16px', fontWeight: '600' }}>Visualizador</span>
                </a>
              </li>
              <li className="nav-item mx-0 text-center mt-2">
                <a href="/metadados" className="nav-link nav-link-principal fs-5">
                  <img src={MetadadosSVG} style={{ filter: "grayscale(1) brightness(0)" }} width={20} />
                  <br />
                  <span className='span-navbar ' style={{ fontSize: '16px', fontWeight: '600' }}>Metadados</span>
                </a>
              </li>
            </div>
            <li className="nav-item mx-0 w-100">
              <hr style={{ background: '#10949E', height: '5x', marginInlineStart: '20px' }} />
            </li>
            <li className="nav-item mx-0 w-100 text-right mt-3">
              <a href="/duvidas" className="nav-link nav-link-principal fs-5 mx-2">
                <span className='span-navbar mx-2' style={{ fontSize: '16px', fontWeight: '600' }}>Dúvidas</span>
                <FontAwesomeIcon icon={faMessage} />
              </a>
            </li>
            <li className="nav-item mx-0 w-100 text-right mt-3">
              <a href="/#contato" className="nav-link nav-link-principal fs-5 mx-2">
                <span className='span-navbar mx-2' style={{ fontSize: '16px', fontWeight: '600' }}>Contato</span>
                <FontAwesomeIcon icon={faEnvelope} />
              </a>
            </li>
            <li className="nav-item mx-0 w-100 text-right mt-3">
              <a href="/downloads" className="nav-link nav-link-principal fs-5 mx-2">
                <span className='span-navbar mx-2' style={{ fontSize: '16px', fontWeight: '600' }}>Downloads</span>
                <img src={DownloadsSVG} style={{ filter: "grayscale(1) brightness(0)" }} width={20} />
              </a>
            </li>
            <li className="nav-item mx-0 w-100 text-right mt-3">
              <a href="/sobre" className="nav-link nav-link-principal fs-5 mx-2">
                <span className='span-navbar mx-2' style={{ fontSize: '16px', fontWeight: '600' }}>Institucional</span>
                <img src={SobreSVG} style={{ filter: "grayscale(1) brightness(0)" }} width={20} />
              </a>
            </li>
            <li className="nav-item mx-0 w-100 text-right mt-3">
              <a href="/tutoriais" className="nav-link nav-link-principal fs-5 mx-2">
                <span className='span-navbar mx-2' style={{ fontSize: '16px', fontWeight: '600' }}>Manuais</span>
                <img src={ManuaisSVG} style={{ filter: "grayscale(1) brightness(0)" }} width={20} />
              </a>
            </li>
            <li className="nav-item mx-0 w-100 text-right mt-3">
              <a href="/noticias" className="nav-link nav-link-principal fs-5 mx-2">
                <span className='span-navbar mx-2' style={{ fontSize: '16px', fontWeight: '600' }}>Notícias</span>
                <img src={NoticiasSVG} style={{ filter: "grayscale(1) brightness(0)" }} width={20} />
              </a>
            </li>
            {btn_login_mobile}
          </ul>
        </div>
        {/*  */}


        <nav className="navbar navbar-expand-lg px-2 navbar-dark py-0 shadow justify-content-end" style={{ backgroundColor: "#10949E", color: '#252525' }} >

          <div className='float-right'>
            {/* <img className='navbar-brand p-0 mx-2' src={LogoPmf} width={120} /> */}
            {/* <a href='/'><img className="navbar-brand p-0 mx-2 text-left" src={logoNovaIDE} width={120} /></a> */}
          </div>

          <button className="d-none d-sm-none navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent2" aria-controls="navbarSupportedContent2" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent2">
            <ul className="navbar-nav nav-fill container-fluid align-items-start mb-2 mb-lg-0 " style={{fontWeight: '600'}}>
              <li className="nav-item" style={{fontWeight: '600'}}>
                <NavLink activeClassName='active' exact={true} to='/' className="nav-link" style={{ textTransform: 'uppercase', fontSize: '16px', fontFamily: 'Palaquin', fontWeight: '600', color: '#FFF' }} >Início</NavLink>
              </li>
              <li className="nav-item">
                <NavLink activeClassName='active' exact={true} to='/metadados' className="nav-link" style={{ textTransform: 'uppercase', fontSize: '16px', fontFamily: 'Palaquin', fontWeight: '600', color: '#FFF' }} >Metadados</NavLink>
              </li>
              <li className="nav-item ">
                <NavLink activeClassName='active' exact={true} to='/geoservicos' className="nav-link" style={{ textTransform: 'uppercase', fontSize: '16px', fontFamily: 'Palaquin', fontWeight: '600', color: '#FFF' }}>Geoserviços</NavLink>
              </li>
              <li className="nav-item ">
                <NavLink activeClassName='active' exact={true} to='/downloads' className="nav-link" style={{ textTransform: 'uppercase', fontSize: '16px', fontFamily: 'Palaquin', fontWeight: '600', color: '#FFF' }}>Downloads</NavLink>
              </li>
              <li className=" nav-item ">
                <NavLink activeClassName='active' exact={true} to='/sobre' className="nav-link" style={{ textTransform: 'uppercase', fontSize: '16px', fontFamily: 'Palaquin', fontWeight: '600', color: '#FFF' }} >Institucional</NavLink>
              </li>
              <li className="nav-item ">
                <NavLink to="/tutoriais" activeClassName='active' exact={true} className="nav-link" style={{ textTransform: 'uppercase', fontSize: '16px', fontFamily: 'Palaquin', fontWeight: '600', color: '#FFF' }}>Manuais</NavLink>
              </li>
              <li className="nav-item ">
                <NavLink to="/noticias" activeClassName='active' exact={true} className="nav-link" style={{ textTransform: 'uppercase', fontSize: '16px', fontFamily: 'Palaquin', fontWeight: '600', color: '#FFF' }}>Notícias</NavLink>
              </li>
              {/* <li className="nav-item ">
                <a href="/#noticias"  className="nav-link" style={{ textTransform: 'uppercase', fontSize: '15px', fontWeight: 'normal' , color: '#343a40 !important'}}>Notícias</a>
              </li> */}
            </ul>
          </div>
        </nav>
      </>
    )
  }
}

export default Navbar