import React from 'react';

class ListMenu extends React.Component {
  render() {
    return(
      <div className=' list-group list-group-flush ' id='menu'>
        {React.Children.map(this.props.children,  layer => {
          return layer;
        })}
      </div>
    )
  }
}

export default ListMenu;