import React from 'react';
// import logo from './logo.svg';
import './App.css';
import LayoutOld from './components/LayoutOld';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faEye, faEyeSlash, faDownload,
        faHome, faSearchLocation, faMap,
        faSignOutAlt, faInfoCircle, faLayerGroup,
        faPlus, faMinus, faPlusSquare, faSearch, faGlobeAmericas,
        faNetworkWired, faEnvelopeSquare, faCaretSquareUp, faCaretSquareDown,
        faLink, faUser, faArrowAltCircleUp, faArrowAltCircleDown, faCalendar,
        faPhone, faEnvelope
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faEye, faEyeSlash, faDownload, faHome,
  faSearchLocation, faMap, faSignOutAlt,
  faInfoCircle, faLayerGroup, faPlus, faMinus,
  faPlusSquare, faSearch, faGlobeAmericas, faNetworkWired,
  faEnvelopeSquare, faCaretSquareUp, faCaretSquareDown,
  faLink, faUser, faArrowAltCircleDown, faArrowAltCircleUp, faCalendar,
  faPhone, faEnvelope
);


function AppOld() {
  if(window.localStorage.getItem('aceitoTermoUso')){
    return (
      <LayoutOld/>
    )

  }else{
    window.location.href = '/'; // Redirect to the home page
      return null;
  }
}

export default AppOld;
