import {CONCAT_GRIDINFO, SET_GRIDINFO, SHOW_GRIDINFO, TOGGLE_GRIDINFO, HIDE_GRIDINFO, CLEAR_GRIDINFO} from './actionTypes';


export const setGridInfo = (features, typeRequest) => ({
  type: SET_GRIDINFO,
  features: features,
  typeRequest: typeRequest
  
})

export const concatGridInfo = (features, typeRequest) => ({
  type: CONCAT_GRIDINFO,
  features: features,
  typeRequest: typeRequest
})
export const showGridInfo = () => ({
  type: SHOW_GRIDINFO
})

export const toggleGridinfo = () => ({
  type: TOGGLE_GRIDINFO
})

export const hideGridinfo = () => ({
  type: HIDE_GRIDINFO
})
export const clearGridinfo = () => ({
  type: CLEAR_GRIDINFO
})
