import React, {createRef} from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import './style.css'; 
import { Redirect } from "react-router-dom";

class Form extends React.Component {
  constructor (props) {
    super(props);
    this.reloadPage = this.reloadPage.bind(this);
    this.state = {
      retry : false,
      username: '',
      password: '',
      authenticated: false,
      msg_error : null

    }
    this.recaptchaRef = createRef();
  }

  reloadPage() {
    setTimeout( function() {
      window.location.reload()
    }
    , 1000);
  }

  handleChangeUsername = (event) => {
    this.setState({username: event.target.value});
  }

  handleChangePassword = (event) => {
    this.setState({password: event.target.value});
  }

  onSubmit = (event) => {
    const recaptchaValue = this.recaptchaRef.current.getValue();
    if (recaptchaValue === "") {
      this.setState({msg_error: 'Certifique-se se você não é um robô antes de se logar'});
      event.preventDefault();
      return false
    } 
    event.preventDefault();
    this.checkAuthentication();
    
      // return <Redirect to={'/visualizador'} />
  }


  // Login Gambis bonita!
  checkAuthentication = () => {
    var xhr = new XMLHttpRequest();
    var current = this;
    

    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === 4) {
        try {
          JSON.parse(this.responseText)
          window.sessionStorage.setItem('username', current.state.username)
          window.sessionStorage.setItem('password', current.state.password)    
          current.setState({
            authenticated : true
          });
          current.setState({msg_error: null});
          current.showFormLogin();
          current.props.handleLogin()
        } catch (error) {
          current.setState({msg_error: 'Não foi possível se autenticar'});
          current.setState({
            authenticated : false
          });
        }
      }
    });

    xhr.open("GET", process.env.REACT_APP_GEOSERVER_URL + "/LIMITES_ADMINISTRATIVOS/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=LIMITES_ADMINISTRATIVOS%3Alimite_municipal&outputFormat=application%2Fjson") ;
    xhr.setRequestHeader('Authorization', `Basic ${btoa(`${current.state.username}:${current.state.password}`)}`);
    xhr.withCredentials = true
    // xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.send();
  }

  showFormLogin = () => {
    const login_body = document.getElementById('login-body');
    login_body.classList.toggle("d-none");
  }

  recaptchaOnChange = (value) => {
    if (value || value !== "") {
      this.setState({msg_error: null});
    }

  }


  render () {
    let show_msg_error;

    if (this.state.msg_error) {
      show_msg_error = <div className="alert alert-danger" role="alert">
                        {this.state.msg_error}
                      </div>
    }
    return (
      <div >
        <div className="shadow card login-body  d-none" id="login-body" aria-hidden="true">
          <div className="card-header card-header-title">
              <button type="button" className="close" aria-label="Close" onClick={this.showFormLogin} >
                <span aria-hidden="true">&times;</span>
              </button>
              <h6 className="card-title" id="card-title-pmf">Autenticação de usuário</h6>
          </div>
          <div className="card-body " id="">
              <div id="card-content" className="content-card">
              
                <div className='row'>
                  <form onSubmit={this.onSubmit} className='form-inline' id="form-login" >
                    
                    <label className="sr-only" htmlFor="username">Usuário</label>
                    <div className="input-group mb-2 mr-sm-2">
                      <input type="text" className="form-control" id="username" value={this.state.value} onChange={this.handleChangeUsername} onLoad={this.handleChangeUsername}  placeholder="Usuário" name="username"/>
                    </div>
                    <label  className="sr-only" htmlFor="InputPassword">Senha</label>
                    <div className="input-group mb-2 mr-sm-2">
                      <input type="password" className="form-control" id="InputPassword"  value={this.state.value} onChange={this.handleChangePassword} onLoad={this.handleChangePassword}  placeholder="Senha" name="InputPassword"/>
                    </div>
                    <div className="input-group mb-2 mr-sm-2">
                      <ReCAPTCHA ref={this.recaptchaRef} onChange={this.recaptchaOnChange} sitekey={process.env.REACT_APP_RECAPTCHA_KEY} theme='light' badge='inline'/>
                    </div>
                    <button type="submit" className="btn btn-primary" >Logar</button>
                  </form>
                </div>
                <div className='row'>
                {show_msg_error}
                </div>

                </div>
          </div>
        </div>
      </div>
    )
  }
}
export default Form