import React from 'react';
import img1 from './IDE_banner_3.png'
import './Banner.css'

class Banner extends React.Component {

  render() {
    return (
      <>
        <div id="carouselHome1" className="carousel slide text-dark mb-3" data-ride="carousel">
          {/* <ol className="carousel-indicators">
            <li data-target="#carouselHome" data-slide-to="0" className="active"></li>
            <li data-target="#carouselHome" data-slide-to="1"></li>
          </ol> */}
          <div className="carousel-inner rounded" style={{borderColor: 'rgb(255 255 255 / 13%)'}}>
            <div className="carousel-item active">
              <img src={img1} className="d-block img-carousel img-fluid" alt=""/>

              <div className="carousel-caption container-fluid px-0" style={{bottom: '35%'}} >
                <div className='mensagem-slide-1'>
                  {/* <h3><a href='visualizador' className='btn btn-lg btn-responsive mb-sm-5 px-3' style={{backgroundColor: '#57CAD3', color: "#000000"}}><b>CONHEÇA NOSSO VISUALIZADOR</b></a></h3> */}
                  {/* <p className='d-none d-lg-block' style={{"color": "#000000", backgroundColor: "rgb(87 202 211 / 80%)"}}>Visualize, descubra e crie mapas com a nossa aplicação web GIS que permite exportar e salvar mapas diretamente do navegador</p> */}
                </div>
              </div>
            </div>
            {/* <div className="carousel-item">
              <img src={img1} className="d-block img-carousel" alt="" />
              <div className="carousel-caption ">
              </div>
            </div> */}
          </div>
            {/* <a className="carousel-control-prev" href="#carouselHome" role="button" data-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href="#carouselHome" role="button" data-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="sr-only">Next</span>
            </a> */}
        </div>
      </>
    )
  }
}

export default Banner;