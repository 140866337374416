import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import './AreaDeBusca.css'


class AreaDeBusca extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className='row mb-3 align-items-center h-100 justify-content-center'>
        <div className='col-12'>
          <h1 className='text-left fs-title' style={{ fontFamily: 'Palaquin Dark Regular' }}>Infraestrutura de Dados Espaciais da Secretaria das Finanças de Fortaleza</h1>
          <hr className='py-1' style={{ backgroundColor: '#10949E' }} />
        </div>
        {/* <div className="row">
            <div className="col-md-6 offset-md-3">
                <div className="input-group border rounded-pill mb-3 mt-3 border-search">
                    <input className="form-control py-2 bg-transparent border-0 rounded-pill" type="search" placeholder="Pesquise em todo o site!" id="example-search-input"/>
                    <span className="input-group-append">
                        <div className="input-group-text bg-transparent border-0"><FontAwesomeIcon icon={faSearch} /></div>
                    </span>
                </div>
            </div>
        </div> */}
      </div>
    )
  }
}

const mapStateToProps = (store, ownProps) => {
  return { map: store.layersState.map }
}

export default connect(mapStateToProps, null)(AreaDeBusca);