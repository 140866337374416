import React from 'react';

const Termos = ({ isOpen, onClose }) => {
  if (!isOpen && window.localStorage.getItem('aceitoTermoUso')) {
    return null;
  }
  if(!window.localStorage.getItem('aceitoTermoUso')){
    return (
      <div className="modal-overlay">
        <div className="" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title" id="staticBackdropLabel">Termos de Uso</h3>
                {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onClose}></button> */}
              </div>
              <div className="modal-body text-justify">
              O material disponibilizado na plataforma N​ÃO dispensa ou substitui qualquer documento oficial emitido pelo Município, a exemplo de licenças, alvarás,
  fichas de informação, declarações, certidões ou documentos referentes as demais obrigações legais e administrativas. ​As informações ​​disponibilizadas se destinam ao público, profissionais em geral,
  professores e alunos dos diversos níveis de ensino, democratizando e favorecendo a acessibilidade, a transparência e ciência das ações e dados espaciais sob a competência da Secretaria Municipal das Finanças de Fortaleza (SEFIN).
              </div>
              <div className="modal-footer justify-content-center">
                <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={onClose}>Li e aceito os termos.</button>
                {/* <button type="button" className="btn btn-secondary">Voltar</button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }else{
    return(
      <></>
    )
  }
};

export default Termos;
