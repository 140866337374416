import React from 'react';
import { connect } from 'react-redux';
import {toggleVisible, updateLayers, hideLayer, showLayer} from '../../actions';
import { bindActionCreators } from 'redux';
import OptionsPopover from './Popover/OptionsPopover';


class ListItemRadioButton extends React.Component {
  constructor(props) {
    super(props)
    this.isChecked = this.isChecked.bind(this);
    this.onClick   = this.onClick.bind(this);
    this.onChange   = this.onChange.bind(this);
  }

  isChecked(){
    if (this.props.selected === this.props.layerName) {
      return true;
    } else {
      return false;
    }
  }

  onClick(param, e) {
    param.props.updateSelectedRadio(this.props.layerName);
    e.stopPropagation();
  }

  onChange() {
    this.props.hideLayer('XYZ');
    this.props.hideLayer('OSM');
    this.props.hideLayer('CAMADA_BASE:mapa_fortaleza');
    this.props.hideLayer('IMAGEAMENTO:ortofoto_2001');
    this.props.hideLayer('IMAGEAMENTO:ortofoto_2004');
    this.props.hideLayer('IMAGEAMENTO:ortofoto_2010');
    this.props.hideLayer('IMAGEAMENTO:ortofoto_2016');
    if (this.props.layerName !== 'Nenhum' ) {
      this.props.showLayer(this.props.layerSource)
    }
  }

  render() {
    return (
      <div className='col-sm-12 col-md-12 col-lg-6'>
        <label className={`label-item-menu  shadow-sm imagembase ${this.props.idName}`} htmlFor={ this.props.idName }>
          <span className='layer-name'>{ this.props.layerName}</span>
          <input
            type='radio' id={ this.props.idName } name='radio-button' className='layer-checkbox'
            checked={this.isChecked()}
            onClick={(event) => this.onClick(this, event)}
            onChange={this.onChange}
            data-layerclass={ this.props.layerClass }
          />
          <span className='checkmark'></span>
        </label>
        {/* <OptionsPopover parentName={ this.props.parentName } layerSource={ this.props.layerSource } layerName={ this.props.layerName} customStyle='mapa-base'/> */}
        {/* <button type='button' className='btn btn-link btn-options' data-title='' data-toggle='popover' data-placement='top'>
          <span className='oi oi-menu' ></span>
        </button> */}
      </div>
    )
  }
}


const mapStateToProps = (store, ownProps) => {
  return {layers: store.layersState.layers, last_updated: store.layersState.last_updated}
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({updateLayers, toggleVisible, hideLayer, showLayer}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ListItemRadioButton);

// export default ListItem;
