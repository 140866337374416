import React from 'react';
import HeaderNav from '../components/header';
import Carousel from '../components/home/Carousel';
import Card from '../components/home/Card';
import '../components/home/subnav.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setLayersGeoserver } from '../actions/layerGeoserverActions';
import CamadasDisponiveisListMenu from '../components/SideNav/ListMenu/AdicionarCamada/CamadasDisponiveisListMenu';
import { loadMetadataGeoNetwork } from '../utils/loadMetadataGeoNetwork';
import { setMetadataLayers } from '../actions/metadataLayersActions';
import { loadGetCapabilities } from '../utils/loadGetCapabilities';
import LoginForm from '../components/Login/Form';
import Navbar from '../components/home/Navbar';
import Footer from '../components/home/Footer';
import './Geoservicos.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Noticia1 from './img_noticia_1.png'
import Noticia2 from './img_noticia_2.png';
import Noticia3 from './THUMBNAIL_NOTA_CHAMAMENTO.png'
import './Noticias.css'
import {
  NavLink
} from "react-router-dom";

class Noticias extends React.Component {

  constructor(props) {
    super(props)
    const InitialState = {
    }
  }

  filterNoticias = () => {
    // Declare variables
    let input, filter, noticias, noticia, i, title;
    input = document.getElementById('filter-noticias');
    filter = input.value.toUpperCase();
    noticias = document.querySelectorAll('.noticia');
  
    // Loop through all noticias, and hide/show based on search query
    for (i = 0; i < noticias.length; i++) {
      noticia = noticias[i];
      title = noticia.querySelector('.noticia-title');
      if (title) {
        const txtValue = title.textContent || title.innerText;
        if (filter.length > 0 && txtValue.toUpperCase().includes(filter)) {
          // noticia.style.display = 'block';
          title.innerHTML = txtValue.replace(new RegExp(filter, 'gi'), match => `<span class="highlight">${match}</span>`);
        } else if(filter.length > 0 && !txtValue.toUpperCase().includes(filter)) {
          // noticia.style.display = 'block'; // Show all news items when no search input
          title.innerHTML = txtValue;
          title.style.color = 'gray';
        } else if(filter.length == 0){
          title.innerHTML = txtValue
          title.style.color = "#212529"
        }
      }
    }
  }
  
  // ...

  
  
  

  handleLogin = () => {
    this.setState({ last_login: Date.now() })
    // this.props.map.updateSize(); 
    // if (document.getElementById('close-card-pmf')) {
    //   document.getElementById('close-card-pmf').click();
    // }

    // FIXME: Resolve situation when not rerender components after login
    window.location.reload();
  }

  componentDidMount() {
    var checkExist = setInterval(function () {
      if (document.getElementsByClassName('list-group-flush').length > 1) {
        document.getElementsByClassName('list-group-flush')[1].classList.add('active')
        clearInterval(checkExist);
      }
    }, 100);
  }

  render() {
    if(window.localStorage.getItem('aceitoTermoUso')){
      return (
        <>
          <Navbar handleLogin={this.handleLogin} />
          <div className="Geoservicos container bg-white">
            {/* <HeaderNav menu='home' customClass='fixed-top' handleLogin={this.handleLogin}/> */}
            <LoginForm handleLogin={this.handleLogin} />
            {/* <Carousel/> */}
            {/* <nav className="nav navbar-dark subnavbar-pmf justify-content-center"> */}
            {/* <div className='container'> */}
            {/* <NavLink activeClassName='active' exact={true} className="nav-link" to='/'>Mapas Disponíveis</NavLink>
              <NavLink activeClassName='active' exact={true} className="nav-link" to='/geoservicos'>Geoserviços</NavLink>
            <NavLink activeClassName='active' exact={true} className="nav-link" to='/downloads'>Downloads</NavLink> */}
            {/* </div> */}
            {/* </nav> */}
            <h1 className='text-center py-2'>Notícias</h1>
            <div className='py-5'>
              <div className='container' >
                <div className='mx-auto'>
                  <div className={`form-group form-group-search`}>
                    <input className="form-control form-control-sm" type="text"
                      placeholder="" id="filter-noticias" onChange={() => this.filterNoticias()} />
                    <FontAwesomeIcon icon='search'></FontAwesomeIcon>
                  </div>
                  <div className='row noticia'>
                    <div className='col-md-12 col-lg-4'>
                      <img src={Noticia3} className='img-fluid'/>
                    </div>
                    <div className='col-md-12 col-lg-8'>
                      <a href='/noticia/chamamento-sua-pesquisa' className='' style={{color: 'black'}}>
                        <h2 className='font-weight-bold noticia-title'>CHAMAMENTO: queremos conhecer sua pesquisa</h2>
                      </a>
                      <p>O Open Data Day (ODD) é um evento que procura celebrar a promoção e utilização de dados abertos em
todo o mundo. É uma proposta de relevância...</p>
                      <a href='/noticia/chamamento-sua-pesquisa' className='float-right text-dark'>(Leia Mais)</a>
                    </div>
                  </div>
                  <hr style={{backgroundColor: '#10949E', paddingTop: '2px', paddingBottom: '2px'}}/>

                  <div className='row noticia '>
                    <div className='col-md-12 col-lg-4'>
                      <img src={Noticia2} className='img-fluid'/>
                    </div>
                    <div className='col-md-12 col-lg-8'>
                      <a href='/noticia/novas-camadas-disponiveis' className='' style={{color: 'black'}}>
                        <h2 className='font-weight-bold noticia-title'>Novas camadas disponíveis!</h2>
                      </a>
                      <p>Conheça novos mapas altimétricos e atualização de camadas do levantamento
aerofotogamétrico de 2016</p>
                      <a href='/noticia/novas-camadas-disponiveis' className='float-right text-dark'>(Leia Mais)</a>
                    </div>
                  </div>
                  <hr style={{backgroundColor: '#10949E', paddingTop: '2px', paddingBottom: '2px'}}/>
                  <div className='row noticia'>
                    <div className='col-md-12 col-lg-4'>
                      <img src={Noticia1} className='img-fluid'/>
                    </div>
                    <div className='col-md-12 col-lg-8'>
                      <a href='/noticia/novas-camadas-adicionadas' className='' style={{color: 'black'}}>
                        <h2 className='font-weight-bold noticia-title'>IDE-SEFIN de cara nova!</h2>
                      </a>
                      <p>A Infraestrutura de Dados Espaciais da SEFIN reinaugura seu site disponibilizando novas camadas, dispondo de facilidades e de novas funcionalidades para os usuários...</p>
                      <a href='/noticia/novas-camadas-adicionadas' className='float-right text-dark'>(Leia Mais)</a>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          {/* <div className='container-fluid'>
            <Footer />
          </div> */}
        </>
      );

    }else{
      window.location.href = '/'; // Redirect to the home page
      return null;
    }
  }
}

const mapStateToProps = (store, ownProps) => {
  return { layersGeoserver: store.layersGeoserverState.layersGeoserver, metadataLayers: store.metadataLayersState.metadata }
}
const mapDispatchToProps = dispatch =>
  bindActionCreators({ setLayersGeoserver, setMetadataLayers }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Noticias);