import React from 'react';
import { connect } from 'react-redux';
import RendaKriging from './RendaKriging.svg'
import PrecoMercado from './preco_mercado_terreno.png'

class CamadaDestaque extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className='container'>
        <div className='row'>
          <div className='col'>
            <div className="card rounded mt-2 mb-3" style={{ background: "", borderColor: 'rgb(255 255 255 / 13%)' }}>
              <div className=" text-center text-white rounded-pill font-weight-bold text-uppercase py-1" style={{ background: '#10949E', fontFamily: 'Palaquin Dark Bold', fontSize: '16px' }}>
                Camadas em Destaque
              </div>
              <h5 className="card-title text-center font-weight-bold text-uppercase mt-2" style={{ fontFamily: 'Palaquin Dark Bold', fontSize: '16px' }}>Preço de Mercado de Terreno</h5>
              <img src={PrecoMercado} className="card-img-top img-fluid w-341 mx-auto d-block m-2" style={{ boxShadow: '0 4px 4px rgb(0, 0, 0, 0.25)', borderRadius: '20px' }} />
              <div className="card-body p-2 " style={{ background: '#10949E', borderRadius: '25px 25px 25px 25px' }}>
                <p className="card-text small my-2" style={{ borderRadius: '25px', fontFamily: 'Palaquin', fontSize: '14px', fontWeight: '600', marginLeft: '10px', color: "#fff", lineHeight: '20px', marginRight: '10px' }}>
                  Preços de mercado de terreno colhidos e geolocalizados pelo observatório urbano de valores (OUV) desde o ano de 2015. Esses preços não podem ser considerados como valores de mercado, pois são preços de oferta ("de pedida" do vendedor).</p>
                <div className="d-flex  flex-wrap" style={{ justifyContent: 'space-between' }}>
                  <a href={`${process.env.REACT_APP_GEONETWORK_URL}/por/catalog.search#/metadata/744abd6d-7030-4b17-a141-d1364a113b45`} className='btn text-white border-white mb-2' style={{ background: '#10949E', borderRadius: '20px', fontFamily: 'Palaquin Dark', padding: "7px, 15px, 7px, 15px", width: '150px' }}>Metadados</a>
                  <a href="visualizador" className='btn text-white border-white mb-2' style={{ background: '#10949E', borderRadius: '20px', fontFamily: 'Palaquin Dark', padding: "7px, 15px, 7px, 15px", width: '150px' }}>Visualizador</a>
                  <a href="downloads" className='btn text-white border-white mb-2' style={{ background: '#10949E', borderRadius: '20px', fontFamily: 'Palaquin Dark', padding: "7px, 15px, 7px, 15px", width: '150px' }}>Download</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (store, ownProps) => {
  return { map: store.layersState.map }
}

export default connect(mapStateToProps, null)(CamadaDestaque);