import React from 'react';
import LayerTile from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';
import TileGrid from 'ol/tilegrid/TileGrid';
import WMTS, { optionsFromCapabilities } from 'ol/source/WMTS.js';
import TileLayer from 'ol/layer/Tile.js';
import Attribution from 'ol/control/Attribution';
import OSM from 'ol/source/OSM';
import XYZ from 'ol/source/XYZ';
import { buildLayerWFS } from '../../../utils/OlWFS';
import { connect } from 'react-redux';
import { toggleVisible, updateLayers } from '../../../actions';
import { bindActionCreators } from 'redux';
import { setStyleFromSLD } from '../../../utils/OlWFSStyle';
import WMTSCapabilities from 'ol/format/WMTSCapabilities.js';

class Layer extends React.Component {

  checkOlLayer = (layerSource) => {

    try {
      return this.props.layers.getArray().find(function (olLayer) {
        return (olLayer.get('layerSource') === layerSource)
      })
    } catch (error) {
      return true
    }
  }


  addLayer = (layerOl3) => {
    this.props.olmap.addLayer(layerOl3);
  }

  BuildBaseLayersWMS() {
    let source;
    let layerSource;
    let name;
    if (this.props.layerSource === 'OSM') {
      source = new OSM()
      name = "OpenStreetMap"
      layerSource = "OSM"
    } else {
      source = new XYZ({
        attributions: [new Attribution({ html: '<a href=""></a>' })],
        url: 'http://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
      })
      name = "Google Mapas"
      layerSource = "XYZ"
    }
    return new LayerTile({
      name: name,
      layerSource: layerSource,
      visible: this.props.visible,
      source: source,
    })
  }

  BuildBaseLayersWMS() {
    let source;
    let layerSource;
    let name;
    if (this.props.layerSource === 'OSM') {
      source = new OSM()
      name = "OpenStreetMap"
      layerSource = "OSM"
    } else {
      source = new XYZ({
        attributions: [new Attribution({ html: '<a href=""></a>' })],
        url: 'http://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
      })
      name = "Google Mapas"
      layerSource = "XYZ"
    }
    return new LayerTile({
      name: name,
      layerSource: layerSource,
      visible: this.props.visible,
      source: source,
    })
  }

  tileLoader = (tile, src) => {
    const client = new XMLHttpRequest();

    client.open('GET', src);
    client.responseType = 'blob';
    const username = window.sessionStorage.getItem('username')
    const password = window.sessionStorage.getItem('password')
    if (username && username) {
      client.setRequestHeader('Authorization', `Basic ${btoa(`${username}:${password}`)}`);
    }

    client.onload = function () {
      let imageSRC;
      if (client.readyState === XMLHttpRequest.DONE && client.status === 200) {
        imageSRC = URL.createObjectURL(client.response);
        tile.getImage().src = imageSRC;
      }
    };

    client.send();
  }

  renderSliderTimeseries = (layer, timeseries) => {
    const { year_start, year_end } = timeseries;
    let year_length = year_end - year_start
    let dates = []
    for (let index = 0; index <= year_length; index++) {
      dates.push(`${year_start + index}-01-01T00:00:00.000Z`)
    }
    var sliderRange = document.getElementById("myRange");
    sliderRange.max = dates.length - 1;
    sliderRange.value = 0;
    var dateValue = document.getElementById("date_value");
    dateValue.innerHTML = year_start;
    layer.getSource().updateParams({ 'TIME': dates[sliderRange.value] });

  }

  buildLayerWMS = () => {
    let tileGrid = this.props.layerSource === 'CAMADA_BASE:mapa_fortaleza' ? [512, 512] : [512, 512];
    const timeseries = this.props.timeseries
    return new LayerTile({
      visible: this.props.visible,
      name: this.props.layerName,
      layerSource: this.props.layerSource,
      // opacity: 1,//entity.getOpacityDefault(),
      timeseries: timeseries,
      source: new TileWMS({
        url: `${process.env.REACT_APP_GEOSERVER_URL}/wms`,
        tileLoadFunction: this.tileLoader,
        params: {
          'LAYERS': this.props.layerSource,
          'VERSION': '1.1.1',
        },
        serverType: 'geoserver',
        tileGrid: new TileGrid({
          resolutions: [76.35146091935201, //11
            38.175730459676004, //12
            19.087865229838002, //13
            9.543932614919001, //14
            4.7719663074595005, //15
            2.3859831537297502,//16
            1.1929915768648751,//17
            0.5964957884324376,//18
            0.2982478942162188,//19
            0.1491239471081094,//20
            0.0745619735540547,//21
            0.03728098677702735,//22
            0.018640493388513674,//23
            0.009320246694256837//24
          ],
          origin: [550500, 9580500],
          tileSize: tileGrid
        })
      }),
    })
  }

  getOptionsFromWMTSGetCapabilities = async (layer) => {
    const parser = new WMTSCapabilities();
    let layersOptions = []

    let response;
    let text;
    let result;

    try {
      response = await fetch(`${process.env.REACT_APP_GEOSERVER_URL}/gwc/service/wmts?service=WMTS&version=1.1.1&request=GetCapabilities`)
    } catch (error) {
    }

    text = await response.text()
    // console.log(text)
    result = parser.read(text)

    const options = optionsFromCapabilities(result, {
      layer: layer,
      matrixSet: 'EPSG:31984',
    });

    return options

  }

  buildAndAddLayerWMTS = () => {
    const { options } = this.props;

    let layer

    if (!options) return layer
    layer = new TileLayer({
      opacity: 1,
      visible: this.props.visible,
      name: this.props.layerName,
      layerSource: this.props.layerSource,
      source: new WMTS(options),
    });

    return layer;
  };


  // toggleVisible() {
  //   // let layer = this.state.layer;
  //   // layer.setVisible(!this.state.layer.getVisible());
  //   // this.props.handler()
  // }

  // getVisible(){
  //   let layerSource = this.props.layerSource;
  //   let visible = false;
  //   try {
  //     this.props.layers.forEach(function (l) {
  //         if (l.get('layerSource') === layerSource) {
  //           visible = l.getVisible();
  //         }
  //       });
  //   } catch {
  //   }
  //   return visible
  // }

  render() {
    let layerOl3;
    let checkOlLayer = this.checkOlLayer(this.props.layerSource)
    const timeseries = this.props.timeseries || { supported: false }

    // NOTE: 
    if (!checkOlLayer) {
      if (this.props.service === 'WMS') {
        if (this.props.layerSource === 'OSM' || this.props.layerSource === 'XYZ') {
          layerOl3 = this.BuildBaseLayersWMS()
        } else {
          layerOl3 = this.buildLayerWMS()
          if (timeseries.supported) {
            this.renderSliderTimeseries(layerOl3, timeseries)
          }
        }
      }
      else if (this.props.service === 'WMTS') {
        if (this.props.layerSource === 'OSM' || this.props.layerSource === 'XYZ') {
          layerOl3 = this.BuildBaseLayersWMTS()
        } else {
          // NOTE: REVISAR funcao abaixo
          layerOl3 = this.buildAndAddLayerWMTS()
          // if (timeseries.supported) {
          //   this.renderSliderTimeseries(layerOl3, timeseries)
          // }
        }
      }
      else {
        layerOl3 = buildLayerWFS(this.props)
        setStyleFromSLD(this.props.style, layerOl3)
      }
      // this.props.updateLayer(layerOl3, this.props)
      // this.state = {layer: layerOl3}
      // this.addLayer(layerOl3)
      if (layerOl3 !== undefined) {
        this.addLayer(layerOl3)
      }
    }

    return (
      <div>

      </div>
    )
  }
}

const mapStateToProps = (store, ownProps) => {
  return {
    olmap: store.layersState.map,
    layers: store.layersState.layers,
    last_updated: store.layersState.last_updated
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ toggleVisible, updateLayers }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Layer);
// export default Layer;


