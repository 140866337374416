import React from 'react'
import IdeLogoFooter from './ide_logo_footer.png'
import Parceiro2 from './ide_parceiro_2.png'
import img4 from './ide_logo_4.png'
import img5 from './ide_logo_5.png'
import img6 from './ide_logo_6.png'
import img7 from './ide_logo_7.png'
import img8 from './logo_transparencia.png'
import './Parceiros.css'
class Parceiros extends React.Component {
  render() {
    return (
      <>
        {/* <div className='container-fluid'> */}
        <div className='row'>
          <div className='col-sm-12'>
            <div className=' justify-content-center text-center py-2' style={{ backgroundColor: '#10949E', borderColor: 'rgb(255 255 255 / 13%)' }}>
              <h5 className='text-light py-2 ' style={{ fontFamily: 'Palaquin Dark Bold', fontsize: '16px' }}>NOSSOS PARCEIROS E COLABORADORES</h5>
              <div className='row justify-content-center'>
                <div className='col-sm-12 col-md-4 col-lg-3 mb-2'>
                  <a href='https://mapas.fortaleza.ce.gov.br/'>
                    <img src={img6} style={{ maxHeight: "200px", maxWidth: '200px', borderRadius: '20px' }} />
                  </a>
                </div>
                <div className='col-sm-12 col-md-4 col-lg-3 mb-2'>
                  <a href='https://observatoriodefortaleza.fortaleza.ce.gov.br/'>
                    <img src={img4} style={{ maxHeight: "200px", maxWidth: '200px', borderRadius: '20px' }} />
                  </a>
                </div>
                <div className='col-sm-12 col-md-4 col-lg-3 mb-2'>
                  <a href='https://valorsueloamericalatina.org/'>
                    <img src={img5} style={{ maxHeight: "200px", maxWidth: '200px', borderRadius: '20px' }} />
                  </a>
                </div>
              </div>
              <div className='row mt-2 justify-content-center'>
                <div className='col-sm-12 col-md-4 col-lg-3 mb-2'>
                  <a href='https://dados.fortaleza.ce.gov.br/'>
                    <img src={img7} style={{ maxHeight: "200px", maxWidth: '200px', borderRadius: '20px' }} />
                  </a>
                </div>
                <div className='col-sm-12 col-md-4 col-lg-3 mb-2'>
                  <a href='https://portaltransparencia.fortaleza.ce.gov.br/'>
                    <img src={img8} className='bg-light p-2' style={{ maxHeight: "200px", maxWidth: '200px', borderRadius: '20px' }} />
                  </a>
                </div>

              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </>
    )
  }
}

export default Parceiros