import React from 'react';
import './listGroup.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons';

class ListGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: this.props.active || false
    };
    this.menu_id = this.props.menu;
    this.display = this.props.display;
    this.layers = this.props.layersGeoserver;
    this.listChildren = this.listChildren.bind(this);
  }

  changeColorCardHeader = (e) => {
    let header = e.target;
    // Check if the target element is an SVG element
    if (!(header instanceof HTMLDivElement)) {
        // Get the parent element of the SVG
        header = header.closest('.card-header');
    } 
    if(this.props.colorable){
      // Handle the case where the target is not an SVG element
      if (header.style.backgroundColor === '' && !this.state.active && this.hasChildren(header)) {
          header.style.backgroundColor = '#ffcc00';
      } else if (header.style.backgroundColor === '#ffcc00' && this.state.active) {
          header.style.backgroundColor = '';
      } else {
          header.style.backgroundColor = '';
      }
      
    }
};


  hasStringValue(obj, targetValue) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];

        if (typeof value === 'object') {
          // If the current value is an object, recursively check its properties
          if (this.hasStringValue(value, targetValue)) {
            return true;  // Found the value in a nested object
          }
        } else if (typeof value === 'string' && value === targetValue) {
          return true;  // Found the desired string value
        }
      }
    }

    return false;  // String value not found in the object
  }

  hasChildren() {
    return React.Children.count(this.props.children) > 0;
  }

  listChildren() {
    this.setState((prevState) => ({
      active: !prevState.active,
    }));
  }

  changeDisplayState(){

  }

  handleChildEvent(e) {
    e.stopPropagation();
  }

  removeLiElementByChildren(parentUl, parentName, childName) {
    var lis = parentUl.getElementsByTagName('li');
  
    for (var i = 0; i < lis.length; i++) {
      var li = lis[i];
  
      // Check if the li has the correct parent name
      if (li.textContent.includes(parentName)) {
        var children = li.children;
  
        // Check if the li has the correct child name and an ul
        if (children.length === 2 && children[0].textContent === childName && children[1].tagName.toLowerCase() === 'ul') {
          // Remove the found li
          parentUl.removeChild(li);
          return true;
        }
      }
    }
  
    // If no matching element is found
    return false;
  }
  
  componentDidMount(){
    var ul = document.getElementById('Aerolevantamento')
    this.removeLiElementByChildren(ul,'2016','Agrupamentos')
  }

  render() {
    if (this.state.shouldRemove) {
      return null;  // If shouldRemove is true, render nothing
    }

    let layerProps
    if(this.props.colorable && this.state.active){
      layerProps = "col-12 card-header my-1 text-start font-weight-bold bg-menu-layer"
    }else if(!this.props.colorable && this.state.active){
      layerProps = "col-12 card-header my-1 text-start font-weight-bold bg-open-layer"
    }else{
      layerProps = "col-12 card-header my-1 text-start font-weight-bold bg-closed-layer"
    }
    
    var id_with_underline = this.props.menu_id.replaceAll('-','_').replaceAll(' ','_')
    return (
      <li className='list-unstyled margin-layer'  onClick={this.listChildren} style={{display: `${this.props.display}`}} id={id_with_underline} >
        <div className={`${layerProps}`} style={{ cursor: 'pointer', color: 'black', borderRadius: '10px',  display: 'flex', justifyContent: 'space-between', fontSize: '15px' }} onClick={this.changeColorCardHeader}>
          {this.props.groupName}
            {this.state.active ? <FontAwesomeIcon icon={faAngleDown} /> : <FontAwesomeIcon icon={faAngleRight} />}
        </div>
        <ul className={this.state.active ? 'list-group list-group-flush nested active' : 'list-group list-group-flush nested'} onClick={(e) => this.handleChildEvent(e)}>
          {this.props.children}
        </ul>
      </li>
    );
  }
}

export default ListGroup;
