import { combineReducers } from 'redux'
import {layersReducer} from './layersReducer'
import {layersFeaturesInfoReducer} from './featureLayerReducer'
import {layersGeoserverReducer} from './layersGeoserverReducer'
import { layersGeoserverPublicReducer } from './layersGeoserverPublicReducer'
import {sidenavBtnReducer} from './sidenavBtnReducer'
import {metadataLayersReducer} from './metadataLayersReducer'

export const Reducers = combineReducers({
 layersState: layersReducer,
 FeatureInfoState: layersFeaturesInfoReducer,
 layersGeoserverState: layersGeoserverReducer,
 sidenavBtnState: sidenavBtnReducer,
 metadataLayersState: metadataLayersReducer,
 layersGeoserverPublicState: layersGeoserverPublicReducer
});