import {SET_BTN_INFO_ACTIVE, SET_BTN_MENU_LAYER_ACTIVE, SET_BTN_INFO_DESACTIVE} from '../actions/actionTypes';

const initial = {
  btnInfo: false,
  btnMenuLayer: false,
};



export const sidenavBtnReducer = (state = initial , action) => {

  switch (action.type) {
    case SET_BTN_INFO_ACTIVE:
      return { ...state, btnInfo: true, btnMenuLayer: false};
    case SET_BTN_INFO_DESACTIVE:
      return { ...state, btnInfo: false, btnMenuLayer: false};
    case SET_BTN_MENU_LAYER_ACTIVE:
      return { ...state, btnInfo: false, btnMenuLayer: true};
    default:
      return state;
  }
}
