import React from 'react';

import HeaderNav from './header';
import Map from './map/Map';
import SideNav from './SideNav/SideNav';
import LoginForm from './Login/Form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Navbar from './home/Navbar';
import Footer from './home/Footer';

class LayoutOld extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      last_login : Date.now()
    }
  }

  componentWillUnmount(){
    window.document.getElementsByTagName('body')[0].style = 'background: #E1F7FF;'
  }

  handleLogin = () => {
    this.setState({last_login: Date.now()})
    // this.props.map.updateSize(); 
    // if (document.getElementById('close-card-pmf')) {
    //   document.getElementById('close-card-pmf').click();
    // }

    // FIXME: Resolve situation when not rerender components after login
    window.location.reload();
  }
  render() {
    window.document.getElementsByTagName('body')[0].style = 'background: white;'    
    return (
      <div className="main">
        <Navbar handleLogin={this.handleLogin}/>
        {/* <HeaderNav handleLogin={this.handleLogin} /> */}
        <LoginForm handleLogin={this.handleLogin}/>
        <Map handleLogin={this.handleLogin}/>
        <SideNav handleLogin={this.handleLogin}/>
        <div className="modal" id="modal-app" tabIndex="-99999" role="dialog" aria-labelledby="modal-app"  data-keyboard="true" >
          <div className="modal-dialog" id="" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title"></h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
              </div>
              <div className="modal-body">
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store, ownProps) => {
  return {map: store.layersState.map}
}

export default connect(mapStateToProps, null)(LayoutOld);