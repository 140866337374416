import React from 'react';
import ListMenu from '../ListMenu';
import ListItem from '../../ListItem'

class CamadasAtivasListMenu extends React.Component {
  getCurrentGeoserverLayer(layer) {
    let selected = this.props.layersGeoserver.filter((layerGeoserver) => {
      return layerGeoserver.layerSource === layer.get('layerSource')
    })

    if (selected.length > 0) {
      return selected[0]
    } else {
      return { keywordList: ['WMS'] }
    }
  }

  render() {
    return (
      <ListMenu>
        <div style={{ paddingTop: '20px' }}>
          {
            this.props.activeLayers.map((layer, index) => (
              <ListItem
              key={`menu-${layer.get('layerSource')}`}
              idName={`menu-${layer.get('layerSource')}`}
              layerSource={layer.get('layerSource')}
              layerName={layer.get('name')}
              updateActiveLayers={this.props.updateActiveLayers}
              activeLayers={this.props.activeLayers}
              index={index}
              moveUp={this.props.moveUp}
              moveDown={this.props.moveDown}
              hasPopover={true}
              requestType={this.getCurrentGeoserverLayer(layer).keywordList.includes('WFS_VISUALIZADOR') ? 'WFS' : 'WMS'}
              choropleth={this.getCurrentGeoserverLayer(layer).keywordList.includes('CHOROPLETH_VISUALIZADOR')}
              showCheckbox={this.props.showCheckbox === undefined ? true : this.props.showCheckbox}
              showDownloads={this.props.showDownloads === undefined ? false : this.props.showDownloads}
              showLinkGeoservicos={this.props.showLinkGeoservicos === undefined ? false : this.props.showLinkGeoservicos}
              />
              ))
            }
        </div>
      </ListMenu>
    )
  };
}

export default CamadasAtivasListMenu;