import React from "react";

const VideoListCard = ({ palestrante, escolheVideo }) => {
  return (
    <div
      onClick={() => {
        escolheVideo(palestrante);
      }}
      className="card-item"
    >
      <div className="card-info">
        <div className="card-titulo">{palestrante.titulo}</div>
        <div className="card-data">{palestrante.data}</div>
      </div>
      <div className="card-play-container">
        <div className="card-play">
          <svg width="4em" height="4em" viewBox="0 0 16 16" className="bi bi-caret-right-square-fill" fill="whitesmoke" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm5.5 10a.5.5 0 0 0 .832.374l4.5-4a.5.5 0 0 0 0-.748l-4.5-4A.5.5 0 0 0 5.5 4v8z"/>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default VideoListCard;
