import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CC from '../../../creative_commons.png'
import CC2 from '../../../atribuicao_cc.jpg'
const MapFooter = (props) => {
  const [show, setShow] = useState(false);
  const [popTimeout, setPopTimeout] = useState(null);
  const [tipoCoord, setTipoCoord] = useState("EPSG:31984");

  const mudarTipoCoordenadas = (tipo) => {
    setTipoCoord(tipo);

    props.setTipoCoordenadas(tipo);
  };

  const radioSelection = (
    <div
      onClick={() => {
        if (popTimeout) {
          clearTimeout(popTimeout);
          handleOpen();
        }
      }}
    >
      <div className="radio">
        <label>
          <input
            type="radio"
            name="formato"
            value={"EPSG:31984"}
            defaultChecked={tipoCoord === "EPSG:31984"}
            onClick={() => mudarTipoCoordenadas("EPSG:31984")}
          />
          EPSG:31984 (Padrão)
        </label>
      </div>
      {/*
      <div className="radio">
        <label>
          <input
            type="radio"
            name="formato"
            defaultChecked={tipoCoord === "EPSG:4674"}
            onClick={() => mudarTipoCoordenadas("EPSG:4674")}
          />
          EPSG:4674
        </label>
      </div> */}
    </div>
  );
  const popover = (
    <Popover id="popover-basic">
      <Popover.Content>{radioSelection}</Popover.Content>
    </Popover>
  );

  const handleClose = () => {
    setShow(false);
    clearTimeout(popTimeout);
  };
  const handleOpen = () => {
    setShow(true);
    setPopTimeout(
      setTimeout(() => document.getElementById("pop-click").click(), 2000)
    );
  };
  return (
    <div id="map-footer" className="map-footer">
      <div></div>
      <div id="coordenadas" className="coordenadas">
        <span>Coordenadas:</span>
        <div id="coordenadas-tag"></div>
        <span>{tipoCoord}</span>
        <OverlayTrigger placement="top" overlay={popover} trigger="click">
          <span
            onClick={() => (show ? handleClose() : handleOpen())}
            id="pop-click"
          >
            {show ? (
              <FontAwesomeIcon icon="arrow-alt-circle-down" />
            ) : (
              <FontAwesomeIcon icon="arrow-alt-circle-up" />
            )}
          </span>
        </OverlayTrigger>
        <img src={CC2} alt="Creative Commons" width={88} style={{ display: "block", margin: "0 auto", position: 'absolute', bottom: 25, zIndex: 0, right: 10 }} />
      </div>

    </div>
  );
};

export default MapFooter;
