import React from 'react';

class ListMenu extends React.Component {
  render() {
    return(
      <ul className='list-group list-group-flush' id='menu'>
        {React.Children.map(this.props.children,  layer => {
          return layer;
        })}
      </ul>
    )
  }
}

export default ListMenu;