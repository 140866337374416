import StyleStroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import StyleFill from 'ol/style/Fill';
import StyleText from 'ol/style/Text';
import StyleCircle from 'ol/style/Circle';

export const highlightStyleWFS = (feature) => {
    if (feature.getGeometry().getType() === 'Point') {
        return new Style({
            image: new StyleCircle({
              radius: 5,
              fill: new StyleFill({color: 'rgba(255, 255, 255, 0.5)'}),
              stroke: new StyleStroke({
                color: 'rgba(255, 0, 0, 0.4)',
                 width: 2
              })
            })
        })
    } else {
        return new Style({
            text: new StyleText({
                // text: `${feature.get(entity.label_attr()) || ''}`,
                font: '12px Calibri,sans-serif',
                overflow: true,
                fill: new StyleFill({
                    color: '#000'
                }),
                stroke: new StyleStroke({
                    color: '#fff',
                    width: 2
                })
            }),
            fill: new StyleFill({
                //color: getColorHover(f)
                // color: entity.getColorHover() || 'rgba(0,0,0,0.2)'
                color: 'rgba(0,0,0,0.2)'
            }),
            stroke: new StyleStroke({
                color: 'red',
                width: 2
            })
          });
            // return new Style({
        //     text: new StyleText({
        //         // text: `${feature.get(entity.label_attr()) || ''}`,
        //         font: '12px Calibri,sans-serif',
        //         overflow: true,
        //         fill: new StyleFill({
        //             color: '#000'
        //         }),
        //         stroke: new StyleStroke({
        //             color: '#fff',
        //             width: 2
        //         })
        //     }),
        //     fill: new StyleFill({
        //         //color: getColorHover(f)
        //         // color: entity.getColorHover() || 'rgba(0,0,0,0.2)'
        //         color: 'rgba(0,0,0,0.2)'
        //     }),
        //     stroke: new StyleStroke({
        //         color: 'black',
        //         width: 0
        //     })
        //   });
    }
  
}


export const pointerMov = (map) => {
  

}