import React from 'react';
import './slidertimeseries.css'

class SliderTimeSeries extends React.Component {


  render() {
    
    return (
      <div className="slidecontainer">
          <input type="range" min="0" max="10" step="1" className="slider" id="myRange"></input>
          <div>Ano: <span id="date_value"></span></div>
      </div>
    );
  }
}

export default SliderTimeSeries;