import React from 'react';
import ListMenu from '../ListMenu';
import ListGroup from '../../ListGroup'
import { connect } from 'react-redux';
import { capitalize } from '../../../../utils/camelcase.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { builderMenu, builderGroupItems, renderListItem } from './common';

class CamadasDisponiveisListMenu extends React.Component {
  builderList = (layer) => 
    renderListItem(layer, this.props);

    filterCamadas = () => {
      // Declare variables
      let input, filter, ul, li, a, i, txtValue, h2, spans, cardHeaders;
      input = document.getElementById('filter-camadas');
      filter = input.value.toUpperCase();
      ul = document.getElementById("menu");
      li = ul.getElementsByTagName('li');
      h2 = document.getElementsByTagName('h2');
      spans = document.getElementsByTagName('span');
      cardHeaders = document.getElementsByClassName('card-header');
      
      // Loop through all h2 elements
      for (i = 0; i < h2.length; i++) {
          txtValue = h2[i].textContent || h2[i].innerText;
  
          if (txtValue.toUpperCase().includes(filter) && filter.length > 0) {
              h2[i].classList.add('active');
              h2[i].style.color = "var(--bg-color-pmf-buttons-active)";
              // h2[i].style.fontWeight = 'bold';
          } else {
              h2[i].classList.remove('active');
              // h2[i].style.fontWeight = 'bold';
              h2[i].style.color = "#888888";
          }
  
          if (filter.length === 0) {
              h2[i].style.color = "black";
          }
      }
  
      
  
      // Loop through all card-header elements
      for (i = 0; i < cardHeaders.length; i++) {
          txtValue = cardHeaders[i].textContent || cardHeaders[i].innerText;
  
          if (txtValue.toUpperCase().includes(filter) && filter.length > 0) {
              cardHeaders[i].classList.add('active');
              cardHeaders[i].style.color = "var(--bg-color-pmf-buttons-active)";
              // cardHeaders[i].style.fontWeight = 'bold';
          } else {
              cardHeaders[i].classList.remove('active');
              // cardHeaders[i].style.fontWeight = 'bold';
              cardHeaders[i].style.color = "#888888";
          }
  
          if (filter.length === 0) {
              cardHeaders[i].style.color = "black";
          }
      }
      // Loop through all list items, and hide those who don't match the search query
      for (i = 0; i < li.length; i++) {
        li[i].setAttribute("key",i)
        a = li[i].getElementsByTagName("label")[0];
        txtValue = a.textContent || a.innerText;
        if (txtValue.toUpperCase().match(filter) && filter.length > 0) {
          // add class active at parent element matched
          li[i].parentNode.classList.add('active')
          // add class active and red color at element matched
          // li[i].classList.add('active')
          li[i].style.color = "var(--bg-color-pmf-buttons-active)";
          // li[i].style.fontWeight = 'bold';
        } else {
          // li[i].style.fontWeight = 'normal';
          // li[i].style.fontWeight = 'bold';
          
          // Remove class active at element not matched
          li[i].classList.remove('active')
          // Check if item active exist. When not exist set elment parent collapse.
          if (li[i].parentNode.getElementsByClassName('active').length === 0) {
            li[i].parentNode.classList.remove('active')
          // li[i].style.fontWeight = 'bold';

          }
          // add class gray color at element not matched
          li[i].style.color = "#888888";
          // li[i].style.fontWeight = 'bold';

        }
  
        // Reset color when not search filter value
        if (filter.length === 0) {
          li[i].style.color = "black";
          // li[i].style.fontWeight = 'bold';
        }
      }

      // Loop through all span elements
      for (i = 0; i < spans.length; i++) {
        if (!spans[i].classList.contains('fa-stack') && !spans[i].classList.contains('span-navbar') && !spans[i].classList.contains('map-box')) {
            txtValue = spans[i].textContent || spans[i].innerText;

            if (txtValue.toUpperCase().includes(filter) && filter.length > 0) {
                spans[i].classList.add('active');
                spans[i].style.color = "var(--bg-color-pmf-buttons-active)";
                // spans[i].style.fontWeight = 'bold';
            } else {
                spans[i].classList.remove('active');
                // spans[i].style.fontWeight = 'bold';
                spans[i].style.color = "#888888";
            }

            if (filter.length === 0) {
                spans[i].style.color = "black";
            }
        }
    }
    }

  render() {
    const { layersGeoserver } = this.props;
    if (this.props.workspaces.length === 0) return (<ListMenu></ListMenu>);
    
    this.props.treeMenu.sort(dynamicSort("item"));
    return (
      <div>
        <div className={`form-group form-group-search`}>
          <input className="form-control form-control-sm" type="text"
          placeholder="" id='filter-camadas' onChange={() => this.filterCamadas()} />
          <FontAwesomeIcon icon='search'></FontAwesomeIcon>
        </div>
        <ListMenu>
          {
            this.props.treeMenu.map((menu, index) => {
              return <ListGroup key={menu.id} groupName={capitalize(menu.item)} colorable={true} menu_id={menu.id}>
                {builderGroupItems(menu, layersGeoserver, this.props)}
                {builderMenu(menu, layersGeoserver, this.props)}
              </ListGroup>
            })
          }
        </ListMenu>
      </div>
    )
  };
}

const mapStateToProps = (store, ownProps) => {
  return {
    layersGeoserver: store.layersGeoserverState.layersGeoserver,
    workspaces: store.layersGeoserverState.workspaces,
    metadataLayers: store.metadataLayersState.metadata,
    treeMenu: store.layersGeoserverState.treeMenu
  }
}

function dynamicSort(property) {
  var sortOrder = 1;
  if(property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
  }
  return function (a,b) {
      /* next line works with strings and numbers, 
       * and you may want to customize it to your needs
       */
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
  }
}

export default connect(mapStateToProps, null)(CamadasDisponiveisListMenu);

// export default AdicionarCamadaListMenu;
