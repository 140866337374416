import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { setOpacity } from '../../../actions';
import { bindActionCreators } from 'redux';

class Transparency extends React.Component {
  constructor (props) {
    super(props);
    this.updateTransparency   = this.updateTransparency.bind(this);
    this.setTransparent = this.setTransparent.bind(this);
    this.setVisible = this.setVisible.bind(this);
    this.state = {
      transparency : this.getLayerOpacity()
    }
  }

  updateTransparency(e){
    // Inverte valor do input
    this.props.setOpacity(this.props.layerSource, parseFloat(e.target.value));
    this.setState (
      { transparency :  e.target.value }
    )
  }

  getLayerOpacity(){
    const layerSource = this.props.layerSource
    let opacity = 0;
    this.props.layers.forEach ( function (layer) {
      if (layer.get('layerSource') === layerSource) {
        opacity = layer.getOpacity();
      }
    })
    return opacity
  }

  setTransparent(){
    this.props.setOpacity(this.props.layerSource, 0);
    this.setState (
      { transparency :  0 }
    )
  }

  setVisible(){
    this.props.setOpacity(this.props.layerSource, 1);
    this.setState (
      { transparency :  1 }
    )
  }

  render () {
    return (
      <div>
        <div className="popover-title">Transparência:
          <span className="slider-value-distritos"> {((1 - this.state.transparency) * 100).toFixed()}% </span>
        </div>
        <div className='opacity-field'>
          <br/>
          <span className="blue-theme clickable">
            <FontAwesomeIcon icon='eye' onClick={this.setVisible} ></FontAwesomeIcon>
          </span>
          <input
            type="range"
            id="slider-opacity-distritos"
            className="slider-opacity"
            min="0" max="1" step="0.1"
            value={this.state.transparency}
            onChange={this.updateTransparency}
          />
          <span className="blue-theme clickable">
            <FontAwesomeIcon icon='eye-slash' onClick={this.setTransparent} ></FontAwesomeIcon>
          </span>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (store, ownProps) => {
  return {layers: store.layersState.layers, last_updated: store.layersState.last_updated}
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({setOpacity}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Transparency);