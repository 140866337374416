import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './index.css';
import './application.scss';
import App from './App';
import AppOld from './AppOld';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { Store } from './store';
import  Home  from './pages/Home';
import Noticia from './pages/Noticia';
import Tutoriais from './pages/Tutoriais';
import Pesquisa from './pages/Pesquisa'
import  Metadados  from './pages/Metadados';
import  Contato  from './pages/Contato';
import  Sobre  from './pages/Sobre';
import  Geoservicos  from './pages/Geoservicos';
import Camadas from './pages/CamadasTematicas';
import Duvidas from './pages/Duvidas';
import  Downloads  from './pages/Downloads';
import Noticias from './pages/Noticias';
import Eventos from './components/Eventos'
import ErrorBoundary from './utils/ErrorBoundary'
import ErrorPage from './components/Error';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  //Link
} from "react-router-dom";
ReactDOM.render(
  <Router>
    <ErrorBoundary>
      <Provider store={Store}>
        <Switch>
            <Route path="/" exact={true} component={Home} />
            <Route path="/metadados" exact={true} component={Metadados} />
            <Route path="/visualizador" exact={true} component={AppOld} />
            <Route path="/sobre" exact={true} component={Sobre} />
            {/* <Route path="/contato" exact={true} component={Contato} /> */}
            <Route path="/geoservicos" exact={true} component={Geoservicos} />
            <Route path="/downloads" exact={true} component={Downloads} />
            <Route path="/eventos" exact={true} component={Eventos} />
            <Route path="/error" exact={true} component={ErrorPage} />
            <Route path="/noticia/:slug" component={Noticia} />
            <Route path="/tutoriais" exact={true} component={Tutoriais}/>
            <Route path="/pesquisa" exact={true} component={Pesquisa}/>
            <Route path="/duvidas" exact={true} component={Duvidas}/>
            <Route path="/noticias" exact={true} component={Noticias}/>
            {/* <Route path="/camadas/:slug" exact={true} component={Camadas}/> */}
        </Switch>
      </Provider>
    </ErrorBoundary>
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
