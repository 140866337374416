import React from 'react';
import { connect } from 'react-redux';
import Logo from './logo-pmf.png'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import SefinImg from './Rectangle 55.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TermsModal from '../Termos';
import './Footer.css'
class Footer extends React.Component {
  constructor(props) {
    super(props)
  }
  // boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)' 


  render() {
    return (
      <>
        <div id='footer' className='row border-footer' style={{ fontSize: '14px', boxShadow: '0px 5px 20px -10px rgba(0, 0, 0, 0.5)'}}>
          <div className='col-sm-12 col-md-6 px-0 border-image' style={{ overflow: 'hidden' }}>
            <img src={SefinImg} className='img-fluid w-100 h-100'/>
          </div>
          <div className='col-sm-12 col-md-6 text-center bg-white ' style={{ borderRadius: '0px 0px 20px 20px', overflow: 'hidden', borderBottom: '0px', borderLeft: '0px' }}>
            <div className='row justify-content-center  align-items-center h-100'>
              <div className='col-8'>
                <img src={Logo} style={{ width: '220px' }} className='mt-2 col-12' />
                <p className='text-center mt-4' style={{ fontFamily: 'Palaquin' }}>Surgido em razão da necessidade de transparência dos dados de órgãos públicos a IDE-SEFIN tem como objetivo compartilhar aos cidadãos o material geografico produzido nas atividades da Secretaria de Finanças.</p>
                <h3 id='contato' className='font-weight-bold text-info' style={{ fontFamily: 'Palaquin Dark Regular' }}>Contatos:</h3>
                <p className='text-info' style={{ fontSize: '20px', fontFamily: 'Palaquin' }}>
                  <FontAwesomeIcon icon={faEnvelope} /> Email: ide@sefin.fortaleza.ce.gov.br
                </p>
                <p className='text-info' style={{ fontSize: '20px', fontFamily: 'Palaquin' }}>
                  <FontAwesomeIcon icon={faPhone} /> Telefone: +55 (85) 3452-1790
                </p>
                <p className='text-info' style={{ fontSize: '20px', fontFamily: 'Palaquin' }} data-toggle="modal" data-target="#exampleModal">
                  <a onClick={this.handleCloseTermsModal}>Termos de Uso</a>
                </p>

                <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h3 className="modal-title" id="staticBackdropLabel">Termos de Uso</h3>
                        {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onClose}></button> */}
                      </div>
                      <div className="modal-body text-justify">
                        O material disponibilizado na plataforma N​ÃO dispensa ou substitui qualquer documento oficial emitido pelo Município, a exemplo de licenças, alvarás,
                        fichas de informação, declarações, certidões ou documentos referentes as demais obrigações legais e administrativas. ​As informações ​​disponibilizadas se destinam ao público, profissionais em geral,
                        professores e alunos dos diversos níveis de ensino, democratizando e favorecendo a acessibilidade, a transparência e ciência das ações e dados espaciais sob a competência da Secretaria Municipal das Finanças de Fortaleza (SEFIN).
                      </div>
                      <div className="modal-footer justify-content-center">
                        {/* <button type="button" className="btn btn-primary" data-bs-dismiss="modal" >Li e aceito os termos.</button> */}
                        {/* <button type="button" className="btn btn-secondary">Voltar</button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </>
    )
  }
}

const mapStateToProps = (store, ownProps) => {
  return { map: store.layersState.map }
}

export default connect(mapStateToProps, null)(Footer);