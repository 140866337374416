import React from 'react';
import { connect } from 'react-redux';
import './Noticias.css'
import Noticia from '../../pages/Noticia';
import IMAGEMNOTICIA from '../home/img_noticia_1.80f46877.png'
import IMAGEMNOTICIA2 from '../../../src/pages/img_noticia_2.png'
import IMAGEMNOTICIA3 from '../../../src/pages/THUMBNAIL_NOTA_CHAMAMENTO.png'
import { faUnderline } from '@fortawesome/free-solid-svg-icons';
class Noticias extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className=" mb-3 bg-transparent  h-100" style={{ marginTop: '9px' }}>
        <div className="rounded-pill py-1  text-center text-white font-weight-bold text-uppercase" style={{ backgroundColor: '#10949E', borderColor: 'rgb(255 255 255 / 13%)', fontFamily: 'Palaquin Dark Bold', fontSize: '16px'}}>
          Notícias
        </div>
        <div className="row" style={{ background: '' }}>
          <div className="col-12">
          <div className="bg-transparent border-0">
              <div className='d-flex justify-content-between'>
                <a href='noticia/chamamento-sua-pesquisa' className='small  text-dark d-flex'>
                  <img src={IMAGEMNOTICIA3} className='float-left mt-4' style={{ width: '120px', height: '120px', borderRadius: '10px' }} />
                  <div className='flex-column ml-3 d-flex align-items-start mb-2' style={{ fontFamily: 'Palaquin', fontSize: '16px' }}>
                    <br />
                    <span className="fs-noticia" style={{ fontFamily: 'Palaquin Dark', fontSize: '16px' }}>Publicação</span>
                    <span style={{ textDecoration: 'underline', lineHeight: '24px', fontFamily: 'Palaquin Dark' }}>CHAMAMENTO: queremos conhecer sua pesquisa</span>
                    <p className='' style={{ fontFamily: 'Palaquin', fontsize: '16px', color: '#252525', fontWeight: '600' }}>Utilizou nossos dados para elaborar sua pesquisa ou trabalho? Queremos conhecer seu produto!</p>
                  </div>
                </a>
              </div>
              <br/>
              <hr className='hr-noticias' style={{ background: "#10949E" }} />
            </div>
          <div className="bg-transparent border-0">
              <div className='d-flex justify-content-between'>
                <a href='noticia/novas-camadas-disponiveis' className='small  text-dark d-flex'>
                  <img src={IMAGEMNOTICIA2} className='float-left mt-4' style={{ width: '120px', height: '120px', borderRadius: '10px' }} />
                  <div className='flex-column ml-3 d-flex align-items-start mb-2' style={{ fontFamily: 'Palaquin', fontSize: '16px' }}>
                    <br />
                    <span className="fs-noticia" style={{ fontFamily: 'Palaquin Dark', fontSize: '16px' }}>Publicação</span>
                    <span style={{ textDecoration: 'underline', lineHeight: '24px', fontFamily: 'Palaquin Dark' }}>Novas camadas disponíveis!</span>
                    <p className='' style={{ fontFamily: 'Palaquin', fontsize: '16px', color: '#252525', fontWeight: '600' }}>Conheça novos mapas altimétricos e atualização de camadas do levantamento
aerofotogamétrico de 2016</p>
                  <br/>
                  </div>
                </a>
              </div>
              {/* <hr className='hr-noticias' style={{ background: "#FFCC00" }} /> */}
            </div>
            <br/>
            <hr className='hr-noticias' style={{ background: "#10949E" }} />
            <div className="bg-transparent border-0">
              <div className='d-flex justify-content-between'>
                <a href='noticia/novas-camadas-adicionadas' className='small  text-dark d-flex'>
                  <img src={IMAGEMNOTICIA} className='float-left mt-4' style={{ width: '120px', height: '120px', borderRadius: '10px' }} />
                  <div className='flex-column ml-3 d-flex align-items-start mb-2' style={{ fontFamily: 'Palaquin', fontSize: '16px' }}>
                    <br />
                    <span className="fs-noticia" style={{ fontFamily: 'Palaquin Dark', fontSize: '16px' }}>Publicação</span>
                    <span style={{ textDecoration: 'underline', lineHeight: '24px', fontFamily: 'Palaquin Dark' }}>IDE-SEFIN de cara nova!</span>
                    <p className='' style={{ fontFamily: 'Palaquin', fontsize: '16px', color: '#252525', fontWeight: '600' }}>A Infraestrutura de Dados Espaciais da SEFIN reinaugura seu site disponibilizando novas camadas, dispondo de facilidades e de novas funcionalidades para os usuários</p>
                  </div>
                </a>
              </div>
              <br/>
              <hr className='hr-noticias' style={{ background: "#10949E" }} />
            </div>
            
          </div>
        </div>
      </div>

    )
  }
}

const mapStateToProps = (store, ownProps) => {
  return { map: store.layersState.map }
}

export default connect(mapStateToProps, null)(Noticias);