import React from 'react';
import HeaderNav from '../components/header';
import Carousel from '../components/home/Carousel';
import Card from '../components/home/Card';
import '../components/home/subnav.css'
import LoginForm from '../components/Login/Form';
import {Container, Col, Row} from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Navbar from '../components/home/Navbar';
import Footer from '../components/home/Footer';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope, faComment } from '@fortawesome/free-regular-svg-icons'
import './Contato.css'
class Contato extends React.Component {

  constructor(props) {
    super(props)
    const InitialState = {
    }
  }


  handleLogin = () => {
    this.setState({last_login: Date.now()})
    // this.props.map.updateSize(); 
    // if (document.getElementById('close-card-pmf')) {
    //   document.getElementById('close-card-pmf').click();
    // }

    // FIXME: Resolve situation when not rerender components after login
    window.location.reload();
  }


  render() {
    if(window.localStorage.getItem('aceitoTermoUso')){
      return (
        <div className="">
            <Navbar handleLogin={this.handleLogin}/>
            {/* <HeaderNav menu='home'  handleLogin={this.handleLogin}/> */}
            <LoginForm handleLogin={this.handleLogin}/>
            
            <div className='container text-center bg-white rounded-bottom'>
            <h1 className='text-center py-2'>Contato</h1>
              <div  className="row bg-white">
                <div className='col-sm-12 col-md-4 mb-4'>
                  <span className='fa-stack fa-2x'>
                    <FontAwesomeIcon icon={faCircle} className='mt-2 fa-stack-2x' style={{fontSize: '30px', color: '#4CBABF'}}/>
                    <FontAwesomeIcon icon={faEnvelope} className='mt-4 fa-stack-1x' style={{fontSize: '30px', color: 'white'}}/>
                  </span>
                  <p className='font-weight-bold' style={{color: '#4CBABF'}}>Email</p>
                  <p className='text-muted'>Tem alguma dúvida ?</p>
                  <a href="mailto:ide@sefin.fortaleza.ce.gov.br" className='text-dark font-weight-bold'>
                      ide@sefin.fortaleza.ce.gov.br
                  </a>
                </div>
                <div className='col-sm-12 col-md-4'>
                <span className='fa-stack fa-2x'>
                    <FontAwesomeIcon icon={faCircle} className='mt-2 fa-stack-2x' style={{fontSize: '30px', color: '#4CBABF'}}/>
                    <FontAwesomeIcon icon="phone" className='mt-4 fa-stack-1x' style={{fontSize: '30px', color: 'white'}}/>
                  </span>
                  {/* <FontAwesomeIcon icon="phone" className='mt-2' style={{fontSize: '30px'}}/> */}
                  <p className='font-weight-bold' style={{color: '#4CBABF'}}>Telefone</p>
                  <p className='text-muted'>Você pode ligar das - às -</p>                     
                  <a href="tel:+558534521790" className='text-dark font-weight-bold'>
                  +55 (85) 3452 1790
                  </a>
                </div>
                <div className='col-sm-12 col-md-4'>
                <span className='fa-stack fa-2x'>
                    <FontAwesomeIcon icon={faCircle} className='mt-2 fa-stack-2x' style={{fontSize: '30px', color: '#4CBABF'}}/>
                    <FontAwesomeIcon icon={faComment} className='mt-4 fa-stack-1x' style={{fontSize: '30px', color: 'white'}}/>
                  </span>
                  {/* <FontAwesomeIcon icon={faComment} className='mt-2' style={{fontSize: '30px'}} /> */}
                  <p className='font-weight-bold' style={{color: '#4CBABF'}}>Dúvidas</p>
                  <p className='text-muted'>Precisa de ajuda ?</p>
                </div>
              </div>
    
            </div>
            {/* <div className='container-fluid'>
              <Footer/>
            </div> */}
        </div> 
      );

    }else{
      window.location.href = '/'; // Redirect to the home page
      return null;
    }
  }
}

export default Contato;