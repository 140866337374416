import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {setStyleLayer } from '../../../actions';
import {setStyleFromSLD} from '../../../utils/OlWFSStyle';

class ChoroplethClasses extends React.Component {
  constructor (props) {
    super(props);

    let isChoropleth = this.isChoroplethLayer();
    let choroplethClasses = 5;
    if (isChoropleth) {
      choroplethClasses = this.getCurrentChoroplethLevel();
    }
    //define state
    this.state = {
      choroplethClasses : choroplethClasses,
      isChoropleth: isChoropleth,
      choroplethClassMin: this.getChoroplethClassMin() ,
      choroplethClassMax: this.getChoroplethClassMax() 
    }
  }

  getChoroplethClassMax() {
    try {
      var max = this.props.currentGeoserverLayer['keywordList'].filter(
        (i) => i.match(/CHOROPLETH_CLASS_MAX_/)
        )
      max = parseInt(max[0].replace('CHOROPLETH_CLASS_MAX_', ''))
    } catch (error) {
      max = 10
    }
    return max
  }
  getChoroplethClassMin() {
    try {
      var min = this.props.currentGeoserverLayer['keywordList'].filter(
        (i) => i.match(/CHOROPLETH_CLASS_MIN/)
        )
      min = parseInt(min[0].replace('CHOROPLETH_CLASS_MIN_', ''))
    } catch (error) {
      min = 5
    }
    return min
  }
  isChoroplethLayer() {
    return this.props.currentGeoserverLayer 
            && this.props.currentGeoserverLayer['keywordList']
            && this.props.currentGeoserverLayer['keywordList'].includes('CHOROPLETH_VISUALIZADOR')
  }

  getChoroplethStyleNameSLD(num_classes) {
    return this.props.currentGeoserverLayer.style.replace(/_(\d+)$/g, '') + '_' + num_classes

  }
  updateChoroplethClasses = (e) => {
    let choropleth_style = this.getChoroplethStyleNameSLD(e.target.value)
    if(this.isVectorLayer()) {
      setStyleFromSLD(choropleth_style, this.props.currentLayer)
    } else {
      this.props.currentLayer.getSource().updateParams({STYLES: choropleth_style})
    }
    this.props.setStyleLayer(this.props.currentLayer.get('layerSource'), choropleth_style)
    this.setState (
      { choroplethClasses :  e.target.value }
    )
  }

  getCurrentChoroplethLevel(){
    let level

    if (this.isVectorLayer()) {
      try {
        level = this.props.currentLayer.getStyle().__geoStylerStyle.rules.length
      } catch (error) {
        level = 5
      }
    } else {
      try {
        level = this.props.currentLayer.getSource().getParams().STYLES.split('_').reverse()[0]
      } catch (error) {
        level = 5
      }
    }
    return isNaN(parseInt(level)) ? 5 : parseInt(level)
  }

  isVectorLayer(){
    return this.props.currentLayer.constructor.name === 'VectorLayer'
  }

  render () {
    if (!this.state.isChoropleth){
      return(<div/>);
    }

    return (
      <div>
        Classes
        <span className="slider-value-distritos"> {this.state.choroplethClasses} </span>
        <div className='opacity-field slider-choropleth '>
          <br/>
          <span className="blue-theme ">
            <FontAwesomeIcon icon='minus'></FontAwesomeIcon>
          </span>
          <input
            type="range"
            id="slider-opacity-distritos"
            min={this.state.choroplethClassMin} max={this.state.choroplethClassMax} step="1"
            value={this.state.choroplethClasses}
            onChange={this.updateChoroplethClasses}
          />
          <span className="blue-theme ">
            <FontAwesomeIcon icon='plus'></FontAwesomeIcon>
          </span>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (store, ownProps) => {
  return {layers: store.layersState.layers, last_updated: store.layersState.last_updated, layersGeoserver: store.layersGeoserverState}
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({setStyleLayer}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChoroplethClasses);
