import React from 'react';
import HeaderNav from '../components/header';
import Carousel from '../components/home/Carousel';
import Card from '../components/home/Card';
import '../components/home/subnav.css';
import manual_pdf from '../components/home/Manual_de_utilização_v1.0.pdf';
import Navbar from '../components/home/Navbar';
import Footer from '../components/home/Footer';
import './Sobre.css'
import LoginForm from '../components/Login/Form'
class Sobre extends React.Component {

  constructor(props) {
    super(props)
    const InitialState = {
    }
  }

  handleLogin = () => {
    this.setState({last_login: Date.now()})
    // this.props.map.updateSize(); 
    // if (document.getElementById('close-card-pmf')) {
    //   document.getElementById('close-card-pmf').click();
    // }

    // FIXME: Resolve situation when not rerender components after login
    window.location.reload();
  }

  render() {
    if(window.localStorage.getItem('aceitoTermoUso')){
      return (
        <>
          <Navbar handleLogin={this.handleLogin}/>
          <LoginForm handleLogin={this.handleLogin}/>
          <div className=" container bg-white ">
            {/* <HeaderNav menu='home'/> */}
                  <h1 className='text-center py-2'>Sobre a IDE</h1>
                <div className="float-right">
                  {/* <a href={manual_pdf} target="_blank" download="Manual_de_utilização_v1.0.pdf" className="btn-sm btn btn-primary">
                    Manual
                  </a> */}
                </div>
            <div className='py-5 px-4'>
              <div className=''>
                <div className='row text-justify'>
                </div>
                <div className='row text-justify'>
                  <h2>O QUE É UMA INFRAESTRUTURA DE DADOS ESPACIAIS (IDE)?</h2>
                  <blockquote className="blockquote blockquote-footer blockquote-padding">
                    CONCAR (2010): “Conjunto integrado de tecnologias, políticas, mecanismos e procedimentos de coordenação e monitoramento, padrões e acordos para facilitar o armazenamento, acesso, compartilhamento e disseminação e o uso de dados geoespaciais do nivel federal ao distrital”
                  </blockquote>
                  <h2>OBJETIVO DA IDE-SEFIN</h2>
                  <ol>
                    <li>Promover o adequado ordenamento da geração, armazenamento, acesso, compartilhamento, disseminação e uso dos dados geoespaciais de origem da Secretaria das Finanças do Município (SEFIN) ou de outras secretarias interessadas (que firmem convênio com esse propósito), composta pelos seguintes geoserviços:
                      <ol type="a">
                        <li>Visualizador de Mapas (geoportal-SEFIN)</li>
                        <li>Catálogo de Metadados</li>
                        <li>Serviços espaciais (através de webservice com protocolos OGC)</li>
                      </ol>
                    </li>
                    <li>Integrar-se com a futura IDE Municipal a ser desenvolvida no âmbito do Programa Fortaleza Cidade Sustentável (FCS) e a Infraestrutura Nacional de Dados Espaciais – INDE</li>
                    <li>Concentrar, em uma única plataforma, todo o compartilhamento e disseminação dos geoserviços a cargo da SEFIN, de tal sorte a manter uma política de segurança única de acesso a dados geoespaciais com controle de acesso e facilidade de documentação (através dos metadados) e manutenção. </li>
                    <li>Eliminar a duplicidade de ações e o desperdício de recursos na obtenção de dados geoespaciais dentro da própria SEFIN ou pelos demais órgãos da administração pública municipal.</li>
                    <li>Adequar a base cartográfica do Município com as recomendações da Portaria 511/2009 do Ministério das Cidades e recomendações técnicas para dados vetoriais da Comissão Nacional de Cartografia (CONCAR) com fito a futuras integrações com cartórios de registro de imóveis e ao Sistema nacional de gestão de informações territoriais (SINTER).</li>
                  </ol>
  
  
                  <h2>JUSTIFICATIVA DA IDE-SEFIN</h2>
                  <p>
                    Nos termos do Código Tributário Municipal (Lei Complementar no 159 de 23 de dezembro de 2013), a SEFIN é competente para a gestão e a manutenção do Cadastro Imobiliário, mormente quanto aos dados cadastrais de todas as unidades e subunidades imobiliárias existentes no Município, independentemente da sua categoria de uso ou da tributação incidente, bem como o caráter multifinalitário desse cadastro.
                  </p>
                  <p>
                    Em termos objetivos, a SEFIN controla através da aplicação SITFOR, a criação, alteração e exclusão das seguintes entidades com natureza alfanumérica e vetorial (espacial): lotes (parcelas territoriais), unidades autônomas (inscrições municipais), unidades de avaliação (edificações), condomínios edilícios, quadras fiscais, distritos fiscais, bairros, limite do Município, logradouros e trechos de logradouros (e sua infraestrutura), macrozonas do plano diretor dentre outras. Essas entidades compõem a representação cartográfica base para todo território do nosso Município.
                  </p>
                  <p>
                    É cediço os diversos investimentos já realizados pela SEFIN na atualização da sua base cartográfica e imobiliária com a contratação de execução serviços de cobertura aerofotogramétrica para geração de ortofotos (realizadas 2 nos últimos anos), scaneamento a laser para detecção de áreas edificadas acrescidas, modelos digitais de terrenos e edificações, dentre outros, sempre com intuito de melhorar a qualidade dos lançamentos dos tributos patrimoniais e promover a justiça fiscal. Esses investimentos a tornaram a maior detentora e fornecedora de dados geoespaciais dentre todas as secretarias do Município.
                  </p>
                  <p>
                    O volume cada vez crescente de dados geoespaciais a cargo da competência legal da SEFIN demanda um maior controle na sua manutenção e publicização, já que essas informações são bem mais complexas que uma informação alfanumérica tradicional, devendo serem disponibilizadas em formatos padronizados que possibilitem o uso nas mais diversas plataformas de aplicações de dados daqueles que a consomem.
                  </p>
                  <p>
                    Nesse ínterim, é que surge a solução tecnológica de uma Infraestrutura de Dados Espaciais (IDE), definida como:
                  </p>
  
  
                  <blockquote className="blockquote blockquote-footer blockquote-padding">
                    [...] conjunto integrado de tecnologias; políticas; mecanismos e procedimentos de coordenação e monitoramento; padrões e acordos, necessário para facilitar e ordenar a geração, o armazenamento, o acesso, o compartilhamento, a disseminação e o uso dos dados geoespaciais de origem federal, estadual, distrital e municipal;
                  </blockquote>
                  <p>
                    A SEFIN já disponibiliza serviços de consulta ao banco de dados espacial do SITFOR para aplicações de outras secretarias. Tome-se como exemplo o webservice SEFIN-SEUMA responsável pela troca de dados necessárias ao serviço de “Consulta de Adequabilidade Online”, a qual “permite que o cidadão de forma segura e rápida verifique se a atividade que pretende realizar é permitida em determinado endereço”. Esse serviço realiza uma consulta pelo número da inscrição imobiliária da SEFIN na base cartográfica do SITFOR e permite que a aplicação da SEUMA responda a solicitação do cidadão, de acordo com normas vigentes e a partir da análise espacial da localização do lote, se a atividade econômica do seu futuro empreendimento se adequa ao local escolhido.
                    <i>
                      <u>
                        O que se observa, entretanto, é que o fornecimento desse serviço não segue os padrões estabelecidos nas boas práticas de troca de dados espaciais. Repita-se, o dado espacial tem nuances mais complexas que um dado alfanumérico
                      </u>
                    </i>, principalmente quanto:
                  </p>
                  <ol >
                    <li>possui uma componente espacial que exige uma referência de sistema de referência de coordenadas (Sistema Geodésico Brasileiro – SGB), com indicação clara de Datum e sistema de projeção (geográfico ou plano),  </li>
                    <li>exige uma representação de suas coordenadas de vértices (do ponto/linha ou polígono), de tal sorte que possa ser renderizada em um mapa; </li>
                    <li>
                      exige manutenção unívoca do identificador único e estável de parcela territorial com a “finalidade de permitir o exercício pacífico do direito de propriedade, proteger e propiciar a segurança jurídica, o mercado imobiliário e os investimentos a ele inerentes” (Ministério da Cidades, Portaria 511/2009).
                      <br />
                      <br />
                      <p>
                        A facilidade de troca de informações entre aplicações, com estribo na localização espacial do objeto de interesse (geralmente o lote) e suas interseções geográficas com as demais entidades cartográficas que definem um regime jurídico diferenciado (v.g. macrozoneamento), é um dos fatores que torna um sucesso o emprego de geotecnologias na ordenação do espaço urbano municipal.
                      </p>
                      <p>
                        Como a SEFIN pode ser uma boa provedora de serviços espaciais? Como pode se estruturar tecnologicamente para um cenário crescente de solicitações de informações sob seu manto de competência institucional?
                      </p>
                      <p>
                        Como bem está assente no manual de ESPECIFICAÇÕES TÉCNICAS PARA ESTRUTURAÇÃO DE DADOS GEOESPACIAIS VETORIAIS (Versão 3.0 – Dezembro 2017) da Comissão Nacional de Cartografia (CONCAR), entidade vinculada ao Ministério do Planejamento, Desenvolvimento e Gestão:
                      </p>
                      <blockquote className="blockquote blockquote-footer blockquote-padding">
                        A demanda por informação geoespacial na sociedade atual tem crescido exponencialmente. Com a multiplicidade de geotecnologias existentes no mercado, a produção de dados geoespaciais e sua distribuição tornam-se mais ágeis a cada dia. No entanto, para isso os dados necessitam ser gerados segundo padrões e especificações técnicas que garantam o compartilhamento, a interoperabilidade e a disseminação destes dados, sendo estes aspectos fundamentais em uma Infraestrutura de Dados Espaciais (IDE).
                      </blockquote>
                      No vídeo institucional de apresentação da INDE está assente as justificativas bases para a criação e uma implantação de uma IDE, tal qual vislumbramos no atual cenário da SEFIN frente às demandas crescentes de pedidos a que esse órgão se submete:
                      <blockquote className="blockquote blockquote-footer blockquote-padding">
                        A informação geoespacial está distribuída entre várias instituições públicas e privadas. As informações em geral se encontram de forma isolada, em diferentes formatos, e padrões, nem sempre organizadas, em sistemas que não conversam entre si e servindo apenas aos objetivos daquele órgão que o produziu/adquiriu. Para encontrar determinada informação, muitas vezes é necessária uma ou mais fontes de dados e submetê-los a processos de integração complexos e demorados. Além disso, se investe repetidamente para produzir informações já existentes ou já em processo de produção.
                      </blockquote>
                      Outro ponto que merece destaque é o Sistema Nacional de Gestão de Informações Territoriais – Sinter, instituído pelo Decreto Federal 8.764, de 10 de maio de 2016, sob gestão da Secretaria da Receita Federal, cujo objetivo é criar uma
                      <blockquote className="blockquote blockquote-footer blockquote-padding">
                        “[...] ferramenta de gestão pública que integrará, em um banco de dados espaciais, o fluxo dinâmico de dados jurídicos produzidos pelos serviços de registros públicos ao fluxo de dados fiscais, cadastrais e geoespaciais de imóveis urbanos e rurais produzidos pela União, pelos Estados, pelo Distrito Federal e pelos Municípios. (Grifou-se)
                        Como o Sinter é projeto colaborativo e deverá obedecer aos padrões da CONCAR, futuras integrações com os cartórios de registros de imóveis do nosso Município serão muito mais factíveis. Despicienda nominar as vantagens de tal integração cartoral para a tributação imobiliária...
                      </blockquote>
                    </li>
  
                  </ol>
  
                  <h4>REFERÊNCIAS</h4>
                  <ul>
                    <li>
                      MANUAL DE ESPECIFICAÇÕES TÉCNICAS PARA ESTRUTURAÇÃO DE DADOS GEOESPACIAIS VETORIAIS (Versão 3.0 – Dezembro 2017) da Comissão Nacional de Cartografia (CONCAR);
                    </li>
                    <li>Sistema Nacional de Gestão de Informações Territoriais – Sinter, instituído pelo Decreto Federal 8.764, de 10 de maio de 2016;</li>
                    <li>Código Tributário Municipal de Fortaleza, LEI COMPLEMENTAR Nº 159 DE 23 DE DEZEMBRO DE 2013;</li>
                    <li>DECRETO Nº 6.666, DE 27 DE NOVEMBRO DE 2008. Institui, no âmbito do Poder Executivo federal, a Infra-Estrutura Nacional de Dados Espaciais (INDE).</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* <div className='container-fluid'>
            <Footer />
          </div> */}
        </>
      );

    }else{
      window.location.href = '/'; // Redirect to the home page
      return null;
    }
  }
}

export default Sobre;