import React from "react";

import VideoListCard from "./VideoListCard";

const VideoList = ({ palestrantes, escolheVideo }) => {
  const listaPalestrantes = palestrantes.map((palestrante, idx) => {
    return (
      <VideoListCard
        key={idx}
        palestrante={palestrante}
        escolheVideo={escolheVideo}
      />
    );
  });

  return <div className="list list-group video-list">{listaPalestrantes}</div>;
};

export default VideoList;
