import React from 'react';
import GridInfo from './GridInfo';
import SideButton from './SideButton'
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Modal from './Modal';
import AdicionarCamadaListMenu from './ListMenu/AdicionarCamada/AdicionarCamadaListMenu'
import TrocarMapaBaseListMenu from './ListMenu/TrocarMapaBaseListMenu'
import Legend from '../map/legends';
import  './sidenav.css';
import { connect } from 'react-redux';
import {showGridInfo, hideGridinfo, toggleGridinfo} from '../../actions/featureActions';
import {setBtnInfoActive, setBtnMenuLayerActive, setBtnInfoDesactive} from '../../actions/sidenavBtnActions';
import { bindActionCreators } from 'redux';

class SideNav extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      displayModal : false,
      modalTitle: '',
      tabTitle: '',
      displayLegendArea: false,
      search: false
    }
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal(modalTitle, tabTitle, listMenu, search) {
    if (listMenu==='adicionarCamadas'){
      this.props.setBtnMenuLayerActive()
    } else if (listMenu==='gridinfo') {
      this.props.setBtnInfoActive()
    }
    this.setState(
      {
        displayModal : true,
        modalTitle: modalTitle,
        tabTitle: tabTitle,
        listMenu: listMenu,
        search : search
      }
    );
  }

  closeModal() {
    this.props.setBtnInfoDesactive()
    this.setState(
      {
        displayModal : false,
        modalTitle: '',
        tabTitle: null,
        listMenu: '',
        search : false
      }
    );
  }
  toggleLegendArea = () => {
    this.setState(
      {displayLegendArea: !this.state.displayLegendArea}
    );
  }

  handleSetLayerBase = (layer) => {
    this.setState(
      {layerBase: layer }
    );
  }

  popover = (name) => {
    return (
      <Popover>
        <Popover.Content>
          <p style={{marginBottom:'0px'}}>{name}</p>
        </Popover.Content>
      </Popover>
    )
  }

  render() {
    let listMenu;
    if (this.state.listMenu === 'adicionarCamadas') {
      listMenu = <AdicionarCamadaListMenu handleLogin={this.props.handleLogin}/>
    } else if (this.state.listMenu === 'gridinfo') {
      listMenu = <GridInfo/>
    } else if (this.state.listMenu === 'trocarMapaBase') {
      listMenu = <TrocarMapaBaseListMenu handleLogin={this.props.handleLogin} handleSetLayerBase={this.handleSetLayerBase} layerBase={this.state.layerBase}/>
    }

    return (
      <div>
        <div className='side-buttons float-right' style={{marginTop: '3.5rem'}}>
          <OverlayTrigger placement='left' overlay={this.popover('Adicionar camadas')} >
            <div>
              <SideButton
                idButton="" onClick={this.openModal}
                onClickParams = {{ modalTitle: 'Adicionar camadas', tabTitle: 'Camadas Disponíveis', listMenu: 'adicionarCamadas', search : true }}
                icon='layer-group' dataContentId={'card-content-adicionar-camadas'}
                btnClasses= {`${this.state.listMenu === 'adicionarCamadas' && 'btn-active'}`}
              />
            </div>
          </OverlayTrigger>
          <OverlayTrigger placement='left'overlay={this.popover('Trocar mapa base')} >
            <div>
              <SideButton
                idButton="btn-basemap" onClick = {this.openModal}
                onClickParams = {{ modalTitle: 'Trocar mapa base', tabTitle: null, listMenu: 'trocarMapaBase', search : false }}
                icon='map' dataContentId = {'card-content-mapa-base'}
                btnClasses={`${this.state.listMenu === 'trocarMapaBase' && 'btn-active'}`}
              />
            </div>
          </OverlayTrigger>
          <OverlayTrigger placement='left' overlay={this.popover('Legenda')} >
            <button type='button'
              className={`btn btn-sm  btn-circle  btn-primary btn-pmf shadow ${this.state.displayLegendArea && 'btn-active'}`}
              onClick={() => this.toggleLegendArea()}>
              <div className='oi oi-map oi-map-custom'/>
            </button>
          </OverlayTrigger>
          <OverlayTrigger placement='left' overlay={this.popover('Informações das Camadas')} >
            <div>
              <SideButton
                idButton="btn-gridinfo" onClick = {this.openModal}
                onClickParams = {{ modalTitle: 'Informações da seleção', tabTitle: null, listMenu: 'gridinfo', search : false }}
                icon='info-circle' dataContentId = {'gridContentSelected'}
                btnClasses= {`${this.state.listMenu === 'gridinfo' && 'btn-active'}`}
                title={'Informações das Camadas'}
              />
            </div>
          </OverlayTrigger>
        </div>
        <Modal displayModal = {this.state.displayModal} closeModal = { this.closeModal } modalTitle = { this.state.modalTitle } tabTitle = {this.state.tabTitle} search={this.state.search}>
          {listMenu}
        </Modal>
        <Legend toggleLegendArea={this.toggleLegendArea} displayLegendArea={this.state.displayLegendArea}/>
      </div>
    );
  }
}
const mapStateToProps = (store, ownProps) => {
  return {displayGridInfo: store.FeatureInfoState.displayGridInfo, btnInfoActive: store.sidenavBtnState.btnInfo}
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ showGridInfo, hideGridinfo, toggleGridinfo, setBtnInfoActive, setBtnInfoDesactive, setBtnMenuLayerActive}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SideNav);
// export default SideNav;