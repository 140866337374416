export const loadMetadataGeoNetwork = (params) => {
  
  const url = `${process.env.REACT_APP_GEONETWORK_URL}/eng/csw?SERVICE=CSW&VERSION=2.0.2&REQUEST=GetRecords&resultType=results&ElementSetName=full&maxRecords=100`;
  const url_metadata = process.env.REACT_APP_GEONETWORK_URL
  fetch(url)
  .then((response) => {return response.text()})
  .then((xml) => {
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(xml,"text/xml");
    let data = [];

    const results = xmlDoc.getElementsByTagName('csw:Record');

    for (let item of results) {
      let description = item.getElementsByTagName('dc:description')[0].textContent
      description     =  description.length > 250 ? `${description.slice(0,250)}...` : description
      let title       =  item.getElementsByTagName('dc:title')[0].textContent
      let identifier  =  item.getElementsByTagName('dc:identifier')[0].textContent
      let thumbnail   =  null
      if (item.querySelectorAll("[name='image/png']").length > 0) {
        thumbnail   =  item.querySelectorAll("[name='image/png']")[0].textContent
      }
      let source = null;
      if (item.getElementsByTagName('dc:source').length > 0) {
        source = item.getElementsByTagName('dc:source')[0].textContent
      }
      let url         =  `${url_metadata}/por/catalog.search?node=srv#/metadata/${identifier}`

      data.push(
        {
          title: title,
          description: description,
          thumbnail: thumbnail,
          source: source,
          url: url,
          identifier: identifier
        }
      )
    }
    params.props.setMetadataLayers(data)
  })
}