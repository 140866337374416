const whiteList = {
  "IBGE": true,
  "IPTU": true,
  "ISSQN": true,
  "ITBI": true,
  "OUV": true,
  "PGV": true,
  "RMF": true,
  "CAGECE": true,
  "TMRSU": true,
}

export function camelCase(str) { 
  str = str.toLowerCase().replace('_',' ')
  return str
    .replace(/\s(.)/g, function(a) { 
        return a.toUpperCase(); 
    })
    .replace(/\s/g, ' ') 
    .replace(/^(.)/, function(b) { 
        return b.toLowerCase(); 
    });
} 


export function capitalize(str) { 
  if (whiteList[str]) {
    return str
  }
  try {
    return str
      .toLowerCase().replaceAll('_',' ')
      .replace(/\s(.)/g, function(a) { 
        return a.toUpperCase(); 
      }).replace(/^(.)/, function(b) { 
        return b.toUpperCase(); 
      }) 
  } catch (error) {
    console.error(error)
    return str;
  }
} 

export const capitalizeFirstLetter = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

