import React from 'react';
import { connect } from 'react-redux';

class Legend extends React.Component {
  state = { src: '' };
  componentDidMount() {
    const {bbox, layerSource} =  this.props;
    const props = this.props

    const layer = this.props.layers.getArray().filter((layer)=> {return layer.get('layerSource') === this.props.layerSource })[0]

    const username = window.sessionStorage.getItem('username')
    const password = window.sessionStorage.getItem('password')
    const legendOptions = 'LEGEND_OPTIONS=forceLabels:on;fontAntiAliasing:true;countMatched:true;fontName:sans;hideEmptyRules:true;forceTitles:off'
    let url;
    let currentStyle = null;
    try{
      currentStyle = layer.get('currentStyle')
    } catch(error) {
    }
    let credentials;
    if (username && password) 
      credentials = `Basic ${btoa(`${username}:${password}`)}`;
    if (layer && this.props.currentGeoserverLayer) {
      if(currentStyle) {
        url = `${this.props.currentGeoserverLayer.legendUrl}&style=${currentStyle}&bbox=${bbox}&${legendOptions}`;
      } else {
        url = `${this.props.currentGeoserverLayer.legendUrl}&bbox=${bbox}&${legendOptions}`;
      }
    }
  
    // const url = `${process.env.REACT_APP_GEOSERVER_URL}/wms?REQUEST=GetLegendGraphic&VERSION=1.1.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&STYLE=${layer[2]}&LAYER=${layer[0]}&LEGEND_OPTIONS=forceLabels:on;fontAntiAliasing:true;countMatched:true;fontName:sans;hideEmptyRules:true;forceTitles:off&BBOX=${bbox.toString()}`;
    
    new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.open('GET', url);
      xhr.setRequestHeader('Authorization', credentials);
      xhr.onload = function() {
        if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
          const imageSRC = URL.createObjectURL(xhr.response);
          resolve(imageSRC)
        }
      }
      xhr.send();
    }).then(imageSRC => {
      this.setState({src: imageSRC});
      
    })

  }


  render () {
    const {layerSource} = this.props;
    if (!this.props.currentGeoserverLayer) {
      return (<></>)
    }
    return (
      <div className='legend-div'>
        <div className="popover-title">Legenda:</div>
        <img
          id ={`legend-popover-${layerSource.replace(':','_')}`}
          className='legend-image img-fluid'
          src={this.state.src}
          alt="Legenda"
          onLoad={this.props.onLoad}
        ></img>
      </div>
    )
  }
}


const mapStateToProps = (store, ownProps) => {
  return {
    layers: store.layersState.layers,
    last_updated: store.layersState.last_updated,
    bbox: store.layersState.bbox
  }
}

export default connect(mapStateToProps, null)(Legend);