import React,{ createRef } from "react";
import { connect } from "react-redux";
import { toggleVisible, updateLayers } from "../../actions";
import { bindActionCreators } from "redux";
import OptionsPopover from "./Popover/OptionsPopover";
import imgWMS from "./Popover/wms.png";
import imgWFS from "./Popover/wfs.png";
import imgWMTS from "./Popover/wmts.png";
import imgChoropleth from "./Popover/choropleth.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDOM from "react-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import AuthenticatedLink from "../AuthenticatedLink";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import CSV from '../../components/home/DownloadsSvgs/csv.svg'
import KML from '../../components/home/DownloadsSvgs/kml.svg'
import SHP from '../../components/home/DownloadsSvgs/shp.svg'
import JSON from '../../components/home/DownloadsSvgs/json.svg'
import ZIP from '../../components/home/DownloadsSvgs/zip.svg'
import Swal from "sweetalert2";
import './ListItem.css'

class ListItem extends React.Component {
  isChecked() {
    let layerSource = this.props.layerSource;
    let checked = false;
    try {
      this.props.layers.forEach(function (l) {
        if (l.get("layerSource") === layerSource) {
          checked = l.getVisible();
        }
      });
    } catch {}
    return checked;
  }

  buttonWFS() {
    if (["Territoriais", "Condominios"].includes(this.props.layerName)) {
      return (
        <button
          type="button"
          className="btn btn-link btn-options"
          onClick={() =>
            this.props.toggleVisible(`${this.props.layerSource} WFS`)
          }
          data-placement="top"
        >
          <span className="oi oi-inbox"></span>
        </button>
      );
    }
  }

  popover() {
    if (this.props.hasPopover) {
      return (
        <OptionsPopover
          parentName={this.props.groupName}
          layerSource={this.props.layerSource}
          layerName={this.props.layerName}
        />
      );
    } else {
      return "";
    }
  }

  async DonwloadStarted(event) {
  
    const username = window.sessionStorage.getItem("username");
    const password = window.sessionStorage.getItem("password");
    let headers = {};
    if (username && password) {
      headers = {
        Authorization: `Basic ${btoa(`${username}:${password}`)}`,
      };
    }
  
    Swal.fire({
      icon: 'success',
      title: 'Baixando',
      text: 'Seu download começará em breve',
    });
  
    const url = event.currentTarget.href; // Get the download link from the clicked element
  
    const result = await fetch(url, {
      headers: new Headers(headers)
    });
  
    const blob = await result.blob();
    const href = window.URL.createObjectURL(blob);
  
    // Create a hidden anchor element
    const downloadLink = document.createElement('a');
    downloadLink.download = `${this.props.layerName}.zip`; // Set the desired file name
    downloadLink.href = href;
  
    document.body.appendChild(downloadLink);
    downloadLink.click();
  
    // Clean up the download link element
    document.body.removeChild(downloadLink);
  }
  
  
  uppercaseFirstHalf(inputString) {
    // Split the input string by ':'
    const parts = inputString.split(':');
  
    // Check if there is at least one ':' in the string
    if (parts.length > 1) {
      // Get the first part and convert it to uppercase
      const firstHalf = parts[0].toUpperCase();
  
      // Join the first half with the second half using ':'
      const result = [firstHalf, ...parts.slice(1)].join(':');
      return result;
    } else {
      // If there is no ':', return the input string as is
      return inputString;
    }
  }
  

  moveUpButton() {
    if (this.props.index >= 0) {
      if (this.props.index === 0) {
        return (
          <a className="move move-disabled" disabled>
            <FontAwesomeIcon icon="caret-square-up" />
          </a>
        );
      } else {
        return (
          <a className="move" onClick={this.moveUpListItem}>
            <FontAwesomeIcon icon="caret-square-up" />
          </a>
        );
      }
    } else {
      return "";
    }
  }

  moveUpListItem = () => {
    let lowerListItem = ReactDOM.findDOMNode(this);
    let upperListItem = lowerListItem.previousElementSibling;
    upperListItem.classList.add("transition-down");
    lowerListItem.classList.add("transition-up");
    setTimeout(() => {
      upperListItem.classList.remove("transition-down");
      lowerListItem.classList.remove("transition-up");
      this.props.moveUp(this.props.index);
    }, 100);
  };

  moveDownButton() {
    if (this.props.index >= 0) {
      if (this.props.index === this.props.activeLayers.length - 1) {
        return (
          <a className="move move-disabled" disabled>
            <FontAwesomeIcon icon="caret-square-down" />
          </a>
        );
      } else {
        return (
          <a className="move" onClick={this.moveDownListItem}>
            <FontAwesomeIcon icon="caret-square-down" />
          </a>
        );
      }
    } else {
      return "";
    }
  }

  moveDownListItem = () => {
    let upperListItem = ReactDOM.findDOMNode(this);
    let lowerListItem = upperListItem.nextElementSibling;
    upperListItem.classList.add("transition-down");
    lowerListItem.classList.add("transition-up");
    setTimeout(() => {
      upperListItem.classList.remove("transition-down");
      lowerListItem.classList.remove("transition-up");
      this.props.moveDown(this.props.index);
    }, 100);
  };

  onChange = () => {
    this.props.toggleVisible(this.props.layerSource);
    this.props.updateActiveLayers(this.props.layerSource);
  };
  callbackonCopy = (tooltipid) => {
    document.getElementById(tooltipid).innerHTML = "Copiado";
    setTimeout(function () {
      document.getElementById(tooltipid).innerHTML = "Copiar";
    }, 2000);
  };

  render() {
    const { requestType } = this.props;
    let imgchoropleth;
    let showCheckbox;
    let showDownloads;
    let showLinkGeoservicos;
    let textAbstractMetadata;
    let layerIcon;
    const workspace = this.props.layerSource.split(":")[0];
    const layer = this.props.layerSource.split(":")[1];

    if (this.props.showAbstractInfo) {
      textAbstractMetadata = (
        <div className="text-muted">{this.props.textAbstractMetadata}</div>
      );
    }

    if(requestType == "WFS"){
     layerIcon = (<img
            src={imgWFS}
            className="icon-flag-layers"
            title={`Camada ${requestType}`}
            alt={`Camada ${requestType}`}
          />)
    }
    else{
      layerIcon = (<img
            src={imgWMS}
            className="icon-flag-layers"
            title={`Camada ${requestType}`}
            alt={`Camada ${requestType}`}
          />)
    }

    if (this.props.choropleth) {
      imgchoropleth = (
        <img
          src={imgChoropleth}
          className="icon-flag-layers"
          title="Camada Coroplética"
          alt="Camada Coroplética"
        />
      );
    }
    if (this.props.showCheckbox) {
      showCheckbox = (
        <>
          <input
            type="checkbox"
            id={this.props.idName}
            className={`layer-checkbox`}
            checked={this.isChecked()}
            onChange={this.onChange}
            data-layerclass={this.props.layerClass}
          />
          <span className="checkmark"></span>
          {layerIcon}
          {imgchoropleth}
        </>
      );
    }

    if (this.props.showLinkGeoservicos) {
      const urlWMS = `${process.env.REACT_APP_GEOSERVER_URL}/${workspace}/${layer}/wms?request=GetCapabilities`;
      const urlWFS = `${process.env.REACT_APP_GEOSERVER_URL}/${workspace}/${layer}/wfs?request=GetCapabilities`;
      const urlCSW = `${process.env.REACT_APP_GEONETWORK_URL}/${workspace}/${layer}/eng/csw?request=GetCapabilities`;
      const textWMS = `${process.env.REACT_APP_GEOSERVER_URL}/${workspace}/${layer}/wms`;
      const textWFS = `${process.env.REACT_APP_GEOSERVER_URL}/${workspace}/${layer}/wfs`;
      const textCSW = `${process.env.REACT_APP_GEONETWORK_URL}/${workspace}/${layer}/eng/csw`;
      let showWFS = !this.props.layerName.match("Orto");
      let showWMS = <></>;
      if (showWFS) {
        showWFS = (
          <>
            <br />
            <CopyToClipboard
              onCopy={() =>
                this.callbackonCopy(`tooltip-wfs-${this.props.idName}`)
              }
              text={urlWFS}
            >
              <button className="btn btn-link">
                <div className="tooltip-copy">
                  <span
                    className="tooltiptext"
                    id={`tooltip-wfs-${this.props.idName}`}
                  >
                    Copiar
                  </span>
                  <img
                    src={imgWFS}
                    className=""
                    value={urlWFS}
                    alt={`Camada ${requestType}`}
                    data-targetid={`link-wfs-${this.props.idName}`}
                    data-tooltipid={`tooltip-wfs-${this.props.idName}`}
                  />
                </div>
              </button>
            </CopyToClipboard>
            <a href={urlWFS} target="_blank">
              {textWFS}
            </a>
          </>
        );
      }

      if(!this.props.keywordList.includes('SKIP_WMS')){
        showWMS = (
          <a href={urlWMS} style={{marginInlineStart: '10px'}}>
              <img src={imgWMS} />
            </a>
        )
      }
      // if(!this.props.layerSource.match("IMAGEAMENTO")){
        showLinkGeoservicos = (
          <div className="row justify-content-start">
            {(() => {
              if (!this.props.keywordList.includes('SKIP_WFS')) {
                return this.props.keywordList.includes('USE_DATAURL_LINK') ? <></> :
                  <a href={urlWFS} style={{ marginInlineEnd: '10px' }}><img src={imgWFS} /></a>;
              } else {
                return null; // or any fallback content you want to render if SKIP_WFS is present
              }
            })()}
            {/* <a href={urlWFS} style={{marginInlineEnd: '10px'}}><img src={imgWFS} /></a> */}
            {showWMS}
            {/* <br />
            <CopyToClipboard
              onCopy={() =>
                this.callbackonCopy(`tooltip-wms-${this.props.idName}`)
              }
              text={urlWMS}
            >
              <button className="btn btn-link">
                <div className="tooltip-copy">
                  <span
                    className="tooltiptext"
                    id={`tooltip-wms-${this.props.idName}`}
                  >
                    Copiar
                  </span>
                  <img
                    src={imgWMS}
                    className=""
                    value={urlWMS}
                    alt={`Camada ${requestType}`}
                    data-targetid={`link-wms-${this.props.idName}`}
                    data-tooltipid={`tooltip-wfs-${this.props.idName}`}
                  />
                </div>
              </button>
            </CopyToClipboard>
            <a href={urlWMS} target="_blank">
              {textWMS}
            </a>
            {showWFS} */}
          </div>
        );
      // }
    }
    if (this.props.showDownloads) {
      const urlDownload = `${process.env.REACT_APP_GEOSERVER_URL}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${this.props.layerSource}&outputFormat=`;
      if(this.props.keywordList.includes('USE_DATAURL_LINK') && this.props.dataLink.length >= 2){
        // urlDownload = this.props.dataLink
        showDownloads = (
          <div className="row mt-3">
            {(() => {
              const downloadLinks = [];
              for (let i = 0; i < this.props.dataLink.length; i++) {
                downloadLinks.push(
                  <div className="col-sm-3" style={{ color: "#10949E" }} key={i}>
                    <a href={`${this.props.dataLink[i]}`} download={`${this.props.layerName}${i}`} onClick={(e) => this.DonwloadStarted(e)}>
                      <span className="fa-stack fa-2x">
                        <FontAwesomeIcon icon={faCircle} className="mt-2 fa-stack-2x" style={{ width: '50px', height: '50px' }} />
                        <img src={ZIP} style={{ marginTop: '18px', width: '30px', color: 'white', marginInlineStart: '25px' }} className="fa-stack-2x" />
                      </span>
                      <p className="font-weight-bold" style={{ marginInlineStart: '25px' }}>{`00${i+1}`}</p>
                    </a>
                  </div>
                );
              }
              return downloadLinks;
            })()}
          </div>
        );
        
      }
      else if(this.props.keywordList.includes('USE_DATAURL_LINK') && this.props.dataLink.length == 1){
        showDownloads = (
          <div className="row mt-3 ">
            <div className="col-sm-3" style={{color: "#10949E"}}>
              <a href={`${this.props.dataLink[0]}`} download={`${this.props.dataLink.slice(-3)}`} onClick={(e) => this.DonwloadStarted(e)}>
                <span className="fa-stack fa-2x " >
                  <FontAwesomeIcon icon={faCircle} className='mt-2 fa-stack-2x' style={{width: '50px', height: '50px'}}/>
                  <img src={ZIP} style={{marginTop: '18px', width: '30px', color: 'white', marginInlineStart: '25px'}} className='fa-stack-2x'/>
                </span>
                <p className="font-weight-bold " style={{marginInlineStart: '25px'}}>ZIP</p>
              </a>
            </div>
            </div>
        )
      }
      else{
        showDownloads = (
          <div className="row mt-3 ">
            <div className="col-sm-3" style={{color: "#10949E"}}>
              <AuthenticatedLink url={`${urlDownload}csv`} filename={`${this.props.layerName}.csv`}>
                <span className="fa-stack fa-2x " >
                  <FontAwesomeIcon icon={faCircle} className='mt-2 fa-stack-2x' style={{width: '50px', height: '50px'}}/>
                  <img src={CSV} style={{marginTop: '18px', width: '30px', color: 'white', marginInlineStart: '25px'}} className='fa-stack-2x'/>
                </span>
                <p className="font-weight-bold " style={{marginInlineStart: '20px'}}>CSV</p>
              </AuthenticatedLink>
            </div>
            <div className="col-sm-3" style={{color: "#10949E"}}>
              <AuthenticatedLink url={`${process.env.REACT_APP_GEOSERVER_URL}/wms/kml?layers=${this.uppercaseFirstHalf(this.props.layerSource)}`}
                      filename={`${this.props.layerName}.application/vnd.google-earth.kml`}>
                <span className="fa-stack fa-2x" >
                  <FontAwesomeIcon icon={faCircle} className='mt-2 fa-stack-2x' style={{width: '50px', height: '50px'}}/>
                  <img src={KML} style={{marginTop: '18px', width: '30px', color: 'white', marginInlineStart: '25px'}} className='fa-stack-2x'/>
                </span>
                <p className="font-weight-bold " style={{marginInlineStart: '20px'}}>KML</p>
              </AuthenticatedLink>
            </div>
            <div className="col-sm-3" style={{color: "#10949E"}}>
              <AuthenticatedLink url={`${urlDownload}SHAPE-ZIP`}
                      filename={`${this.props.layerName}.zip`}>
                <span className="fa-stack fa-2x" >
                  <FontAwesomeIcon icon={faCircle} className='mt-2 fa-stack-2x' style={{width: '50px', height: '50px'}}/>
                  <img src={SHP} style={{marginTop: '18px', width: '30px', color: 'white', marginInlineStart: '25px'}} className='fa-stack-2x'/>
                </span>
                <p className="font-weight-bold " style={{marginInlineStart: '20px'}}>SHP</p>
              </AuthenticatedLink>
            </div>
            <div className="col-sm-3" style={{color: "#10949E"}}>
              <AuthenticatedLink url={`${urlDownload}json`}
                      filename={`${this.props.layerName}.json`}>
                <span className="fa-stack fa-2x" >
                  <FontAwesomeIcon icon={faCircle} className='mt-2 fa-stack-2x' style={{width: '50px', height: '50px'}}/>
                  <img src={JSON} style={{marginTop: '18px', width: '30px', color: 'white', marginInlineStart: '25px'}} className='fa-stack-2x'/>
                </span>
                <p className="font-weight-bold " style={{marginInlineStart: '20px'}}>JSON</p>
              </AuthenticatedLink>
            </div>
            {/* <table
              className={`table download-table ${
                "download-table-home-screen"
              }`}
            >
              <thead>
                <tr>
                  <th width="25%" scope="col">
                    CSV
                  </th>
                  <th width="25%" scope="col">
                    SHP
                  </th>
                  <th width="25%" scope="col">
                    JSON
                  </th>
                  <th width="25%" scope="col">
                    KML
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="align-middle">
                    <AuthenticatedLink
                      url={`${urlDownload}csv`}
                      filename={`${this.props.layerName}.csv`}
                    >
                      <FontAwesomeIcon icon="download" />
                    </AuthenticatedLink>
                  </td>
                  <td className="align-middle">
                    <AuthenticatedLink
                      url={`${urlDownload}SHAPE-ZIP`}
                      filename={`${this.props.layerName}.zip`}
                    >
                      <FontAwesomeIcon icon="download" />
                    </AuthenticatedLink>
                  </td>
                  <td className="align-middle">
                    <AuthenticatedLink
                      url={`${urlDownload}json`}
                      filename={`${this.props.layerName}.json`}
                    >
                      <FontAwesomeIcon icon="download" />
                    </AuthenticatedLink>
  
                  </td>
                  <td className="align-middle">
                    <AuthenticatedLink
                      url={`${urlDownload}application/vnd.google-earth.kml+xml`}
                      filename={`${this.props.layerName}.application/vnd.google-earth.kml`}
                    >
                      <FontAwesomeIcon icon="download" />
                    </AuthenticatedLink>
  
                  </td>
                </tr>
              </tbody>
            </table> */}
          </div>
        );
      }
    }

    if (this.props.showDownloads){

        return(
          <li className="row">
              <label className="col-sm-12 col-md-8 mt-5">
                <div className="d-flex align-items-center">
                  {<h2 style={{ fontSize: '20px', marginRight: '10px', marginBottom: 0 }}>{this.props.layerName}</h2> }
                </div>
                  {<div><hr style={{ backgroundColor: "#10949E", flex: '1', margin: 0, height: '1px' }} /><div style={{fontSize: '14px'}}>{textAbstractMetadata}</div> </div>}
              </label>
  
  
            <div className="col-sm-12 col-md-4 ">
              {/* {this.props.layerName} */}
              {/* {showCheckbox} */}
              {showDownloads}
              {/* {showLinkGeoservicos} */}
            </div>
          </li>
        )
      
      
    }else if(this.props.showLinkGeoservicos){
      return (
        <li className="list-unstyled inline-list-item li-multilevel">
          <label className="label-item-menu w-100" htmlFor={this.props.idName}>
          <div className="row">
              <div className="col-sm-10">
                <h2 style={{fontSize: '20px', color: 'black'}}>{this.props.layerName}</h2>
                <hr style={{ backgroundColor: "#10949E", flex: '1', margin: 0, height: '1px' }} />
                {textAbstractMetadata}
                {/* {showCheckbox} */}
                {/* {showDownloads} */}
              </div>
              <div className="col-sm-2 mt-4">
                {showLinkGeoservicos}
              </div>
            </div>


          </label>
          <div className="popover-options">
            {this.moveUpButton()}
            {this.moveDownButton()}
            {this.popover()}
          </div>
        </li>
      );
    }else{
      return (
        <li className="list-unstyled inline-list-item ">
          <label className="label-item-menu item-full-width" htmlFor={this.props.idName}>
            <span className="map-box">{this.props.layerName}</span>
            {textAbstractMetadata}
            {showCheckbox}
            {showDownloads}
            {showLinkGeoservicos}
          </label>

          <div className="popover-options">
            {this.moveUpButton()}
            {this.moveDownButton()}
            {this.popover()}
          </div>
        </li>
      );
    }
  }
}

const mapStateToProps = (store, ownProps) => {
  return {
    layers: store.layersState.layers,
    last_updated: store.layersState.last_updated,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ updateLayers, toggleVisible }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ListItem);

// export default ListItem;
