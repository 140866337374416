import {SET_BTN_INFO_ACTIVE, SET_BTN_MENU_LAYER_ACTIVE, GET_BTN_INFO_ACTIVE, SET_BTN_INFO_DESACTIVE} from './actionTypes';


export const setBtnInfoActive = (properties) => ({
  type: SET_BTN_INFO_ACTIVE,
})

export const setBtnInfoDesactive = (properties) => ({
  type: SET_BTN_INFO_DESACTIVE,
})

export const getBtnInfoActive = (properties) => ({
  type: GET_BTN_INFO_ACTIVE,
})

export const setBtnMenuLayerActive = (properties) => ({
  type: SET_BTN_MENU_LAYER_ACTIVE,
})



