import React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import ListMenu from '../ListMenu';
import { connect } from 'react-redux';
import CamadasAtivasListMenu from './CamadasAtivasListMenu';
import CamadasDisponiveisListMenu from './CamadasDisponiveisListMenu';
import {getActiveLayers, moveActiveLayer, orderActiveLayersZIndex} from '../../../../utils/ActiveLayersHandler'

require( "./styles.scss");
class AdicionarCamadaListMenu extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      activeLayers: getActiveLayers(this.props.OlLayers)
    }
  }

  moveActiveLayerUp = (index) => {
    if (index - 1 >= 0) {
      let currentArray = moveActiveLayer(index, -1, this.state.activeLayers)
      this.setState( { activeLayers : currentArray } )
    }
  }

  moveActiveLayerDown = (index) => {
    if (index + 1 <= this.state.activeLayers.length - 1) {
      let currentArray = moveActiveLayer(index, 1, this.state.activeLayers)
      this.setState( { activeLayers : currentArray } )
    }
  }

  updateActiveLayers = (layerSource) => {
    let activeLayers = this.state.activeLayers
    let olLayer = this.getOlLayer(layerSource)
    if (olLayer) {
      if (olLayer.getVisible()) {
        activeLayers.unshift(olLayer);
      } else {
        olLayer.setZIndex(undefined);
        activeLayers.splice(activeLayers.indexOf(olLayer), 1);
      }
      activeLayers = orderActiveLayersZIndex(activeLayers)
      this.setState({ activeLayers : activeLayers });
    }
    
  }

  getOlLayer = (layerSource) => {
    let currentLayer = this.props.OlLayers.array_.find( function(olLayer) {
      return (olLayer.get('layerSource') === layerSource)
    })
    return currentLayer
  }

  render () {
    if (this.props.workspaces.length === 0) {
      return (<ListMenu></ListMenu>);
    }
    return (
      <Tabs defaultActiveKey="camadas-disponiveis" id="uncontrolled-tab-example" >
        <Tab eventKey="camadas-disponiveis" title="Camadas Disponíveis">
          <CamadasDisponiveisListMenu
            handleLogin={this.props.handleLogin}          
            updateActiveLayers={this.updateActiveLayers}
          />
        </Tab>
        <Tab eventKey="camadas-ativas" title="Camadas Ativas">
          <ListMenu>
            <CamadasAtivasListMenu
              handleLogin={this.props.handleLogin}
              activeLayers={this.state.activeLayers}
              layersGeoserver={this.props.layersGeoserver}
              updateActiveLayers={this.updateActiveLayers}
              moveUp={this.moveActiveLayerUp}
              keywordList={this.props.keywordList}
              moveDown={this.moveActiveLayerDown}
              treeMenu={this.props.treeMenu}
              metadataLayers={this.props.metadataLayers}
            />
          </ListMenu>
        </Tab>
      </Tabs>
    )
  };
}

const mapStateToProps = (store, ownProps) => {
  return {
    layersGeoserver: store.layersGeoserverState.layersGeoserver,
    workspaces: store.layersGeoserverState.workspaces,
    OlLayers: store.layersState.layers,
    metadataLayers: store.metadataLayersState.metadata,
    treeMenu: store.layersGeoserverState.treeMenu
  }
}

export default connect(mapStateToProps, null)(AdicionarCamadaListMenu);
// export default AdicionarCamadaListMenu;