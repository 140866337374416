export const UPDATE_MAP = 'UPDATE_MAP';
export const TOGGLE_VISIBLE_LAYER = 'TOGGLE_VISIBLE_LAYER';
export const GET_VISIBLE_LAYER = 'GET_VISIBLE_LAYER';
export const SHOW_GRIDINFO = 'SHOW_GRIDINFO';
export const LEGEND_CARD_VISIBLE = 'LEGEND_CARD_VISIBLE';
export const TOGGLE_GRIDINFO = 'TOGGLE_GRIDINFO';
export const HIDE_LAYER = 'HIDE_LAYER';
export const SHOW_LAYER = 'SHOW_LAYER';
export const HIDE_GRIDINFO = 'HIDE_GRIDINFO';
export const SET_GRIDINFO = 'SET_GRIDINFO';
export const SET_OPACITY = 'SET_OPACITY';
export const SET_LAYERS_GEOSERVER = 'SET_LAYERS_GEOSERVER';
export const SET_LAYERS_GEOSERVER_PUBLIC = 'SET_LAYERS_GEOSERVER_PUBLIC';
export const CONCAT_GRIDINFO = 'CONCAT_GRIDINFO';
export const CLEAR_GRIDINFO = 'CLEAR_GRIDINFO';
export const SET_ACTIVE_BTN = 'SET_ACTIVE_BTN';
export const GET_BTN_INFO_ACTIVE = 'GET_BTN_INFO_ACTIVE';
export const SET_BTN_INFO_ACTIVE = 'SET_BTN_INFO_ACTIVE';
export const SET_BTN_INFO_DESACTIVE = 'SET_BTN_INFO_DESACTIVE';
export const SET_BTN_MENU_LAYER_ACTIVE = 'SET_BTN_MENU_LAYER_ACTIVE';
export const SET_BBOX_MAP = 'SET_BBOX_MAP';
export const SET_STYLE_LAYER = 'SET_STYLE_LAYER';
export const SET_METADATA_LAYERS = 'SET_METADATA_LAYERS';
