import {CONCAT_GRIDINFO, SET_GRIDINFO, SHOW_GRIDINFO, TOGGLE_GRIDINFO, HIDE_GRIDINFO, CLEAR_GRIDINFO} from '../actions/actionTypes';

const initialStateFeatureInfo = {
  displayGridInfo: false,
  contentGridInfoWFS: [],
  contentGridInfoWMS: [],
  contentGridInfoWMTS: []
}

export const layersFeaturesInfoReducer = (state = initialStateFeatureInfo , action) => {
  let contentGridInfoWMS;
  let contentGridInfoWFS;
  let displayGridInfo;
  let contentGridInfoWMTS;

  switch (action.type) {
    case CONCAT_GRIDINFO:
      if (action.typeRequest === 'wfs') {
        contentGridInfoWFS = state.contentGridInfoWFS
        contentGridInfoWFS = contentGridInfoWFS.concat(action.features)
        contentGridInfoWMS =  state.contentGridInfoWMS
        contentGridInfoWMTS = state.contentGridInfoWMTS
      } else if (action.typeRequest === 'wms'){
        contentGridInfoWMS = state.contentGridInfoWMS
        contentGridInfoWMS = contentGridInfoWMS.concat(action.features)
        contentGridInfoWFS =  state.contentGridInfoWFS
        contentGridInfoWMTS = state.contentGridInfoWMTS
      }else if (action.typeRequest === 'wmts') {
        contentGridInfoWMTS = state.contentGridInfoWMTS
        contentGridInfoWMTS = contentGridInfoWMTS.concat(action.features)
        contentGridInfoWMS = state.contentGridInfoWMS
        contentGridInfoWFS = state.contentGridInfoWFS
      }
      try {
        displayGridInfo = (contentGridInfoWMS.length > 0 || contentGridInfoWFS.length > 0 || contentGridInfoWMTS.length > 0) ? true : false;
      } catch {
        displayGridInfo = false
      }
      return {...state, displayGridInfo: displayGridInfo, contentGridInfoWMS: contentGridInfoWMS, contentGridInfoWFS: contentGridInfoWFS, contentGridInfoWMTS: contentGridInfoWMTS};
    case SET_GRIDINFO:
      if (action.typeRequest === 'wfs') {
        contentGridInfoWFS = action.features
        contentGridInfoWMS =  state.contentGridInfoWMS
        contentGridInfoWMTS = state.contentGridInfoWMTS
      } else if(action.typeRequest === 'wms'){
        contentGridInfoWMS = action.features
        contentGridInfoWFS =  state.contentGridInfoWFS
        contentGridInfoWMTS = state.contentGridInfoWMTS
      }else {
        contentGridInfoWMTS = action.features
        contentGridInfoWFS = state.contentGridInfoWFS
        contentGridInfoWMS = state.contentGridInfoWMS
      }
      try {
        displayGridInfo = (contentGridInfoWMS.length > 0 || contentGridInfoWFS.length > 0 || contentGridInfoWMTS.length > 0) ? true : false;
      } catch {
        displayGridInfo = false
      }
      return {...state, displayGridInfo: displayGridInfo, contentGridInfoWMS: contentGridInfoWMS, contentGridInfoWFS: contentGridInfoWFS, contentGridInfoWMTS: contentGridInfoWMTS};
    case SHOW_GRIDINFO:
      return {...state, displayGridInfo: true};
    case TOGGLE_GRIDINFO:
      return {...state, displayGridInfo: !state.displayGridInfo};
    case HIDE_GRIDINFO:
      return {...state, displayGridInfo: false};
    case CLEAR_GRIDINFO:
      return {...state, displayGridInfo: false, contentGridInfoWFS: [], contentGridInfoWMS: []}
    default:
      return state;
  }
}
