import SourceVector from 'ol/source/Vector';
import LayerVector from 'ol/layer/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import {bbox} from 'ol/loadingstrategy';
import {styleWFS, stylePointWFS} from './OlWFSStyle';

export const vectorSource = (layerName) => {
  let vector_source = new SourceVector({
    format: new GeoJSON({
      defaultDataProjection: 'EPSG:31984'
    }),
    // url: function(extent) {
    //   let link = `https://geoserverhomo.sefin.fortaleza.ce.gov.br/geoserver/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${layerName}&outputFormat=application/json&bbox=${extent.join(',')}`;
    //   return link;
    // },
      loader: function(extent, resolution, projection) {
        // var proj = projection.getCode();
        //var url = `https://geoserverhomo.sefin.fortaleza.ce.gov.br/geoserver/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${layerName}&outputFormat=application/json&bbox=${extent.join(',')},EPSG:31984`;
        var params = {
          "service":"WFS",
          "version":"1.0.0",
          "request":"GetFeature",
          "typeName":layerName,
          "outputFormat":"application/json",
          // "maxFeatures":200,
          "bbox": extent.join(',')
        }
        var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        var url = `${process.env.REACT_APP_GEOSERVER_URL}/ows?${queryString}`;
        var xhr = new XMLHttpRequest();
        const username = window.sessionStorage.getItem('username')
        const password = window.sessionStorage.getItem('password')
        let credentials;
        if (username && password) 
        credentials = `Basic ${btoa(`${username}:${password}`)}`;

        xhr.open('GET', url);
        xhr.setRequestHeader('Authorization', credentials);

        xhr.withCredentials = true
        var onError = function() {
          vector_source.removeLoadedExtent(extent);
        }
        xhr.onerror = onError;
        xhr.onload = function() {
            if (xhr.status === 200) {
              vector_source.clear()
              vector_source.addFeatures(
                vector_source.getFormat().readFeatures(xhr.responseText)
              );
            } else {
                onError();
            }
        }
        xhr.send();


    },
    crossOrigin: 'anonymous',
    strategy: bbox,
  })
  return vector_source;
  
}



export const buildLayerWFS = (props) => {
  
  // ResolutionsResolução por nivel de ZOOM
  const RESOLUTIONS_POR_ZOOM =  {
    11: 76.35146091935201, 
    12: 38.175730459676004,
    13: 19.087865229838002,
    14: 9.543932614919001,
    15: 4.7719663074595005,
    16: 2.3859831537297502,
    17: 1.1929915768648751,
    18: 0.5964957884324376,
    19: 0.2982478942162188,
    20: 0.1491239471081094,
    21: 0.0745619735540547,
    22: 0.03728098677702735,
    23: 0.018640493388513674,
    24: 0.009320246694256837
  }

  const resolutionByZoom = function(zoom) {
    return RESOLUTIONS_POR_ZOOM[zoom]
  }
  // let maxResolution = ['Lotes Fiscais', 'Condomínios', 'Condomínios Idades'].includes(props.layerName) ? resolutionByZoom(17) :  resolutionByZoom(11)
   let maxResolution = resolutionByZoom(11)

  return new LayerVector({
    name: props.layerName,
    visible: props.visible,
    layerSource: props.layerSource,
    crossOrigin: 'anonymous',
    source: vectorSource(props.layerSource),
    maxResolution: maxResolution,
    // style:   function(feature) {
      // teste()
      // if (feature.getGeometry().getType() === 'Point') {
      //   return stylePointWFS()
      // } else {
      //   return styleWFS()
      // }
    // }
  })
}


