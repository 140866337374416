import React from "react";
import Navbar from "../components/home/Navbar";
import Footer from "../components/home/Footer";
import LoginForm from '../components/Login/Form'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Manual1 from './manual_1.jpeg'
import Manual2 from './manual_2.jpeg'
import Manual1PDF from './manual_geoserver.pdf'
import Manual2PDF from './manual_geral_ide_sefin.pdf'
class Tutoriais extends React.Component {


  filterNoticias = () => {
    // Declare variables
    let input, filter, noticias, noticia, i, title;
    input = document.getElementById('filter-noticias');
    filter = input.value.toUpperCase();
    noticias = document.querySelectorAll('.noticia');

    // Loop through all noticias, and hide/show based on search query
    for (i = 0; i < noticias.length; i++) {
      noticia = noticias[i];
      title = noticia.querySelector('.noticia-title');
      if (title) {
        const txtValue = title.textContent || title.innerText;
        if (filter.length > 0 && txtValue.toUpperCase().includes(filter)) {
          // noticia.style.display = 'block';
          title.innerHTML = txtValue.replace(new RegExp(filter, 'gi'), match => `<span class="highlight">${match}</span>`);
        } else if (filter.length > 0 && !txtValue.toUpperCase().includes(filter)) {
          // noticia.style.display = 'block'; // Show all news items when no search input
          title.innerHTML = txtValue;
          title.style.color = 'gray';
        } else if (filter.length == 0) {
          title.innerHTML = txtValue
          title.style.color = "#212529"
        }
      }
    }
  }

  handleLogin = () => {
    this.setState({ last_login: Date.now() })
    // this.props.map.updateSize(); 
    // if (document.getElementById('close-card-pmf')) {
    //   document.getElementById('close-card-pmf').click();
    // }

    // FIXME: Resolve situation when not rerender components after login
    window.location.reload();
  }

  componentDidMount() {
    var checkExist = setInterval(function () {
      if (document.getElementsByClassName('list-group-flush').length > 1) {
        document.getElementsByClassName('list-group-flush')[1].classList.add('active')
        clearInterval(checkExist);
      }
    }, 100);
  }

  render() {
    if (window.localStorage.getItem('aceitoTermoUso')) {
      return (
        <>
          <Navbar handleLogin={this.handleLogin} />
          <div className="container bg-white">
            <LoginForm handleLogin={this.handleLogin} />
            <h1 className="text-center py-2">Manuais</h1>
            <div className='py-5'>
              <div className='container' >
                <div className='mx-auto'>
                  <div className={`form-group form-group-search`}>
                    <input className="form-control form-control-sm" type="text"
                      placeholder="" id="filter-noticias" onChange={() => this.filterNoticias()} />
                    <FontAwesomeIcon icon='search'></FontAwesomeIcon>
                  </div>
                  <div className='row noticia'>
                    <div className='col-md-12 col-lg-4'>
                      <a href={Manual1PDF} target="_blank" download="manual_geoserver.pdf">
                        <img src={Manual1} className='img-fluid' />
                      </a>
                    </div>
                    <div className='col-md-12 col-lg-8'>
                      <a href={Manual1PDF} target="_blank" download="manual_geoserver.pdf" className='' style={{ color: 'black' }}>
                        <h2 className='font-weight-bold noticia-title'>Manual Geoserver</h2>
                      </a>
                      <p>O manual disponibilizado proporciona aos usuários uma compreensão prática da utilização das funcionalidades que possuem associação com os objetivos de acesso e manejo dos dados geoespaciais pelos usuários da IDE-SEFIN por meio do Geoserver.</p>
                      {/* <a href='' className='float-right text-dark'>(Leia Mais)</a> */}
                    </div>
                  </div>
                  <hr style={{ backgroundColor: '#10949E', paddingTop: '2px', paddingBottom: '2px' }} />
                  <div className='row noticia'>
                    <div className='col-md-12 col-lg-4'>
                      <a href={Manual2PDF} target="_blank" download="manual_geral_ide_sefin.pdf">
                        <img src={Manual2} className='img-fluid' />
                      </a>
                    </div>
                    <div className='col-md-12 col-lg-8'>
                      <a href={Manual2PDF} target="_blank" download="manual_geral_ide_sefin.pdf" className='' style={{ color: 'black' }}>
                        <h2 className='font-weight-bold noticia-title'>Manual Geral IDE - SEFIN</h2>
                      </a>
                      <p>Este manual tem como objetivo, apresentar os termos legais, os recursos, as funcionalidades e as ferramentas da plataforma do visualizador da IDE-SEFIN aos seus usuários, por meio de uma série de etapas ilustradas, com linguagem acessível.</p>
                      {/* <a href='' className='float-right text-dark'>(Leia Mais)</a> */}
                    </div>
                  </div>
                  <hr style={{ backgroundColor: '#10949E', paddingTop: '2px', paddingBottom: '2px' }} />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="container-fluid">
            <Footer/>
          </div> */}
        </>
      )

    } else {
      window.location.href = '/'; // Redirect to the home page
      return null;
    }
  }
}

export default Tutoriais