import React from 'react';
import Noticia from '../../pages/Noticia';

class UltimasNoticias extends React.Component {


  render() {
    const noticias = [
      { id: 1, name: 'CHAMAMENTO: queremos conhecer sua pesquisa', slug: 'chamamento-sua-pesquisa', description: 'Utilizou nossos dados para elaborar sua pesquisa ou trabalho? Queremos conhecer seu produto!', date: '19/02/2024', resumo: '“Utilizou nossos dados para elaborar sua pesquisa ou trabalho? Queremos conhecer seu produto!"' },
      { id: 2, name: 'Novas camadas disponíveis!', slug: 'novas-camadas-disponiveis', description: 'Description for Product 1', date: '26/07/2023', resumo: '“Conheça novos mapas altimétricos e atualização de camadas do levantamento aerofotogamétrico de 2016"' },
      { id: 3, name: 'IDE-SEFIN de cara nova!', slug: 'novas-camadas-adicionadas', description: 'Description for Product 1', date: '26/07/2023', resumo: '“A Infraestrutura de Dados Espaciais da SEFIN reinaugura seu site disponibilizando novas camadas, dispondo de facilidades e de novas funcionalidades para os usuários"' }
    ];

    const listUltimasNoticias = noticias.map((noticia) => {
      return <li className='list-item' key={noticia}>
        <a href={`/noticia/${noticia.slug}`} className='text-dark font-weight-bold'>{noticia.name}</a>
        <p style={{ fontSize: 'small' }}>{noticia.resumo}</p>
      </li>;
    });

    // const noticia = noticias.find((noticia) => noticia.slug === slug);

    return (
      <>
        <div className='' >
          <span className='card font-weight-bold text-center mb-2' style={{ backgroundColor: '#4CBABF' }}>ÚLTIMAS NOTÍCIAS</span>

          <div className='card' style={{ backgroundColor: '#E1F2F3' }}>
            <div className='card-body'>
              <ul className='list-group list-group-flush'>
                {listUltimasNoticias}
                {/* <li className='list-item' >
                  <a href='/' className='text-dark'>Teste</a>
                  <p style={{fontSize: 'small'}}>{noticias[1].resumo}</p>
                </li>
                <li className='list-item' >
                  <a href='/' className='text-dark'>Teste</a>
                  <p style={{fontSize: 'small'}}>{noticias[1].resumo}</p>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default UltimasNoticias