import React from "react"
import './Servicos.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDatabase, faBook, faGlobe, faLocationArrow, faCloudDownloadAlt, faGraduationCap, faCircle, faSearchLocation } from '@fortawesome/free-solid-svg-icons'
import MetadadosSVG from './metadados_list.svg'
import DownloadsSVG from './downloads.svg'
import GeorServicosSVG from './georservicos.svg'
import ManuaisSVG from './manuaissvg.svg'
import Visualizador from './visualizador.svg'
import manual_pdf from './Manual_de_utilização_v1.0.pdf'
import Cat_Meta from './cat_metadados.svg'
class Servicos extends React.Component {
  render() {
    return (
      <>
        <div className="row justify-content-center margin-negative margin-auto text-center" style={{ color: '#10949E' }}>
          <div className="col-sm-12 col-md-2 text-center margin-icon">
            <a href={`${process.env.REACT_APP_GEONETWORK_URL}`}>
              <span className="fa-stack fa-3x">
                <FontAwesomeIcon icon={faCircle} className='mt-2 fa-stack-2x' />
                <img src={MetadadosSVG} style={{ marginTop: '30px', width: '55px', color: 'white' }} className='fa-stack-1x text-white' />
                {/* <FontAwesomeIcon  icon={faBook} className='fa-stack-1x text-white' style={{marginTop: '30px'}}/> */}
              </span>
              <p className="mt-2" style={{ fontSize: '16px', fontFamily: 'Palaquin Dark', fontWeight: '400' }}>Metadados</p>
            </a>
          </div>
          <div className="col-sm-12 col-md-2 text-center">
            <a href="geoservicos">
              <span className="fa-stack fa-3x">
                <FontAwesomeIcon icon={faCircle} className='mt-2 fa-stack-2x' />
                <img src={GeorServicosSVG} style={{ marginTop: '30px', width: '55px', color: 'white' }} className='fa-stack-1x text-white' />
                {/* <FontAwesomeIcon style={{marginTop: '30px'}} icon={faGlobe} className=' fa-stack-1x text-white'/> */}
              </span>
              <p className="mt-2" style={{ fontSize: '16px', fontFamily: 'Palaquin Dark', fontWeight: '400' }}>Geoserviços</p>
            </a>
          </div>
          <div className="col-sm-12 col-md-2 text-center">
            <a href="visualizador">
              <span className="fa-stack fa-3x">
                <FontAwesomeIcon icon={faCircle} className='mt-2 fa-stack-2x' />
                <img src={Visualizador} style={{ marginTop: '30px', width: '55px', color: 'white', fontSize: '16px', fontFamily: 'Palaquin Dark', fontWeight: '400' }} className='fa-stack-1x text-white' />
                {/* <FontAwesomeIcon style={{marginTop: '35px'}} icon={faLocationArrow} className='fa-stack-1x text-white'/> */}
              </span>
              <p className="mt-2" style={{ fontSize: '16px', fontFamily: 'Palaquin Dark', fontWeight: '400' }}>Visualizador</p>
            </a>
          </div>
          <div className="col-sm-12 col-md-2 text-center">
            <a href="downloads">
              <span className="fa-stack fa-3x">
                <FontAwesomeIcon icon={faCircle} className='mt-2 fa-stack-2x' />
                <img src={DownloadsSVG} style={{ marginTop: '30px', width: '55px', color: 'white' }} className='fa-stack-1x text-white' />
                {/* <FontAwesomeIcon style={{marginTop: '30px'}} icon={faCloudDownloadAlt} className=' fa-stack-1x text-white'/> */}
              </span>
              <p className="mt-2" style={{ fontSize: '16px', fontFamily: 'Palaquin Dark', fontWeight: '400' }}>Download</p>
            </a>
          </div>
          <div className="col-sm-12 col-md-2 text-center">
            <a href="tutoriais">
              <span className="fa-stack fa-3x">
                <FontAwesomeIcon icon={faCircle} className='mt-2 fa-stack-2x' />
                <img src={ManuaisSVG} style={{ marginTop: '30px', width: '55px', color: 'white' }} className='fa-stack-1x text-white' />
                {/* <FontAwesomeIcon style={{marginTop: '30px'}} icon={faGraduationCap} className='fa-stack-1x text-white'/> */}
              </span>
              <p className="mt-2" style={{ fontSize: '16px', fontFamily: 'Palaquin Dark', fontWeight: '400' }}>Manuais</p>
            </a>
          </div>
        </div>
      </>
    )
  }
}

export default Servicos