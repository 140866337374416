import React from "react";
import Navbar from "../components/home/Navbar";
import Footer from "../components/home/Footer";
import LoginForm from '../components/Login/Form'
class Pesquisa extends React.Component {

  handleLogin = () => {
    this.setState({last_login: Date.now()})
    // this.props.map.updateSize(); 
    // if (document.getElementById('close-card-pmf')) {
    //   document.getElementById('close-card-pmf').click();
    // }

    // FIXME: Resolve situation when not rerender components after login
    window.location.reload();
  }

  render(){
    return(
      <>
        <Navbar handleLogin={this.handleLogin}/>
        <div className="container bg-white">
          <LoginForm handleLogin={this.handleLogin}/>
          <h1 className="text-center py-2">Pesquisa</h1>
        </div>
        {/* <div className="container-fluid">
          <Footer/>
        </div> */}
      </>
    )
  }
}

export default Pesquisa