import React from "react";
import { withRouter } from 'react-router-dom';
import Navbar from "../components/home/Navbar";
import Footer from "../components/home/Footer";
import UltimasNoticias from "../components/home/UltimasNoticias";
import img1_noticia2 from './noticia2_img1.png'
import img2_noticia2 from './noticia2_img2.png'
import img3_noticia2 from './noticia2_img3.png'
import img4_noticia2 from './noticia2_img4.png'
import img5_noticia2 from './noticia2_img5.png'
import img6_noticia2 from './noticia2_img6.png'
import img1_noticia3 from './THUMBNAIL_NOTA_CHAMAMENTO.png'
import LoginForm from '../components/Login/Form'
class Noticia extends React.Component {
  constructor(props) {
    super(props)
  }

  handleLogin = () => {
    this.setState({last_login: Date.now()})
    // this.props.map.updateSize(); 
    // if (document.getElementById('close-card-pmf')) {
    //   document.getElementById('close-card-pmf').click();
    // }

    // FIXME: Resolve situation when not rerender components after login
    window.location.reload();
  }


  render() {
    const { prop1, prop2 } = this.props
    const { slug } = this.props.match.params;


    const noticias = [
      {
        id: 1,
        name: 'IDE-SEFIN de cara nova!',
        slug: 'novas-camadas-adicionadas',
        paragraph1: `
        Para uma experiência mais completa e moderna de acesso aos dados georreferenciados da
cidade de Fortaleza, a Infraestrutura de Dados Espaciais da Secretaria Municipal das Finanças
(IDE-SEFIN) reinaugurou sua plataforma.
        `,
        paragraph2: `
        Criada em 2020, a IDE-SEFIN promove o ordenamento adequado da geração,
armazenamento, acesso, compartilhamento, disseminação e uso dos dados geoespaciais, de origem
da SEFIN e de outras secretarias, e elimina a duplicidade de ações e o desperdício de recursos na
obtenção de dados geoespaciais, em conformidade com o Decreto Federal nº 6.666, de 27 de
novembro de 2008.
        ` ,
        paragraph3: `
        A renovação do design e a interface mais intuitiva e simplificada melhora a eficiência da
navegação pelos múltiplos conjuntos de dados geográficos dispostos na IDE. Com a revitalização, o
site apresenta uma aparência mais moderna e responsiva e introduz novos recursos e facilidades para
os usuários.
        `,
        paragraph4: `
        Um exemplo dessa comodidade é o módulo “Navegue nas Nossas Camadas por Temas”, que
direciona o usuário ao tipo de informação de interesse, auxiliando a busca pelas camadas por
preferência.
        `,
        paragraph5: `
        Outra novidade é a aba de “Notícias”, que será incrementada periodicamente com
atualizações referentes à publicação de novas camadas, divulgação de melhorias, integração com
outras plataformas de acesso, divulgação e uso de informações georreferenciadas, comunicação de
novas capacitações oferecidas em áreas de interesse, dentre várias outras informações relativas às
atualizações recebidas na plataforma.
        `,
        paragraph6: `
        Temos a apresentação da “Camada Destaque”, que deixará em evidência uma camada com
singular relevância, direcionando o usuário ao metadado correspondente à mesma. Estreando esta
funcionalidade, a primeira camada em destaque da nossa plataforma é a do Observatório do Mercado
Imobiliário (OMI), que conta com preços colhidos de ofertas de terrenos no município desde 2015.
Estes dados passaram por “curadoria” das suas informações, por meio de cruzamento com o próprio
cadastro imobiliário da SEFIN, permitindo sua localização geográfica e preenchimento de atributos
faltantes para subsidiar estudos do valor do solo por pesquisadores e avaliadores dos imóveis.
        `,
        paragraph7: `
        O catálogo de metadados também passou por atualização e revisão das suas camadas, que
passaram de 44 para 214.
        `,
        paragraph8: `
        A nova IDE contará ainda com mais atualizações, ao longo dos próximos meses, por exemplo
a publicação de tutoriais de utilização da plataforma e dos servidores e com a disponibilização da FAQ
(Frequently Asked Questions).
        `,
        paragraph9: `
        Com essas adições, a IDE-SEFIN reafirma seu compromisso em utilizar as tecnologias como
um meio de fornecer geoinformações precisas, relevantes e atualizadas do Município para os cidadãos
e consolida-se como uma ferramenta fundamental para o acesso transparente, público e
compartilhado.
        `,
        date: '05/09/2023',
        resumo: '“A Infraestrutura de Dados Espaciais da SEFIN reinaugura seu site disponibilizando novas camadas, dispondo de facilidades e de novas funcionalidades para os usuários'
      },
      {
        id: 2,
        name: 'Novas camadas disponíveis!',
        slug: 'novas-camadas-disponiveis',
        paragraph1: `
        A IDE-SEFIN disponibilizou os Modelos Digitais de Superfície - MDS e de Terreno -
MDT com aplicação de novos estilos nas camadas. Os mapas representam a altimetria de
todos os objetos acima do solo e o relevo do município, respectivamente. Os dados utilizados
na elaboração do mapa são provenientes do levantamento aerofotogramétrico realizado no
município entre dezembro de 2016 e janeiro de 2017.
        `,
        paragraph2: `
        O MDS mede a altura da superfície considerando as estruturas acima do solo, como,
por exemplo, as edificações e vegetação, composto por um mosaico com 1097 articulações
das nuvens de pontos.
        ` ,
        paragraph3: `
        Já o MDT considera apenas o nível do solo, do terreno, excluindo os demais elementos
que compõem a paisagem. As utilizações desses tipos de mapa podem ser variadas, como,
por exemplo, planejamento urbano e índices de verticalização de Fortaleza no MDS e estudos
geomorfológicos e de infraestrutura urbana no MDT. Os dois mapas estão disponíveis para
visualização no menu “Imageamento”.
        `,
        paragraph4: `
        Os mapas de MDT e MDS já estão disponíveis no visualizador, juntamente com suas
respectivas altimetrias determinadas em cada modelo, no menu “Legendas”, assim como o
detalhamento de cada ponto no visualizador, como mostra abaixo, nas imagens 3 e 4. Ambos
os mapas estarão disponíveis em breve para download nos formatos TIF, LAS e pelo
geoserviço WCS, tendo em vista que, para estes, a dinâmica do download será diferente das
demais camadas e para isto será publicado tutorial com os processos específicos necessários
para a realização deste. Contudo, o geoserviço WMS já está disponível no momento desta
publicação.
        `,
        paragraph5: `
        Mais 13 novas camadas da restituição de 2016 também foram publicadas, como, por
exemplo, a restituição das árvores e do sistema viário de Fortaleza. Além disso, todas foram
otimizadas, tornando o acesso e visualização das camadas mais rápidos no visualizador. Um
novo submenu de “Agrupamentos” (imagem 3) também foi incluído no menu
“Aerolevantamento”. Com objetivo de disponibilizar mapas temáticos, o menu foi criado
contendo cinco grupos de camadas possuem dados que se relacionam entre si devido aos
temas nela contidos, como, por exemplo, mangues, rios e canais presentes no mapa de
Recursos Hídricos.
        `,
        paragraph6: `
        Além dos agrupamentos, também foi publicada a restituição completa, com todas as 55
camadas de disponíveis (imagem 4), com simbologias semelhantes ao produto cartográfico
original do aerolevantamento, em formato DWG, que também será disponibilizado em breve.
        `,
        paragraph7: `
        Todas as camadas da restituição já estão disponíveis para download, tanto para
geoserviços em WMS quanto em WFS. Para acessar e baixar as novas camadas
disponibilizadas, utilize nossa plataforma.
        `,
        date: '13/12/2023',
        resumo: '“Conheça novos mapas altimétricos e atualização de camadas do levantamento aerofotogamétrico de 2016'
      },
      {
        id: 3,
        name: 'CHAMAMENTO: queremos conhecer sua pesquisa',
        slug: 'chamamento-sua-pesquisa',
        paragraph1: `
        O Open Data Day (ODD) é um evento que procura celebrar a promoção e utilização de dados abertos em
todo o mundo. É uma proposta de relevância e adesão universal com debates sobre a abertura e o uso de dados
governamentais, por meio de encontros, seminários, workshops e palestras.
        `,
        paragraph2: `
        Nesse contexto, celebrando o Open Data Day a nível municipal, que prioriza e utiliza essencialmente
softwares de código-fonte abertos, a IDE-Sefin prepara seu 1° Seminário IDE-Sefin (1° SIS) com o objetivo de
participar do movimento proposto e divulgar nossa plataforma, inicialmente, para o público interno da Prefeitura
Municipal de Fortaleza.
        ` ,
        paragraph3_a: `
        Para tanto, sabendo da ampla utilização e importância que nossos dados possuem para elaboração de
diversos produtos acadêmicos, trabalhos corporativos e aplicações práticas, estamos realizando a seleção de três
destes produtos para que o/a (s) autor/a (es/s) o apresente no nosso
        `,
        paragraph3_b: `
        1° SIS
        `,
        paragraph3_c: `
        . Para os participantes, serão
disponibilizados certificados de participação no evento e de apresentação de trabalho, assim como será realizada
a entrega dos kits, além da publicação e divulgação do trabalho na nossa plataforma.
        `,
        paragraph4_a: `
        Para enviar seu produto ou trabalho realizado utilizando os dados disponibilizados pela nossa plataforma,
acesse o formulário disponibilizado ao final deste chamamento e anexe, em local indicado e em formato “PDF” seu
        `,
        paragraph4_b: `resumo`,
        paragraph4_c: `,`,
        paragraph4_d: `projeto de pesquisa`,
        paragraph4_e: `(poderá ser apresentado com resultados parciais) ou `,
        paragraph4_f: `abstract`,
        paragraph4_g: `
         (indicado para
          trabalhos acadêmicos já concluídos e deverá conter entre 150 a 500 palavras). A escolha da modalidade fica a
          critério dos autores, desde que possuam os itens obrigatórios posteriormente mencionados.
        `,
        paragraph5: `
        Os envios dos produtos poderão ser feitos a partir da publicação deste chamamento até o dia 27 de
fevereiro de 2024. Os resumos deverão, prioritariamente, seguir as normas de padronização da ABNT. O resumo
deve conter, obrigatoriamente, todos os itens abaixo:
        `,
        paragraph6_a: `
        a) Nome completo do/a (s) autor/a (es/s)
        `,
        paragraph6_b: `b) Tema`,
        paragraph6_c: `c) Breve introdução`,
        paragraph6_d: `d) Objetivos`,
        paragraph6_e: `e) Metodologia implementada`,
        paragraph6_f: `f) Camadas utilizadas que foram obtidas na IDE-Sefin`,
        paragraph6_g: `g) Resultados finais ou parciais obtidos`,
        paragraph6_h: `h) Discussão / conclusão / produto`,
        paragraph7: `
        Os autores poderão ser contatados a qualquer momento para possíveis elucidações. Mais informações
referentes a data do evento, metodologia de apresentação, tempo de exposição e outros detalhes, serão
expendidos após a seleção dos trabalhos, via contatos disponibilizados no ato da inscrição. Os casos omissos
nesta seleção serão analisados e julgados pela Equipe IDE-Sefin.
        `,
        paragraph8: `
        ACESSO AO FORMULÁRIO DE INSCRIÇÃO:
        `,
        paragraph9: `https://forms.gle/8T1BevCyr31Mkaoc9`,
        paragraph10: `Para sanar quaisquer dúvidas, estamos à disposição por meio dos contatos abaixo:`,
        paragraph11: `Telefone: (85) 3105-1240`,
        paragraph12: `E-mail: ide@sefin.fortaleza.ce.gov.br`,
        date: '19/02/2024',
        resumo: 'Utilizou nossos dados para elaborar sua pesquisa ou trabalho? Queremos conhecer seu produto!'
      }
    ];


    const noticia = noticias.find((noticia) => noticia.slug === slug);
    const paragraphWithNumber214 = noticia.paragraph7;

    // Split the paragraph text into parts before and after the number "214"
    const parts = paragraphWithNumber214.split('214');

    // Wrap the number "214" in a <strong> tag to make it bold
    const paragraphWithBoldNumber214 = (
      <>
        {parts[0]}
        <u><strong>214</strong></u>
        {parts[1]}
      </>
    );

    if (window.localStorage.getItem('aceitoTermoUso')) {
      if(noticia.id == 1){
        return (
          <>
            <Navbar handleLogin={this.handleLogin} />
            <LoginForm handleLogin={this.handleLogin} />
            <div className="container pt-2 justify-content-center bg-white border border-top-0 border-bottom-0 border-right border-left " style={{ minHeight: '1000px' }}>
              <div className="row">
                <div className="col-sm-12 col-md-8">
                  <div className="row">
                    <div className="col-12">
                      <legend className="text-muted" style={{ fontSize: 'small' }}>{noticia.date}</legend>
                    </div>
                    <div className="col-12">
                      <h2 className="font-weight-bold ">{noticia.name}</h2>
                    </div>
                    <div className="col-12">
                      <span className="text-muted">{noticia.resumo}</span>
                    </div>
                    <div className="col-sm-12">
                      <hr />
                      <p className="text-justify" style={{ textIndent: '30px' }}>{noticia.paragraph1}</p>
                      <p className="text-justify" style={{ textIndent: '30px' }}>{noticia.paragraph2}</p>
                      <p className="text-justify" style={{ textIndent: '30px' }}>{noticia.paragraph3}</p>
                      <p className="text-justify" style={{ textIndent: '30px' }}>{noticia.paragraph4}</p>
                      <p className="text-justify" style={{ textIndent: '30px' }}>{noticia.paragraph5}</p>
                      <p className="text-justify" style={{ textIndent: '30px' }}>{noticia.paragraph6}</p>
                      <p className="text-justify" style={{ textIndent: '30px' }}>{paragraphWithBoldNumber214}</p>
                      <p className="text-justify" style={{ textIndent: '30px' }}>{noticia.paragraph8}</p>
                      <p className="text-justify" style={{ textIndent: '30px' }}>{noticia.paragraph9}</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-4 mt-2">
                  <UltimasNoticias />
                </div>
              </div>
            </div>
            {/* <Footer /> */}
          </>
        )
      }
      else if(noticia.id == 2){
        return (
          <>
            <Navbar handleLogin={this.handleLogin} />
            <LoginForm handleLogin={this.handleLogin} />
            <div className="container pt-2 justify-content-center bg-white border border-top-0 border-bottom-0 border-right border-left " style={{ minHeight: '1000px' }}>
              <div className="row">
                <div className="col-sm-12 col-md-8">
                  <div className="row">
                    <div className="col-12">
                      <legend className="text-muted" style={{ fontSize: 'small' }}>{noticia.date}</legend>
                    </div>
                    <div className="col-12">
                      <h2 className="font-weight-bold ">{noticia.name}</h2>
                    </div>
                    <div className="col-12">
                      <span className="text-muted">{noticia.resumo}</span>
                    </div>
                    <div className="col-sm-12">
                      <hr />
                      <p className="text-justify" style={{ textIndent: '30px' }}>{noticia.paragraph1}</p>
                      <p className="text-justify" style={{ textIndent: '30px' }}>{noticia.paragraph2}</p>
                      <p className="text-justify" style={{ textIndent: '30px' }}>{noticia.paragraph3}</p>
                      <div id="img1" className="mb-2 text-center">
                        <img className="img-fluid" src={img1_noticia2}/>
                        <figcaption className="font-weight-bold mt-2" style={{fontSize: '14px'}}>Imagem 1: Mapa do Modelo Digital de Superfície - MDS no visualizador da IDE-SEFIN.</figcaption>
                      </div>
                      <div id="img2" className="mb-2 text-center">
                        <img className="img-fluid" src={img2_noticia2}/>
                        <figcaption className="font-weight-bold mt-2" style={{fontSize: '14px'}}>Imagem 2: Mapa do Modelo Digital de Terreno - MDT no visualizador da IDE-SEFIN.</figcaption>
                      </div>
                      <p className="text-justify" style={{ textIndent: '30px' }}>{noticia.paragraph4}</p>
                      <div id="img3" className="mb-2 text-center">
                        <img className="img-fluid" src={img3_noticia2}/>
                        <figcaption className="font-weight-bold mt-2" style={{fontSize: '14px'}}>Imagem 3: Legenda do mapa do Modelo Digital de Terreno - MDT.</figcaption>
                      </div>
                      <div id="img4" className="mb-2 text-center">
                        <img className="img-fluid" src={img4_noticia2}/>
                        <figcaption className="font-weight-bold mt-2" style={{fontSize: '14px'}}>Imagem 4: Detalhamento da altimetria de pontos específicos no mapa.</figcaption>
                      </div>
                      <p className="text-justify" style={{ textIndent: '30px' }}>{noticia.paragraph5}</p>
                      <div id="img5" className="mb-2 text-center">
                        <img className="img-fluid" src={img5_noticia2}/>
                        <figcaption className="font-weight-bold mt-2" style={{fontSize: '14px'}}>Imagem 5: Submenu “Agrupamentos” disponível no visualizador da IDE-SEFIN.</figcaption>
                      </div>
                      <p className="text-justify" style={{ textIndent: '30px' }}>{noticia.paragraph6}</p>
                      <div id="img6" className="mb-2 text-center">
                        <img className="img-fluid" src={img6_noticia2}/>
                        <figcaption className="font-weight-bold mt-2" style={{fontSize: '14px'}}>Imagem 6: Restituição de 2016 completa.</figcaption>
                      </div>
                      <p className="text-justify" style={{ textIndent: '30px' }}>{noticia.paragraph8}</p>
                      <p className="text-justify" style={{ textIndent: '30px' }}>{noticia.paragraph9}</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-4 mt-2">
                  <UltimasNoticias />
                </div>
              </div>
            </div>
            {/* <Footer /> */}
          </>
        )
      }
      else if (noticia.id == 3){
        return (
          <>
            <Navbar handleLogin={this.handleLogin} />
            <LoginForm handleLogin={this.handleLogin} />
            <div className="container pt-2 justify-content-center bg-white border border-top-0 border-bottom-0 border-right border-left " style={{ minHeight: '1000px' }}>
              <div className="row">
                <div className="col-sm-12 col-md-8">
                  <div className="row">
                    <div className="col-12">
                      <legend className="text-muted" style={{ fontSize: 'small' }}>{noticia.date}</legend>
                    </div>
                    <div className="col-12">
                      <h2 className="font-weight-bold ">{noticia.name}</h2>
                    </div>
                    <div className="col-12">
                      <span className="text-muted">{noticia.resumo}</span>
                    </div>
                    <div className="col-sm-12">
                      <hr />
                      <p className="text-justify" style={{ textIndent: '30px' }}>{noticia.paragraph1}</p>
                      <p className="text-justify" style={{ textIndent: '30px' }}>{noticia.paragraph2}</p>
                      <p className="text-justify" style={{ textIndent: '30px' }}>{noticia.paragraph3_a}<b >{noticia.paragraph3_b}</b>
                      {noticia.paragraph3_c}
                      </p>
                      <h6 className="font-weight-bold text-justify justify-content-right"><u>REGULAMENTO</u></h6>
                      <p className="text-justify" style={{ textIndent: '30px' }}>{noticia.paragraph4_a}
                      <u>{noticia.paragraph4_b}</u>
                      {noticia.paragraph4_c}
                      <u>{noticia.paragraph4_d}</u>
                      {noticia.paragraph4_e}
                      <u>{noticia.paragraph4_f}</u>
                      {noticia.paragraph4_g}
                      </p>
                      <p className="text-justify" style={{ textIndent: '30px' }}>{noticia.paragraph5}</p>
                      <p className="text-justify" style={{ textIndent: '30px' }}>{noticia.paragraph6_a}</p>
                      <p className="text-justify" style={{ textIndent: '30px' }}>{noticia.paragraph6_b}</p>
                      <p className="text-justify" style={{ textIndent: '30px' }}>{noticia.paragraph6_c}</p>
                      <p className="text-justify" style={{ textIndent: '30px' }}>{noticia.paragraph6_d}</p>
                      <p className="text-justify" style={{ textIndent: '30px' }}>{noticia.paragraph6_e}</p>
                      <p className="text-justify" style={{ textIndent: '30px' }}>{noticia.paragraph6_f}</p>
                      <p className="text-justify" style={{ textIndent: '30px' }}>{noticia.paragraph6_g}</p>
                      <p className="text-justify" style={{ textIndent: '30px' }}>{noticia.paragraph6_h}</p>
                      <p className="text-justify" style={{ textIndent: '30px' }}>{noticia.paragraph7}</p>
                      <p className="text-justify font-weight-bold" style={{ textIndent: '30px' }}>{noticia.paragraph8}</p>
                      <p className="text-justify" style={{ textIndent: '30px' }}><a href={`${noticia.paragraph9}`}>{noticia.paragraph9}</a></p>
                      <p className="text-justify" style={{ textIndent: '30px' }}>{noticia.paragraph10}</p>
                      <p className="text-justify" style={{ textIndent: '30px' }}><u>{noticia.paragraph11.slice(0,parseInt(noticia.paragraph11.indexOf(':')))}</u>{noticia.paragraph11.slice(parseInt(noticia.paragraph11.indexOf(':')))}</p>
                      <p className="text-justify" style={{ textIndent: '30px' }}><u>{noticia.paragraph12.slice(0,parseInt(noticia.paragraph12.indexOf(':')))}</u>{noticia.paragraph12.slice(parseInt(noticia.paragraph12.indexOf(':')))}</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-4 mt-2">
                  <UltimasNoticias />
                </div>
              </div>
            </div>
            {/* <Footer /> */}
          </>
        )
      }
    } else {
      window.location.href = '/'; // Redirect to the home page
      return null;
    }
  }
}

export default withRouter(Noticia)