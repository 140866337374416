import React from 'react';
import imagem1 from './1.png';
import imagem2 from './2.png';
import brasaopmf from './logopmf.png';
import brasaopmf2 from './logopmf2.png';
import brasaopmf3 from './logopmf3.png';
import './carousel.css';
class Carousel extends React.Component {
  render() {
  return (
    <div id="carouselHome" className="carousel slide text-dark" data-ride="carousel" style={{top: '0px'}}>
      <ol className="carousel-indicators">
        <li data-target="#carouselHome" data-slide-to="0" className="active"></li>
        <li data-target="#carouselHome" data-slide-to="1"></li>
      </ol>
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img src={imagem1} className="d-block img-carousel" alt=""/>

          <div className="carousel-caption ">
            <div className='mensagem-slide-1'>
              {/* <div className='mensagem-text-1'>
                "Tornar as informações geográficas acessíveis por meio da cooperação do setor público, da academia e do setor privado, para o aprimoramento da gestão das políticas públicas vinculadas ao território e a promoção do desenvolvimento econômico e social sustentável do município de Fortaleza."
              </div> */}
              {/* <div className='logo-mensagem-1'>
                <img src={brasaopmf3} className="img-fluid" alt="" />
              </div> */}
            </div>
          </div>
        </div>
        <div className="carousel-item">
          <img src={imagem2}className="d-block img-carousel" alt=""/>
          <div className="carousel-caption ">
            {/* <div className='logo-mensagem-2'>
                <img src={brasaopmf2} className="img-fluid" alt="" />
            </div> */}
            {/* <div className='mensagem-slide-2'>
                <i>"Toda ação da SEFIN é para tornar Fortaleza um lugar melhor para se viver"</i>
            </div> */}
          </div>
        </div>
      </div>
      <a className="carousel-control-prev" href="#carouselHome" role="button" data-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="sr-only">Previous</span>
      </a>
      <a className="carousel-control-next" href="#carouselHome" role="button" data-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="sr-only">Next</span>
      </a>
    </div>
    );
  }
 }

 export default Carousel;