import React from "react";
import { Card } from "react-bootstrap";
import Col from 'react-bootstrap/Col'

const EventList = ({eventos, escolheEvento}) => {
  const listaEventos = eventos.map((evento, idx) => {
    return (
      <Col xs lg="4">
        <Card >
          <Card.Body>
            <Card.Title>{evento}</Card.Title>
            <Card.Text>
            </Card.Text>
          <Card.Link href="#" onClick={() => {escolheEvento(evento)}}>Assistir</Card.Link>
          </Card.Body>
        </Card>
      </Col>
    );
  });

  return (
    <div className='lista-eventos'>{listaEventos}</div>
  )
}

export default EventList;