import { UPDATE_MAP, TOGGLE_VISIBLE_LAYER, HIDE_LAYER, SHOW_LAYER, SET_OPACITY, SET_BBOX_MAP, SET_STYLE_LAYER} from './actionTypes';

export const updateLayers = (layers, map) => ({
  type: UPDATE_MAP,
  layers: layers,
  map: map
})

export const toggleVisible = (layerSource) => ({
  type: TOGGLE_VISIBLE_LAYER,
  layerSource: layerSource
})

export const hideLayer = (layerSource) => ({
  type: HIDE_LAYER,
  layerSource: layerSource
})

export const showLayer = (layerSource) => ({
  type: SHOW_LAYER,
  layerSource: layerSource
})
export const setBBoxLayer = (bbox) => ({
  type: SET_BBOX_MAP,
  bbox: bbox
})

export const setStyleLayer = (layerSource, style) => ({
  type: SET_STYLE_LAYER,
  layerSource: layerSource,
  style: style

})

export const setOpacity = (layerSource, opacity) => ({
  type: SET_OPACITY,
  layerSource: layerSource,
  opacity: opacity,
})
