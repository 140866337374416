import React from 'react';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Transparency from './Transparency';
import Legend from './Legend';
import Download from './Download';
import ChoroplethClasses from './ChoroplethClasses';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactDOM from "react-dom";
import BtnMetadata from '../BtnMetadata';
import {loadGetCapabilities} from '../../../utils/loadGetCapabilities';
import {loadMetadataGeoNetwork} from '../../../utils/loadMetadataGeoNetwork';
import {setMetadataLayers} from '../../../actions/metadataLayersActions';
import {setLayersGeoserver} from '../../../actions/layerGeoserverActions';


require( "./styles.scss");

class OptionsPopover extends React.Component {
  constructor(props) {
    super(props)
    this.getCurrentLayer = this.getCurrentLayer.bind(this);
    this.getCurrentGeoserveLayer = this.getCurrentGeoserveLayer.bind(this);
    this.getButtonPosition = this.getButtonPosition.bind(this);
    this.setArrowPosition = this.setArrowPosition.bind(this);
    this.setPopoverPosition = this.setPopoverPosition.bind(this);
    this.onImageLoad = this.onImageLoad.bind(this);
    //declare properties
    this.currentLayer = this.getCurrentLayer();
    this.idPopover = `popover-${this.props.layerSource.replace(':','_')}`
    if (this.currentLayer) {
      this.currentGeoserverLayer   = this.getCurrentGeoserveLayer(this.currentLayer);
      this.getCurrentMetadataLayer = this.getCurrentMetadataLayer(this.currentLayer);
    }

    this.state = {
      imageLoaded: false
    }
    loadGetCapabilities({props: this.props, filter: 'ACCESSIBLE_VISUALIZADOR'});
    loadMetadataGeoNetwork({props: this.props})
  }

  getCurrentLayer(){
    let layerSource = this.props.layerSource;
    let currentLayer;
    this.props.layers['array_'].forEach ( function (layer) {
      if (layer.get('layerSource') === layerSource) {
        currentLayer = layer
      }
    })
    return currentLayer;
  }

  getCurrentGeoserveLayer(currentLayer){
    let currentGeoserverLayer;
    this.props.layersGeoserver.forEach ( function (layerGeoserver) {
      if (currentLayer.get('layerSource') === layerGeoserver['layerSource']) {
        currentGeoserverLayer = layerGeoserver;
      }
    })
    return currentGeoserverLayer;
  }

  getCurrentMetadataLayer(currentLayer) {
    if (this.currentGeoserverLayer && this.props.metadataLayers.length > 0) {
      const identifier = this.currentGeoserverLayer.metadata.identifier 
      return this.props.metadataLayers.find((i) => (i.identifier === identifier))
    }

  }

  onImageLoad(){
    // o popover deve ser centralizado ao botão que o ativa
    let buttonPosition = this.getButtonPosition()
    let popover = document.getElementById(this.idPopover)
    if (popover) {
      this.setPopoverPosition(buttonPosition, popover)
      this.setArrowPosition(buttonPosition, popover)
    }

  }

  getButtonPosition(){
    let button = ReactDOM.findDOMNode(this);
    if (button) { return button.getBoundingClientRect().top }
  }

  setArrowPosition(reference, popover){
    let arrows = document.getElementsByClassName("arrow")
    let currentPosition = ReactDOM.findDOMNode(popover).getBoundingClientRect()
    for(let i = 0; i < arrows.length; i ++){
      arrows[i].style.top = `${reference-currentPosition['top']+4}px`
    }
  }

  setPopoverPosition(reference, popover){
    let currentPosition = ReactDOM.findDOMNode(popover).getBoundingClientRect()
    let valores = popover.style.transform.match(/\d+px/g)
    // define o top como o espaço necessário para centralizar o meio do popover com o botão
    let top = (reference + 4) - (currentPosition['height']/2)
    // trata casos em que o popover possa sair da tela
    if (top < 0 ) {
      top = 0
    } else if (window.innerHeight < top + currentPosition['height']) {
      top = top - ((top + currentPosition['height']) - window.innerHeight)
    }
    popover.style.transform = `translate3d(${valores[0]}, ${top}px, ${valores[2]})`
  }

  render () {
    const popover = (
      <Popover placement='left' className="custom-popover" id={this.idPopover}>
        <Popover.Title as="h3">Preferências</Popover.Title>
        <Popover.Content>
          <div style={{maxWidth: 380}}>
            <b>
              {this.props.layerName}
            </b>
          </div>
          <BtnMetadata  
            layerSource={this.props.layerSource}
            currentLayer={this.currentLayer}
            currentGeoserverLayer={this.currentGeoserverLayer}
            currentMetadataLayer={this.getCurrentMetadataLayer}
          />
          <Legend
            layerSource={this.props.layerSource}
            currentLayer={this.currentLayer}
            currentGeoserverLayer={this.currentGeoserverLayer}
            onLoad={this.onImageLoad}
          />
          <Transparency
            layerSource={this.props.layerSource}
            currentLayer={this.currentLayer}
            currentGeoserverLayer={this.currentGeoserverLayer}
          />
          <ChoroplethClasses
            layerSource={this.props.layerSource}
            currentLayer={this.currentLayer}
            currentGeoserverLayer={this.currentGeoserverLayer}
          />
          <Download
            layerSource={this.props.layerSource}
            currentLayer={this.currentLayer}
            currentGeoserverLayer={this.currentGeoserverLayer}
          />
        </Popover.Content>
      </Popover>
    );

    return (
      <OverlayTrigger
        trigger="click"
        placement='left'
        overlay={popover}
        rootClose
      >
        <button type='button' className={`btn btn-link btn-options btn-options-${this.props.customStyle}`}  >
          <span className='oi oi-menu' />
        </button>
      </OverlayTrigger>
    )
  }
}

const mapStateToProps = (store, ownProps) => {
  return {
    layers: store.layersState.layers,
    last_updated: store.layersState.last_updated,
    layersGeoserver: store.layersGeoserverState.layersGeoserver,
    metadataLayers: store.metadataLayersState.metadata
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({setMetadataLayers, setLayersGeoserver}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OptionsPopover);