import React from 'react';
import Navbar from '../components/home/Navbar';
import AreaDeBusca from '../components/home/AreaDeBusca';
import { connect } from 'react-redux';
import Footer from '../components/home/Footer';
import Banner from '../components/home/Banner'
import Servicos from '../components/home/Servicos';
import './Home.css'
import TemasCamadas from '../components/home/TemasCamadas';
import Parceiros from '../components/home/Parceiros';
import EstatisticaGeral from '../components/home/EstatisticaGeral';
import Noticias from '../components/home/Noticias';
import CamadaDestaque from '../components/home/CamadaDestaque';
import LoginForm from '../components/Login/Form'
import TermsModal from '../components/Termos'

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showTermsModal: true, // Initialize the state for showing the modal
    };
  }

  handleCloseTermsModal = () => {
    this.setState({ showTermsModal: false }); // Close the modal
    if (!window.localStorage.getItem('aceitoTermoUso')) {
      window.localStorage.setItem('aceitoTermoUso', true)
    }
  };

  handleLogin = () => {
    this.setState({ last_login: Date.now() })
    // this.props.map.updateSize(); 
    // if (document.getElementById('close-card-pmf')) {
    //   document.getElementById('close-card-pmf').click();
    // }

    // FIXME: Resolve situation when not rerender components after login
    window.location.reload();
  }



  render() {
    return (
      <>
        <header className="" >
          <Navbar handleLogin={this.handleLogin} />
          <LoginForm handleLogin={this.handleLogin} />
        </header>
        <main className="container border-primary" >
          <div className="row justify-content-center">
            <div className='col-12'>
              <div className='row my-5'>
                <section className="col-sm-12 col-md-6">
                  <AreaDeBusca />
                </section>
                <section className="col-sm-12 col-md-6" >
                  <Banner />
                </section>
              </div>
            </div>
            {/* <div className="col-12 mb-2">
              <div className='row mt-2'>
              </div>
            </div> */}
            <section className="col-sm-12 bg-white drop-shadow-servicos">
              <Servicos />
            </section>
            <div className="col-12 bg-white drop-shadow-camadas">
              <div className='row d-flex align-items-stretch'>
                <section className="col-sm-12 col-md-6 d-flex" style={{marginLeft: '-8px'}}>
                  <CamadaDestaque />
                </section>
                <section className="col-sm-12 col-md-6 d-flex" >
                  <div className='row ' style={{paddingLeft: '15px'}}>
                    <div className='col-12' id='noticias' style={{marginLeft: '5px'}} >
                      <Noticias />
                    </div>
                    <div className='col-12 mt-auto'>
                      <EstatisticaGeral />
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <section className='col-12 bg-white drop-shadow-temas'>
              <div className='container-fluid'>
                <div className='row justify-content-center'>
                  <div className='col-12'>
                    <TemasCamadas />
                  </div>
                </div>
              </div>
            </section>
            <section className='col-12  px-0 drop-shadow-temas'>
              <Parceiros />
            </section>
            <section className='col-12 drop-shadow-footer'  >
              <Footer />
            </section>
          </div>
          <TermsModal isOpen={this.state.showTermsModal} onClose={this.handleCloseTermsModal} />
        </main>
      </>
    )
  }
}

const mapStateToProps = (store, ownProps) => {
  return { map: store.layersState.map }
}

export default connect(mapStateToProps, null)(Home);