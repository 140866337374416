import React from 'react';
import HeaderNav from '../header';
import '../home/subnav.css';
import './index.css'
import LoginForm from '../Login/Form';
import Navbar from '../home/Navbar';
import Footer from '../home/Footer';
class ErrorPage extends React.Component {
  handleLogin = () => {
    this.setState({last_login: Date.now()})

    window.location.reload();
  }

  render() {
    return (
      <div className="Error"> 
        <Navbar handleLogin={this.handleLogin}/>
        {/* <HeaderNav menu='home' customClass='fixed-top' handleLogin={this.handleLogin}/> */}
        <LoginForm handleLogin={this.handleLogin}/>

        <div className='py-5 bg-light col-lg-12 col-md-12 col-sm-12'>
          <div className='d-flex  justify-content-center' style={{ height: '100vh'}}>
            <div className='align-self-center'>
              <h1 className='error-title' >OPS :(</h1>
              <p className='error-message'>Ocorreu um erro! Tente novamente mais tarde.</p>
            </div>
          </div>
        </div>
        <div className='container-fluid'>
          <Footer/>
        </div>
      </div>
    );
  } 
}

export default ErrorPage;