
import {capitalizeFirstLetter} from './camelcase';
export function humanize(text) {
  let result = '';
  result = replaceBefore(text).split('_').map((x) => {
    return `${result} ${replacePMF(x)}`
  })
  // text = text.replace(/\_/g, ' ')
  return capitalizeFirstLetter(result.join('').trim());
}

function replacePMF(text){
  text = text.replace(/^(qtd|quant)$/g, 'quantidade de')
  text = text.replace(/^(tipo)$/g, 'tipo de')
  text = text.replace(/^(vl)$/g, 'valor de')
  text = text.replace(/^(nao)$/g, 'não')
  text = text.replace(/^(edif|edit)$/g, 'edificação')
  text = text.replace(/^(condominio)$/g, 'condomínio')
  text = text.replace(/^(soma)$/g, 'soma de')
  text = text.replace(/^(class)$/g, 'classificação')
  text = text.replace(/^(lancamentos)$/g, 'lançamentos')
  text = text.replace(/^(arborizacao)$/g, 'arborização')
  text = text.replace(/^(viaria|viar)$/g, 'viária')
  text = text.replace(/^(agua)$/g, 'água')
  text = text.replace(/^(iluminacao)$/g, 'iluminação')
  text = text.replace(/^(nomecondominio)$/g, 'nome do condomínio')
  text = text.replace(/^(perimeter|perimet)$/g, 'perímetro')
  text = text.replace(/^(m)$/g, 'metros')
  text = text.replace(/^(cartogr)$/g, 'cartografia')
  text = text.replace(/^(data)$/g, 'data de')
  text = text.replace(/^(num)$/g, 'número de')
  text = text.replace(/^(c)$/g, 'com')
  text = text.replace(/^(criacao)$/g, 'criação')
  text = text.replace(/^(alteracao)$/g, 'alteração')
  text = text.replace(/^(area)$/g, 'área')
  text = text.replace(/^(versao)$/g, 'versão')
  text = text.replace(/^(zonaesp)$/g, 'zona especial')
  text = text.replace(/^(DECRET)$/g, 'DECRETO')
  text = text.replace(/^(compl)$/g, 'complemento')
  text = text.replace(/^(ibg)$/g, 'IBGE')
  text = text.replace(/^(m2)$/g, 'm²')
  text = text.replace(/^(compriment)$/g, 'comprimento')
  text = text.replace(/^(insc)$/g, 'inscrição')
  text = text.replace(/^(inscricoes)$/g, 'inscrições')
  text = text.replace(/^(tot)$/g, 'total')
  text = text.replace(/^(tot)$/g, 'total')
  text = text.replace(/^(terr)$/g, 'terreno')
  return text
}

function replaceBefore(text){
  text = text.replace(/^(tes_mai_vl)$/g, 'ano de exercício 2020')
  text = text.replace(/^(vl_fq_2020)$/g, 'Codlog do logradouro da testada de maior valor')
  text = text.replace(/^(areas_edificacao)$/g, 'áreas edificadas')
  text = text.replace(/^(padrao_acabamento_nome)$/g, 'padrão de acabamento')
  text = text.replace(/^(logradouro_id)$/g, 'Codlog')
  text = text.replace(/^(tipo)$/g, 'tipo ')
  
  
  return text 
}