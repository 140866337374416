import React,{ createRef } from "react";
import Swal from "sweetalert2";

export default function AuthenticatedLink({ url, filename, children }) {
  const link = createRef();
  const handleAction = async () => {
    if (link.current.href) {
      return;
    }
    
    const username = window.sessionStorage.getItem("username");
    const password = window.sessionStorage.getItem("password");
    let headers = {};
    if (username && password) {
      headers = {
        Authorization: `Basic ${btoa(`${username}:${password}`)}`,
      };
    }

    Swal.fire({
      icon: 'success',
      title: 'Baixando',
      text: 'Seu download começará em breve',
    })
    const result = await fetch(url, {
      headers: new Headers(headers)
    });
    
    const blob = await result.blob();
    const href = window.URL.createObjectURL(blob);
    
    link.current.download = filename;
    link.current.href = href;
    
    link.current.click();
    
  };

  return (
    <>
      <a role="button" ref={link} onClick={handleAction} target='_blank' className="download-link" rel="noopener noreferrer">
        {children}
      </a>
    </>
  );
}
