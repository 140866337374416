import React from 'react';
import HeaderNav from '../components/header';
import Carousel from '../components/home/Carousel';
import Card from '../components/home/Card';
import '../components/home/subnav.css';
import {loadMetadataGeoNetwork} from '../utils/loadMetadataGeoNetwork';
import {setMetadataLayers} from '../actions/metadataLayersActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import LoginForm from '../components/Login/Form';
import Navbar from '../components/home/Navbar';
import Footer from '../components/home/Footer';
import './Home.css'
import {
  NavLink
} from "react-router-dom";
class Home extends React.Component {

  constructor(props) {
    super(props)
    const InitialState = {
      metadata: []
    }
    this.state = {
      ...InitialState,
      authenticated : true,
      username: '',
      password: '',
    }
    loadMetadataGeoNetwork({props: this.props})
  }

  metadataAll() {
    return this.props.metadataLayers;
  }

  handleLogin = () => {
    this.setState({last_login: Date.now()})
    // this.props.map.updateSize(); 
    // if (document.getElementById('close-card-pmf')) {
    //   document.getElementById('close-card-pmf').click();
    // }

    // FIXME: Resolve situation when not rerender components after login
    window.location.reload();
  }

  render() {
    if(window.localStorage.getItem('aceitoTermoUso')){
      return (
        <div className="Home">
            {/* <HeaderNav menu='home' customClass='fixed-top' handleLogin={this.handleLogin}/> */}
            <Navbar handleLogin={this.handleLogin}/>
            <div className='container bg-white'>
    
              <LoginForm handleLogin={this.handleLogin}/>
              {/* <Carousel/> */}
              {/* <nav className="nav navbar-dark subnavbar-pmf justify-content-center"> */}
                {/* <div className='container'> */}
                {/* <NavLink activeClassName='active' exact={true} className="nav-link" to='/'>Mapas Disponíveis</NavLink>
                  <NavLink activeClassName='active' exact={true} className="nav-link" to='/geoservicos'>Geoserviços</NavLink>
                  <NavLink activeClassName='active' exact={true} className="nav-link" to='/downloads'>Downloads</NavLink> */}
                {/* </div> */}
              {/* </nav> */}
              <h1 className='text-center py-2'>Metadados</h1>
              <div className='py-5'>
                  <div className='container'>
                    <div className='row'>
                      {
                        this.metadataAll().map((data) => {
                          
                          return <Card key={data.identifier} {...data} />
                        })
                      }
                    </div>
                  </div>
              </div>
            </div>
            {/* <div className='container-fluid'>
              <Footer/>
            </div> */}
        </div>
      );
      
    }else{
      window.location.href = '/'; // Redirect to the home page
      return null;
    }
  }
}


const mapStateToProps = (store, ownProps) => {
  return {metadataLayers: store.metadataLayersState.metadata}
}
const mapDispatchToProps = dispatch =>
  bindActionCreators({ setMetadataLayers}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Home);