import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function BtnMetadata(props) {

  const onClick = () => {
    const modal_app = document.getElementById('modal-app');
    if (props.currentGeoserverLayer) {
      modal_app.getElementsByClassName('modal-body')[0].innerHTML = props.currentGeoserverLayer.abstract
      modal_app.getElementsByClassName('modal-title')[0].innerHTML = props.currentGeoserverLayer.layerTitle
    } else {
      modal_app.getElementsByClassName('modal-body')[0].innerHTML = 'Sem informações'
      modal_app.getElementsByClassName('modal-title')[0].innerHTML = ''
    }

  }
  return (
    <div>
      <button type="button" className="btn blue-theme btn-sm" onClick={() => onClick()} data-toggle="modal" data-target="#modal-app">
        <FontAwesomeIcon icon='link'/>
        <span> Descrição</span>
      </button>
    </div>
  )
}
