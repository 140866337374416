import {SET_METADATA_LAYERS} from '../actions/actionTypes';

const initial = {
  metadata: []
};



export const metadataLayersReducer = (state = initial , action) => {

  switch (action.type) {
    case SET_METADATA_LAYERS:
      return { ...state, metadata: action.metadata};
    default:
      return state;
  }
}
