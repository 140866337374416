//MOVER PARA O REDUX

export function getActiveLayers (layers) {
  let activeLayers = []
  layers.array_.map( (olLayer) => {
    if(olLayer.getVisible()) {
      if (isMapaBase(olLayer)) { olLayer.setZIndex(-1) }
      let ordered_index = getOrderedIndex(activeLayers, olLayer.getZIndex())
      activeLayers.splice(ordered_index, 0, olLayer);
    } else {
      olLayer.setZIndex(undefined)
    }
    return null
  })

  return orderActiveLayersZIndex(activeLayers);
}

function getOrderedIndex(array, zindex) {
  //initialize
  let ordered_index = 0;
  if (array.length === 0 ) { return ordered_index }

  ordered_index = array.findIndex((layer) => { return zindex > layer.getZIndex() })
  if (ordered_index  === -1 ) { ordered_index = array.length }
  return ordered_index;
}

export function moveActiveLayer(index, direction, activeLayers) {
  // direction can be 1 or -1
  let currentArray = activeLayers
  let currentZindex = currentArray[index].getZIndex()
  let currentLayer = activeLayers[index]
  // swap zindex
  currentArray[index].setZIndex(currentArray[index+direction].getZIndex())
  currentArray[index+direction].setZIndex(currentZindex)
  // swap array items
  currentArray[index] = currentArray[index+direction]
  currentArray[index+direction] = currentLayer
  return currentArray
}


export function orderActiveLayersZIndex(activeLayers) {
  let layers = activeLayers
  layers.forEach( function(layer, index) {
    layer.setZIndex(layers.length - index)
  })
  return layers
}

function isMapaBase(layer) {
  return (
    (
      layer.values_.layerSource.split(':')[0] === 'CAMADA_BASE' ||
      ['OSM', 'XYZ'].includes(layer.values_.layerSource)
    )
    && layer.getZIndex() === undefined
  )
}