import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare, faMap } from '@fortawesome/free-regular-svg-icons'
import { faDatabase, faCloud } from '@fortawesome/free-solid-svg-icons'
import WFS from '.././SideNav/Popover/wfs.png'
import WMS from '.././SideNav/Popover/wms.png'
import Download from './EstatisticaGeralSvgs/download.svg'
import Metadados from './EstatisticaGeralSvgs/metadados.svg'
import './EstatisticaGeral.css'

class EstatisticaGeral extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className='container-fluid text-white py-2 px-4' style={{ marginBottom: '15px', fontSize: '18px', background: '#10949E', borderColor: 'rgb(255 255 255 / 13%)', borderRadius: "20px 20px 20px 20px" }}>
        <div className='row '>
          <div className='col-12 mt-1'>
            <span className='float-right' style={{ fontFamily: 'Palaquin Dark Regular', fontSize: '16px' }}>272 camadas GeosServiços WFS</span>
            <img src={WFS} style={{ width: '23px' }} className='wfs' />
          </div>
          <div className='col-12 mt-1'>
            <span className='float-right' style={{ fontFamily: 'Palaquin Dark Regular', fontSize: '16px' }}>286 camadas GeosServiços WMS</span>
            <img src={WMS} style={{ width: '23px' }} className='wms' />
          </div>
          <div className='col-12 mt-1'>
            <span className='float-right' style={{ fontFamily: 'Palaquin Dark Regular', fontSize: '16px' }}>199 Dados no Catálogo de Metadados </span>
            <img src={Metadados} style={{ width: '20px', height: '20px' }} />
          </div>
          <div className='col-12 mt-1'>
            <span className='float-right' style={{ fontFamily: 'Palaquin Dark Regular', fontSize: '16px' }}>274 Camadas para Download</span>
            <img src={Download} style={{ width: '20px', height: '20px' }} />
          </div>
        </div>
      </div>

    )
  }

}

const mapStateToProps = (store, ownProps) => {
  return { map: store.layersState.map }
}

export default connect(mapStateToProps, null)(EstatisticaGeral);