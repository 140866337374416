import React from 'react';
import './Legend.css'
import { connect } from 'react-redux';




class Legend extends React.Component {
  state = { items: [] };
  

  componentWillReceiveProps(nextProps) {
    const {bbox} =  this.props;

    let layers = []
    if (bbox.length === 0) {
      return true;
    }
    
    this.props.layers.forEach((l) => {
      if (l.getVisible() && layers.filter((item) => {return item.includes(l.get('layerSource'))}).length === 0 ) {
        if(l.get('layerSource') !== 'OSM' && l.get('layerSource') !== 'XYZ') {
          layers.push([l.get('layerSource'),l.get('name'), l.get('currentStyle') || ''])
         }
      }
    });

    
    const promises = this.promisesRequests(bbox, layers)
    // if (layers.length > 0) {
      // result =  (async (promises) => {
          Promise.all(
            promises
          )
          .then(items => {
            this.setState({items});
            
          })
      // })
    // }

    

  }


  promisesRequests = (bbox, layers) => {
    const username = window.sessionStorage.getItem('username')
    const password = window.sessionStorage.getItem('password')
    let credentials;
    if (username && password) 
      credentials = `Basic ${btoa(`${username}:${password}`)}`;
    
    
    return layers.map((layer) => {
      return new Promise((resolve, reject) => {
        const url = `${process.env.REACT_APP_GEOSERVER_URL}/wms?REQUEST=GetLegendGraphic&VERSION=1.1.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&STYLE=${layer[2]}&LAYER=${layer[0]}&LEGEND_OPTIONS=forceLabels:on;fontAntiAliasing:true;countMatched:false;fontName:sans;hideEmptyRules:false;forceTitles:off&BBOX=${bbox.toString()}`;
        var xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.open('GET', url);
        xhr.setRequestHeader('Authorization', credentials);
        xhr.onload = function(e) {
          if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
            const imageSRC = URL.createObjectURL(xhr.response);
            const legend_id = `legend-${layer[0].replace(':','_')}`
            resolve({layers_source: layer[0], layer_name: layer[1], image_url: imageSRC, legend_id: legend_id });
           }
        }
        xhr.send();
      });
    })
    
  }

  render() {
    const { items } = this.state;
    return (
      <div className={this.props.displayLegendArea ? 'card' : 'card d-none'} id='card-legend' aria-hidden='true'>
        <div className='card-header card-header-title'>
          <button type='button' className='close' id='close-card-pmf' data-dismiss='card' aria-label='Close' onClick={() => this.props.toggleLegendArea()}>
            <span aria-hidden='true'>&times;</span>
          </button>
          <h6 className='card-title' id='card-title-pmf'>Legenda</h6>
        </div>
        <div className='card-body card-body-pmf' id='card-body-pmf'>
          <div id='card-content' className='content-card content-card-legend'>
          {items.map((item,index) => (
            <div key={index}>
            <h6>{item.layer_name}</h6>
            <img src={item.image_url} className='img-fluid' id={item.legend_id} alt='legenda'/>
            <hr className='hr-legend'/>
            </div>
          ))}
            {/* {this.getLegendImage(bbox)} */}
          </div>
          <small id="passwordHelpInline" className="text-muted">
            * Valores entre parênteses representam a quantidade de feições na área de visalização do mapa
          </small>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store, ownProps) => {
  return {layers: store.layersState.layers,
     last_updated: store.layersState.last_updated,
     bbox: store.layersState.bbox
    }
}
export default connect(mapStateToProps, null)(Legend);

