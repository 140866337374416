import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './TemasCamadas.css'
// import LimitesAdministrativos from './TemasCamadasSvgs/limites.svg'
import MVS from './TemasCamadasSvgs/modelos.svg'
import OMI from './TemasCamadasSvgs/OMI.svg'
import Imageamento from './TemasCamadasSvgs/imageamento.svg'
// import IPTU from './TemasCamadasSvgs/IPTU.svg'
// import ISSQN from './TemasCamadasSvgs/ISSQN.svg'
import { ReactComponent as ArrowRight } from './TemasCamadasSvgs/arrowRight.svg'
import { ReactComponent as ArrowLeft } from './TemasCamadasSvgs/arrowLeft.svg'
import Zoneamento from './zoneamento.svg'
import LimitesAdministrativos from './limites_administrativos.svg'
import IBGE from './ibge.svg'
import { faCompass } from '@fortawesome/free-regular-svg-icons'
import CartografiaBase from './cartografia_base.svg'
import Aerofotogametria from './aerofotogametria.svg'
import Aerolevantamento from './aerolevantamento.svg'
import IPTU from './iptu.svg'
import ITBI from './itbi.svg'
import ISSQN from './issqn.svg'
import solo from './uso_solo.svg'
import MercadoImobiliario from './mercado_imobiliario.svg'
import GEOSERVER from './GEOSERVER_removebg.png'
import GEONETWORK from './GEONETWORK_removebg.png'

import Fill from 'ol/style/Fill';
class TemasCamadas extends React.Component {
  constructor(props) {
    super(props);
  }


  render() {
    return (
      <div className=' bg-white  mt-2 mb-3' style={{ borderColor: 'rgb(255 255 255 / 13%)' }}>
        <h2 className='fs-title-temas rounded-pill font-weight-bold text-center text-white text-uppercase mt-4 p-2' style={{ backgroundColor: '#10949E' }}>Navegue nas nossas camadas por temas</h2>
        <div className='row justify-content-center mb-2'>
          <div className='col-11 card-services d-flex justify-content-center'>
            <div className=''>
              <div className='text-center ' >
                <a href={`${process.env.REACT_APP_GEONETWORK_URL}`}>
                  <img src={GEONETWORK} className='img-fluid px-2' />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className='row justify-content-center mb-2'>
          <div className='col-11 card-services d-flex justify-content-center'>
            <div className=''>
              <div className='text-center ' >
                <a href={`${process.env.REACT_APP_GEOSERVER_URL}`}>
                  <img src={GEOSERVER} className='img-fluid px-2' />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-3 col-md-6 col-sm-6 col-6 my-1' >
              <div className='card-icons' style={{ backgroundColor: '#00000008' }}>
                <div style={{ padding: '8px 5px 8px 5px' }}>
                  <a href={`${process.env.REACT_APP_GEONETWORK_URL}/por/catalog.search#/search?any=zoneamentos`}>
                    <span className='span-title'><img src={Zoneamento} /> Zoneamento </span>
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-6  col-6 '>
              <div className='card-icons' style={{ backgroundColor: '#00000008' }}>
                <div style={{ padding: '8px 5px 8px 5px' }}>
                  <a href={`${process.env.REACT_APP_GEONETWORK_URL}/por/catalog.search#/search?resultType=details&sortBy=relevance&any=LIMITES_ADMINISTRATIVOS&from=1&to=20`} >
                    <span className='span-title'> <img src={LimitesAdministrativos} /> Limites Administrativos </span>
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-6 col-6 my-1 '>
              <div className='card-icons' style={{ backgroundColor: '#00000008' }}>
                <div style={{ padding: '8px 5px 8px 5px' }}>
                  <a href={`${process.env.REACT_APP_GEONETWORK_URL}/por/catalog.search#/search?resultType=details&sortBy=relevance&any=IBGE&from=1&to=20`}>
                    <span className='span-title'><img src={IBGE} /> IBGE </span>
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-6 col-6 my-1 '>
              <div className='card-icons' style={{ backgroundColor: '#00000008' }}>
                <div style={{ padding: '8px 5px 8px 5px' }}>
                  <a href={`${process.env.REACT_APP_GEONETWORK_URL}/por/catalog.search#/search?resultType=details&sortBy=relevance&any=CARTOGRAFIA_BASE&from=1&to=20`}>
                    <span className='span-title'><img src={CartografiaBase} /> Cartografia Base </span>
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-6 col-6 my-1 '>
              <div className='card-icons' style={{ backgroundColor: '#00000008' }}>
                <div style={{ padding: '8px 5px 8px 5px' }}>
                  <a href={`${process.env.REACT_APP_GEONETWORK_URL}/por/catalog.search#/search?any=IMAGEAMENTO`}>
                    <span className='span-title'> <img src={Aerofotogametria} /> Aerofotogametria </span>
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-6 col-6 my-1 '>
              <div className='card-icons' style={{ backgroundColor: '#00000008' }}>
                <div style={{ padding: '8px 5px 8px 5px' }}>
                  <a href={`${process.env.REACT_APP_GEONETWORK_URL}/por/catalog.search#/search?any=AEROLEVANTAMENTO`} >
                    <span className='span-title'> <img src={Aerolevantamento} /> Aerolevantamento</span>
                  </a>
                </div>
              </div>
            </div>


            <div className='col-lg-3 col-md-6 col-sm-6 col-6 my-1 '>
              <div className='card-icons' style={{ backgroundColor: '#00000008' }}>
                <div style={{ padding: '8px 5px 8px 5px' }}>
                  <a href={`${process.env.REACT_APP_GEONETWORK_URL}/por/catalog.search#/search?any=IPTU`}>
                    <span className='span-title'><img src={IPTU} /> IPTU </span>
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-6 col-6 my-1 '>
              <div className='card-icons' style={{ backgroundColor: '#00000008' }}>
                <div style={{ padding: '8px 5px 8px 5px' }}>
                  <a href={`${process.env.REACT_APP_GEONETWORK_URL}/por/catalog.search#/search?any=ITBI`}>
                    <span className='span-title'><img src={ITBI} /> ITBI </span>
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-6 col-6 my-1 '>
              <div className='card-icons' style={{ backgroundColor: '#00000008' }}>
                <div style={{ padding: '8px 5px 8px 5px' }}>
                  <a href={`${process.env.REACT_APP_GEONETWORK_URL}/por/catalog.search#/search?any=ISSQN`} className='text-wrap'>
                    <span className='span-title'>   <img src={ISSQN} /> ISSQN </span>
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-6  col-6 my-1'>
              <div className='card-icons' style={{ backgroundColor: '#00000008' }}>
                <div style={{ padding: '8px 5px 8px 5px' }}>
                  <a href={`${process.env.REACT_APP_GEONETWORK_URL}/por/catalog.search#/search?any=USO_SOLO`} className='text-wrap'>
                    <span className='span-title'><img src={solo} /> Uso solo </span>
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-6  col-6 my-1'>
              <div className='card-icons' style={{ backgroundColor: '#00000008' }}>
                <div style={{ padding: '8px 5px 8px 5px' }}>
                  <a href={`${process.env.REACT_APP_GEONETWORK_URL}/por/catalog.search#/search?any=MVS`}>
                    <span className='span-title'> <img src={MercadoImobiliario} /> Observatório do Mercado Imobiliário</span>
                  </a>
                </div>
              </div>
            </div>
          </div >
        </div >
      </div >
    )
  }
}

const mapStateToProps = (store, ownProps) => {
  return { map: store.layersState.map }
}

export default connect(mapStateToProps, null)(TemasCamadas);