import React from 'react';
import './header.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  NavLink
} from "react-router-dom";
class HeaderNav extends React.Component {

  signOut(){
    setTimeout( function() {
      window.location.reload()
    }
    , 3500);
  }

  triggerClick =(className) => {
    document.getElementById(className).click()
  }


  showFormLogin = () => {
    const login_body = document.getElementById('login-body');
    login_body.classList.toggle("d-none");
  }

  logout = () => {
    window.sessionStorage.removeItem('username')
    window.sessionStorage.removeItem('password')
    this.props.handleLogin();

  }
  


  render() {
    const {menu} = this.props;
    const username = window.sessionStorage.getItem('username')
    const password = window.sessionStorage.getItem('password')
    let btn_login;
    if (username  && password ) {
      btn_login =  <>
                    <li className="nav-item">
                      <a className="nav-link" href="#" >
                      <FontAwesomeIcon icon='user'/>
                      <span>{username}</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#" onClick={this.logout} >
                      <FontAwesomeIcon icon='sign-out-alt'></FontAwesomeIcon>
                      <span>Sair</span>
                      </a>
                    </li>
                  </>
    } else {
      btn_login =  <li className="nav-item">
                    <a className="nav-link" href="#" onClick={this.showFormLogin} >
                    <FontAwesomeIcon icon='user'/>
                    <span>Autenticar-se</span>
                    </a>
                  </li>

    }
    

    let menu_result;
    if(menu === 'home') {
      menu_result = <ul className="navbar-nav justify-content-end">
                      <li className="nav-item ">
                        <NavLink activeClassName='active' exact={true} className="nav-link" to='/'>
                            <FontAwesomeIcon icon='home'></FontAwesomeIcon>
                            <span>Início</span>
                        </NavLink>
                      </li>
                      <li className="nav-item ">
                        <NavLink activeClassName='active' exact={true} className="nav-link" to='/eventos'>
                            <FontAwesomeIcon icon='calendar'></FontAwesomeIcon>
                            <span>Eventos</span>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink activeClassName='active' className="nav-link" to='/sobre'>
                            <FontAwesomeIcon icon='network-wired'></FontAwesomeIcon>
                            <span>Sobre a IDE</span>
                        </NavLink>
                      </li>
                      <li className="nav-item ">
                        <NavLink activeClassName='active' className="nav-link" to='/contato'>
                            <FontAwesomeIcon icon='envelope-square'></FontAwesomeIcon>
                            <span>Contato</span>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="/visualizador" >
                          <FontAwesomeIcon icon='globe-americas'></FontAwesomeIcon>
                          <span>Ir para o Visualizador</span>
                        </a>
                      </li>
                     {btn_login}
                    </ul>

    } else {
      menu_result = <ul className="navbar-nav justify-content-end">
                      <li className="nav-item">
                        <a className="nav-link" href="/">
                          <FontAwesomeIcon icon='home'></FontAwesomeIcon>
                          <span>Início</span>
                        </a>
                      </li>
                      {/* <li className="nav-item">
                        <a className="nav-link" href="/search">
                          <FontAwesomeIcon icon='search-location'></FontAwesomeIcon>
                          <span>Buscar</span>
                        </a>
                      </li> */}
                      <li className="nav-item">
                        <a className="nav-link" href="#!" onClick={() =>this.triggerClick('btn-basemap')}>
                          <FontAwesomeIcon icon='map'></FontAwesomeIcon>
                          <span>Mapa Base</span>
                        </a>
                      </li>
                    
                      {btn_login}
                    </ul>

    }
    return (
      <>
        <nav className={`navbar navbar-expand-lg  navbar-dark navbar-pmf shadow ${this.props.customClass}`}>
        <a className="navbar-brand logo" href="/"> </a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
          </ul>
          {menu_result}
        </div>
        </nav>
      </>
    );
  }
}

export default HeaderNav;