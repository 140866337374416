import StyleStroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import StyleFill from 'ol/style/Fill';
import StyleText from 'ol/style/Text';
import StyleCircle from 'ol/style/Circle';
import SLDParser from "geostyler-sld-parser";
import OpenLayersParser from "geostyler-openlayers-parser";


export const setStyleFromSLD = function(style, layer){
  if (style.match(/\:/)) {
    style = `workspaces/${style.split(':')[0]}/styles/${style.split(':')[1]}`
  } else {
    style = `styles/${style}`
  }
  const url = `${process.env.REACT_APP_GEOSERVER_URL}/rest/${style}.sld`
  const sldParser = new SLDParser();
  const olParser = new OpenLayersParser();
  
  const result = (async (url) => {
    // const response = await fetch(url, {credentials: 'include'});
    const response = await fetch(url, {
      // credentials: 'include',
      method: 'GET',
      headers: new Headers({
        "Authorization": `Basic ${btoa(`${process.env.REACT_APP_USER_GEOSERVER}:${process.env.REACT_APP_PASSWORD_GEOSERVER}`)}`
      }),
      mode: 'cors',
      cache: 'default'
    });
    const sld = await response.text();
    const geostylerStyle = await sldParser.readStyle(sld);
    const olStyle = await olParser.writeStyle(geostylerStyle);
    layer.setStyle(olStyle);
    return olStyle;
  })
  result(url)

}

export const stylePointWFS = () => {
    return new Style({
        image: new StyleCircle({
          radius: 5,
          fill: new StyleFill({color: 'rgba(255, 255, 255, 0.0)'}),
          stroke: new StyleStroke({
            color: 'rgba(0, 0, 0, 0.0)',
             width: 1
          })
        })
    })
  }

export const styleWFS = () => {

  return new Style({
    fill: new StyleFill({
        color: 'rgba(255, 255, 255, 0.0)'
        //color: getColor(feature)
    }),
    stroke: new StyleStroke({
        color: 'rgba(0, 0, 0, 0.2)',
        //   color: '#319FD3',constructor(options) {
        width: 1
    }),
    text: new StyleText({
        //text: feature.get(entity.label_attr()),
        font: '12px Calibri,sans-serif',
        overflow: true,
        fill: new StyleFill({
            color: '#000'
        }),
        stroke: new StyleStroke({
            color: '#fff',
            width: 1
        })
    })
})
}