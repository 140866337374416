import React from 'react';
import './listGroup.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons';

class ListGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: this.props.active || false,
    };
    this.menu_id = this.props.menu;
    this.listChildren = this.listChildren.bind(this);
  }

  changeColorCardHeader = (e) => {
    let header = e.target;
    // Check if the target element is an SVG element
    if (!(header instanceof HTMLDivElement)) {
        // Get the parent element of the SVG
        header = header.closest('.card-header');
    } 
    if(this.props.colorable){
      // Handle the case where the target is not an SVG element
      if (header.style.backgroundColor === '' && !this.state.active && this.hasChildren(header)) {
          header.style.backgroundColor = '#ffcc00';
      } else if (header.style.backgroundColor === '#ffcc00' && this.state.active) {
          header.style.backgroundColor = '';
      } else {
          header.style.backgroundColor = '';
      }
      
    }
};


  hasChildren() {
    return React.Children.count(this.props.children) > 0;
  }

  listChildren() {
    this.setState((prevState) => ({
      active: !prevState.active,
    }));
  }

  handleChildEvent(e) {
    e.stopPropagation();
  }

  render() {
    let layerProps
    if(this.props.colorable && this.state.active){
      layerProps = "col-12 card-header my-1 text-start font-weight-bold bg-menu-layer"
    }else if(!this.props.colorable && this.state.active){
      layerProps = "col-12 card-header my-1 text-start font-weight-bold bg-open-layer"
    }else{
      layerProps = "col-12 card-header my-1 text-start font-weight-bold bg-closed-layer"
    }

    var id_with_underline = this.props.menu_id.replaceAll('-','_').replaceAll(' ','_')
    return (
      <li className='list-unstyled margin-layer' onClick={this.listChildren} id={id_with_underline}>
        <div className={`${layerProps}`} style={{ cursor: 'pointer', color: 'black', borderRadius: '10px',  display: 'flex', justifyContent: 'space-between', fontSize: '15px' }} onClick={this.changeColorCardHeader}>
          {this.props.groupName}
            {this.state.active ? <FontAwesomeIcon icon={faAngleDown} /> : <FontAwesomeIcon icon={faAngleRight} />}
        </div>
        <ul className={this.state.active ? 'list-group list-group-flush nested active' : 'list-group list-group-flush nested'} onClick={(e) => this.handleChildEvent(e)}>
          {this.props.children}
        </ul>
      </li>
    );
  }
}

export default ListGroup;
