import React from 'react';
import { connect } from 'react-redux';
import {showGridInfo, hideGridinfo, clearGridinfo} from '../../actions/featureActions';

import {humanize} from '../../utils/humanize.js';
import { bindActionCreators } from 'redux';

import  './gridinfo.css';
class GridInfo extends React.Component {

  generateDataTable = (data, key) => {
    const layers = Array.from(new Set(data.map((i) => (i.name))))
    return (
      layers.map(layer => {
        let key = layer.replace(/\s/g, '-');
        let data_filtered = data.filter(r => {return r.name === layer});
        return (
          <div className='card card-gridinfo'  key={key} >
              <div className="bg-gridinfo bg-info card-header-gridinfo" id={`heading-${key}`}>
                  <button className="btn btn-link text-white " type="button" data-toggle="collapse" data-target={`#info-${key}`} aria-expanded="true" aria-controls="collapseOne">
                    <span>
                    {`   ${layer}. ${data_filtered.length} resultado(s)`}
                    </span>
                  </button>
              </div>
              <div id={`info-${key}`}  className={`collapse `}  aria-labelledby={`heading-${key}`} >
                  {
                    data_filtered.map((feature, key2) => {
                      return (
                        <table key={key2} className='table table-bordered table-hover table-striped table-sm'>
                          <tbody >
                            {this.generateDataTableBody(feature.properties)}
                          </tbody>
                        </table>
                    )
                  })
                }
              </div>
          </div>

        )

      })
    )
  }

  generateDataTableBody = (feature) => {
    let keys = Object.keys(feature)

    return keys.map((key, index) => {
      let f = feature[key] ? feature[key] :  '';
      if(!isNaN(f)) {
        f = f.toLocaleString('pt-BR', {useGrouping: false})
      }
      if(key !== 'geometry' && key !== 'bbox') {
        return(
          <tr key={key} >
            <th>{humanize(key)}</th>
            <td>{f}</td>
          </tr>
        )
      }
      return null;
    })

  }

  generateDataTables(contentGridInfoWMS, contentGridInfoWFS, contentGridInfoWMTS) {
    if (
      this.props.displayGridInfo &&
      ((contentGridInfoWMS && contentGridInfoWMS.length > 0) ||
        (contentGridInfoWFS && contentGridInfoWFS.length > 0) ||
        (contentGridInfoWMTS && contentGridInfoWMTS.length > 0))
    ) {
      return (
        <div>
          {contentGridInfoWMS && contentGridInfoWMS.length > 0 && this.generateDataTable(contentGridInfoWMS, 'WMS')}
          {contentGridInfoWFS && contentGridInfoWFS.length > 0 && this.generateDataTable(contentGridInfoWFS, 'WFS')}
          {contentGridInfoWMTS && contentGridInfoWMTS.length > 0 && this.generateDataTable(contentGridInfoWMTS, 'WMTS')}
        </div>
      );
    } else {
      return <div>Clique sobre uma camada para ver mais informações</div>;
    }
  }

  componentDidMount(){
    this.props.clearGridinfo()
  }


  render() {
    const { contentGridInfoWMS, contentGridInfoWFS, contentGridInfoWMTS } = this.props;

    return (

      <div id='gridContentSelected'>
        {/* <div className="card-header card-header-title">
          <button type="button" className="close" data-dismiss="modal" aria-label="Fechar" onClick={() => hideGridinfo()}>
            <span aria-hidden="true">&times;</span>
          </button>
            <h6 className="card-title" id="card-title-pmf">
              Informações da seleção
            </h6>
        </div>
        <div className=''>
          <span className='text-muted'></span>
        </div> */}
          <div className="accordion" id="accordionExample">
          {this.generateDataTables(contentGridInfoWMS, contentGridInfoWFS, contentGridInfoWMTS)}
          </div>
      </div>
    );
  }
}


const mapStateToProps = (store, ownProps) => {
  return {
    displayGridInfo:  store.FeatureInfoState.displayGridInfo,
    contentGridInfoWMS:   store.FeatureInfoState.contentGridInfoWMS,
    contentGridInfoWFS:   store.FeatureInfoState.contentGridInfoWFS,
    contentGridInfoWMTS: store.FeatureInfoState.contentGridInfoWMTS,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ showGridInfo, hideGridinfo, clearGridinfo}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GridInfo);
// export default GridInfo;