import React from 'react';
import OlMap from 'ol/Map';
import View from 'ol/View';
import {defaults as defaultControls} from 'ol/control';
import ScaleLine from 'ol/control/ScaleLine';
import ZoomSlider from 'ol/control/ZoomSlider';
import MousePosition from 'ol/control/MousePosition';
import {createStringXY} from 'ol/coordinate';
import SliderTimeSeries from './sliderTimeSeries'
import 'ol/ol.css';
import './zoomslider.css';
import {register} from 'ol/proj/proj4';
// import {get as getProjection} from 'ol/proj';
import proj4 from 'proj4';
import Layer from './layers/Layer';
import {highlightStyleWFS} from '../../utils/OlMapHighlightStyle';
import {setLayersGeoserver} from '../../actions/layerGeoserverActions';
import {setGridInfo, concatGridInfo, clearGridinfo} from '../../actions/featureActions';
import {updateLayers, setBBoxLayer} from '../../actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {capitalize} from '../../utils/camelcase';
import {loadGetCapabilities} from '../../utils/loadGetCapabilities';
import './Map.css'
import Legend from './legends';
import MapFooter from './Footer'
import TileWMS from 'ol/source/TileWMS';

class Map extends React.Component {
  constructor(props) {
    super(props);
    const InitialState = {
      center: [557753.3887873373, 9579354.728086209],
      zoom: 12,
      minZoom: 11,
      maxZoom: 22,
      layers: [],
    };
    // Define Projeção EPSG:31984
    proj4.defs(
      "EPSG:31984",
      "+proj=utm +zone=24 +south +ellps=WGS84 +datum=WGS84 +units=m +no_defs"
    );
    register(proj4);

    this.state = { ...InitialState };
    
  }

  initializeMap = () => {
    this.mousePositionControl = new MousePosition({
      coordinateFormat: createStringXY(2),
      projection: "EPSG:31984",
      target: document.getElementById("coordenadas-tag"),
      undefinedHTML: "&nbsp;",
    });

    this.selected = null;
    this.olmap = new OlMap({
      controls: defaultControls().extend([
        new ScaleLine({ units: "metric", bar: true, steps: 2, text: true }),
        new ZoomSlider(),
        this.mousePositionControl,
      ]),
      // overlays: [overlay],
      layers: [],
      view: new View({
        projection: "EPSG:31984",
        extent: [
          459177.4133,
          9530530.2265,
          601359.5133,
          9648424.2058,
        ],
        center: this.state.center,
        zoom: this.state.zoom,
      }),
    });
    loadGetCapabilities({
      props: this.props,
      filter: "ACCESSIBLE_VISUALIZADOR",
      forceMapComponentUpdate: this.forceMapComponentUpdate,
    });
  }

  // handlerLayers = () => {
  //   this.props.updateLayers(this.olmap.getLayers())
  // }

  forceMapComponentUpdate = () => {
    // Force a render with a simulated state change
    this.forceUpdate();
  };
  componentDidMount = () => {
    this.initializeMap();
    let map = this.olmap;
    map.setTarget("map");

    // Listen to map changes
    map.on("moveend", () => {
      let center = map.getView().getCenter();
      let zoom = map.getView().getZoom();
      this.props.setBBoxLayer(map.getView().calculateExtent(map.getSize()));
      this.setState({ center, zoom });
    });

    map.on("pointermove", this.pointerMove);
    map.on("singleclick", this.singleClick);

    this.updateMap(); // Update map on render?
  };

  pointerMove = (evt) => {
    let map = this.olmap;
    let selected = this.selected;
    const props = this.props;

    // let features = [];
    // Remove efect hover when not mousehover on the layer
    if (selected !== null) {
      selected.setStyle(undefined);
    }

    map.forEachFeatureAtPixel(evt.pixel, function (feature, layer) {
      if (selected !== null) {
        selected.setStyle(undefined);
        selected = null;
      }
      selected = feature;

      feature.setStyle(highlightStyleWFS(feature));
      return feature;
    });

    if (evt.dragging) {
      return;
    }
    let pixel = map.getEventPixel(evt.originalEvent);
    let hit = map.forEachLayerAtPixel(pixel, function (ft, l) {
      return ft;
    });
    if (props.btnInfoActive) {
      map.getTargetElement().style.cursor = hit ? "pointer" : "";
    } else {
      map.getTargetElement().style.cursor = "";
    }
    this.selected = selected;
  };

  singleClick = (evt) => {
    let map = this.olmap;
    //let layer  = null
    let btnGridInfo = document.getElementById('btn-gridinfo')
    
    btnGridInfo.click()
    // this.props.setBtnInfoActive()
    const props = this.props;
    try {
      if (props.btnInfoActive) {
        props.clearGridinfo();
        let features = [];
        
        map.forEachFeatureAtPixel(evt.pixel, function (ft, l) {
          let item = { properties: null, name: null };
          item["name"] = l.getProperties().name;
          item["properties"] = ft.getProperties();
          features.push(item);
        });
        if (features.length > 0) {
          props.setGridInfo(features, "wfs");
        }
        let layers = [];
        map.forEachLayerAtPixel(evt.pixel, function (ft, l) {
          layers.push(ft);
          // return ft;
        });
        if (layers.length > 0) {
          layers.forEach(function (layer) {
            let view = map.getView();
            let viewResolution = /** @type {number} */ (view.getResolution());
            let wmsSource = layer.getSource();
            if (wmsSource.constructor.name === "VectorSource") {
              // Retorna todos os dados da Camada.
              // props.setGridInfo(wmsSource.getFeatures()[0].getProperties())
            } else {
              let url = null

              // cria camada WMS caso camada pesquisada seja WMTS, caso contrário usa camada WMS ex
              if(layer.values_.source.urls[0].includes('wmts')){
                
                let layerWMS = new TileWMS({
                  url: `${process.env.REACT_APP_GEOSERVER_URL}/wms`,
                  params: {
                    'LAYERS': layer.values_.layerSource,
                    'VERSION': '1.1.1',
                  },
                  serverType: 'geoserver',
                  crossOrigin: 'anonymous'
                })
                
                url = layerWMS.getFeatureInfoUrl(
                  evt.coordinate,
                  viewResolution,
                  "EPSG:31984",
                  {
                    INFO_FORMAT: "application/json",
                    FEATURE_COUNT: 50
                  }
                )

                
              }else{
                url = wmsSource.getFeatureInfoUrl(
                  evt.coordinate,
                  viewResolution,
                  "EPSG:31984",
                  {
                    INFO_FORMAT: "application/json",
                    FEATURE_COUNT: 50,
                  }
                  );
              }
              

              
              if (url) {
                const username = window.sessionStorage.getItem("username");
                const password = window.sessionStorage.getItem("password");
                let headers = {};
                if (username && password) {
                  headers = {
                    Authorization: `Basic ${btoa(`${username}:${password}`)}`,
                  };
                }
                
                fetch(url, { headers: new Headers(headers) })
                  .then(function (response) {
                    return response.json();
                  })
                  .then(function (json) {
                     // props.concatGridInfo(json.features.filter(i => ( i.id)).map(f =>
                     props.concatGridInfo(
                      json.features.map((f) => ({
                        properties: f.properties,
                        name:
                          capitalize(f.id.split(".")[0].replace(/\_/g, " ")) ||
                          "Raster",
                      })),
                      "wms"
                    );
                  })
                  .catch(function (err) {
                    console.error(err);
                    console.error('Não foi possível recuperar informações da Camada');
                    
                  });
              }
            }
          });
        }
      }
    } catch {
      props.setGridInfo([]);
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    let center = this.olmap.getView().getCenter();
    let zoom = this.olmap.getView().getZoom();

    if (center === nextState.center && zoom === nextState.zoom) return false;
    // nextProps.updateLayers(null, this.olmap)
    return true;
  }

  updateMap() {
    this.olmap.getView().setCenter(this.state.center);
    this.olmap.getView().setZoom(this.state.zoom);
    this.props.updateLayers(null, this.olmap);
  }
  const;

  authenticationRequiredLayers = () => {
    if (this.props.layersGeoserver.length === 0) {
      return;
    }
    return (
      <div>
        {this.props.layersGeoserver.map((info) => {
          let result;
          let wfs = info.keywordList.includes("WFS_VISUALIZADOR");
          let wms = info.keywordList.includes("WMS_VISUALIZADOR");
          let wmts = info.keywordList.includes("WMTS_VISUALIZADOR")
          let visible = info.keywordList.includes("VISIBLE_VISUALIZADOR");
          let style = info.style;
          let options = info.options
          let key = `${info.layerSource}-${info.layerName}`;
          //let enable = info.keywordList.includes('ENABLE_VISUALIZADOR');
          if (wfs === true && wms === false && wmts === false) {
            result = (
              <Layer
                handleLogin={this.props.handleLogin}
                key={`${key}-wfs`}
                layerName={info.layerName}
                layerSource={info.layerSource}
                options={options}
                visible={visible}                
                service="WFS"
                style={style}
              />
            );
          }
          if (wms || (wfs === false && wms === false && wmts === false)) {
            result = (
              <Layer
                handleLogin={this.props.handleLogin}
                key={`${key}-wms`}
                layerName={info.layerName}
                layerSource={info.layerSource}
                visible={visible}
                options={options}
                timeseries={info.timeseries}
                service="WMS"
                style={style}
              />
            );
          }

          if (wmts === true && wms === false && wfs === false){
            result = (
              <Layer
                handleLogin={this.props.handleLogin}
                key={`${key}-wmts`}
                layerName={info.layerName}
                layerSource={info.layerSource}
                visible={visible}
                options={options}
                timeseries={info.timeseries}
                service="WMTS"
                style={style}
              />
            )
          }

          return result;
        })}
        <Legend />
      </div>
    );
  };

  setTipoCoordenadas = (tipo) => {
    this.mousePositionControl.setProjection(tipo);
  };

  render() {
    let layers = this.authenticationRequiredLayers();
    // this.updateMap(); // Update map on render?
    const username = window.sessionStorage.getItem("username");
    const password = window.sessionStorage.getItem("password");
    let visibleOSM = false;
    let visibleMapaBaseFortaleza = false;

    visibleOSM = false;
    visibleMapaBaseFortaleza = true;
    let layerBaseFortaleza;
    // Set default layer Base
    // if (username && password) {
      visibleOSM = false;
      let options = null
      visibleMapaBaseFortaleza = true;
      layerBaseFortaleza = (
        <Layer
          handleLogin={this.props.handleLogin}
          layerName="Mapa Fortaleza"
          layerSource="CAMADA_BASE:mapa_fortaleza"
          visible={visibleMapaBaseFortaleza}
          options={options}
          service="WMS"
          style={{}}
        />
      );
    // }

    return (
      <>
        <div id="map" className="map">
          <Layer
            handleLogin={this.props.handleLogin}
            layerName="OpenStreetMap"
            layerSource="OSM"
            visible={visibleOSM}
            options={options}
            service="WMS"
            style={{}}
          />
          <Layer
            handleLogin={this.props.handleLogin}
            layerName="Google Mapas"
            layerSource="XYZ"
            visible={false}
            options={options}
            service="WMS"
            style={{}}
          />
          {layerBaseFortaleza}
          {layers}
          {/* {
          React.Children.map(this.props.children,  layer => {
            return React.cloneElement(layer, {olmap: this.olmap})
          })
        } */}       
        </div>
        <SliderTimeSeries/>
       <MapFooter setTipoCoordenadas={this.setTipoCoordenadas} /> 
      </>
    );
  }
}


const mapStateToProps = (store, ownProps) => {
  return {layersGeoserver: store.layersGeoserverState.layersGeoserver, btnInfoActive: store.sidenavBtnState.btnInfo}
}
const mapDispatchToProps = dispatch =>
  bindActionCreators({ setLayersGeoserver, setGridInfo, concatGridInfo, clearGridinfo, updateLayers, setBBoxLayer}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Map);
// export default Map;
