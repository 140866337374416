import React from 'react';
import ListMenu from '../ListMenu';
import ListGroup from '../../ListGroup'
import { connect } from 'react-redux';
import { capitalize } from '../../../../utils/camelcase.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { builderMenu, builderGroupItems, renderListItem } from './common';

class CamadasPublicasDisponiveisListMenu extends React.Component {
  // builderList = (layer) => 
  //   renderListItem(layer, this.props);

  filterCamadas = () => {
    // Declare variables
    let input, filter, ul, li, a, i, txtValue;
    input = document.getElementById('filter-camadas');
    filter = input.value.toUpperCase();
    ul = document.getElementById("menu");
    li = ul.getElementsByTagName('li');

    // Loop through all list items, and hide those who don't match the search query
    for (i = 0; i < li.length; i++) {
      li[i].setAttribute("key",i)
      a = li[i].getElementsByTagName("label")[0];
      txtValue = a.textContent || a.innerText;
      if (txtValue.toUpperCase().match(filter) && filter.length > 0) {
        // add class active at parent element matched
        li[i].parentNode.classList.add('active')
        // add class active and red color at element matched
        li[i].classList.add('active')
        li[i].style.color = "var(--bg-color-pmf-buttons-active)";
        li[i].style.fontWeight = 'bold';
      } else {
        li[i].style.fontWeight = 'normal';
        
        // Remove class active at element not matched
        li[i].classList.remove('active')
        // Check if item active exist. When not exist set elment parent collapse.
        if (li[i].parentNode.getElementsByClassName('active').length === 0) {
          li[i].parentNode.classList.remove('active')
        }
        // add class gray color at element not matched
        li[i].style.color = "#888888";
      }

      // Reset color when not search filter value
      if (filter.length === 0) {
        li[i].style.color = "black";
      }
    }
  }

  render() {
    const { layersGeoserverPublic, slug  } = this.props;
    if (this.props.workspacesPublic.length === 0) return (<ListMenu></ListMenu>);
    this.props.treeMenuPublic.sort(dynamicSort("item"));
    return (
      <div>
        <div className={`form-group form-group-search`}>
          <input className="form-control form-control-sm" type="text"
          placeholder="" id='filter-camadas' onChange={() => this.filterCamadas()} />
          <FontAwesomeIcon icon='search'></FontAwesomeIcon>
        </div>
        <ListMenu>
          {
            this.props.treeMenuPublic.filter(item => item.item == slug).map((menu, index) => {
              return <ListGroup key={menu.id} groupName={capitalize(menu.item)} >
                {builderGroupItems(menu, layersGeoserverPublic, this.props)}
                {builderMenu(menu, layersGeoserverPublic, this.props)}
              </ListGroup>
            })
          }
        </ListMenu>
      </div>
    )
  };
}

const mapStateToProps = (store, ownProps) => {
  return {
    layersGeoserverPublic: store.layersGeoserverPublicState.layersGeoserverPublic,
    workspacesPublic: store.layersGeoserverPublicState.workspacesPublic,
    metadataLayers: store.metadataLayersState.metadata,
    treeMenuPublic: store.layersGeoserverPublicState.treeMenuPublic
  }
}

function dynamicSort(property) {
  var sortOrder = 1;
  if(property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
  }
  return function (a,b) {
      /* next line works with strings and numbers, 
       * and you may want to customize it to your needs
       */
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
  }
}

export default connect(mapStateToProps, null)(CamadasPublicasDisponiveisListMenu);

// export default AdicionarCamadaListMenu;
