import React from 'react';
import HeaderNav from '../components/header';
import Carousel from '../components/home/Carousel';
import Card from '../components/home/Card';
import '../components/home/subnav.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {setLayersGeoserver} from '../actions/layerGeoserverActions';
import CamadasDisponiveisListMenu from '../components/SideNav/ListMenu/AdicionarCamada/CamadasDisponiveisListMenuDownloads';
import {loadMetadataGeoNetwork} from '../utils/loadMetadataGeoNetwork';
import {setMetadataLayers} from '../actions/metadataLayersActions';
import {loadGetCapabilities} from '../utils/loadGetCapabilities';
import LoginForm from '../components/Login/Form';
import Navbar from '../components/home/Navbar';
import Footer from '../components/home/Footer';
import './Downloads.css'

import {
  NavLink
} from "react-router-dom";
class Downloads extends React.Component {

  constructor(props) {
    super(props)
    const InitialState = {
    }
    loadGetCapabilities({props: this.props, filter: 'ACCESSIBLE_VISUALIZADOR'});
    loadMetadataGeoNetwork({props: this.props})
  }
  
  handleLogin = () => {
    this.setState({last_login: Date.now()})
    // this.props.map.updateSize(); 
    // if (document.getElementById('close-card-pmf')) {
    //   document.getElementById('close-card-pmf').click();
    // }

    // FIXME: Resolve situation when not rerender components after login
    window.location.reload();
  }

  
    componentDidUpdate(){
      let lists = document.querySelectorAll('li.list-unstyled.margin-layer')
      // let aerolevantamentoElements = document.querySelectorAll('li#Aerolevantamento-2016');


      var ordered_list = Array.from(lists);

      // Sort the array by ID
      ordered_list.sort(function(a, b) {
        var idA = a.id;
        var idB = b.id;
        return idB.localeCompare(idA);
      });
      

      // lists.forEach
      if(ordered_list.length > 0){
        this.removeLi(ordered_list)
        // this.removeLi(aerolevantamentoElements)
      }
    }

    // Iterate through each 'Aerolevantamento-2016' li element
    removeLi(ul){
      ul.forEach(function (li) {
        // Find the nested ul within the current li element
        var nestedUl = li.querySelector('ul');
  
        // Check if the ul exists
        if (nestedUl) {
          // Check if all li elements inside the ul are empty
          var areAllLiEmpty = Array.from(nestedUl.children).every(function (nestedLi) {
            return !nestedLi.firstChild || (nestedLi.firstChild.nodeType === 3 && nestedLi.firstChild.data.trim() === '');
          });
  
          // If all li elements inside the ul are empty, remove the parent li
          if (areAllLiEmpty) {
            li.parentNode.removeChild(li);
          }
        }
      });
    }
  // }

  render() {
    if(window.localStorage.getItem('aceitoTermoUso')){
      return (
        <>
            <Navbar handleLogin={this.handleLogin}/>
        <div className="Downloads container bg-white"> 
            {/* <HeaderNav menu='home' customClass='fixed-top' handleLogin={this.handleLogin}/> */}
            <LoginForm handleLogin={this.handleLogin}/>
            {/* <Carousel/> */}
            {/* <nav className="nav navbar-dark subnavbar-pmf justify-content-center"> */}
              {/* <div className='container'> */}
                {/* <NavLink activeClassName='active' exact={true} className="nav-link" to='/'>Mapas Disponíveis</NavLink>
                <NavLink activeClassName='active' exact={true} className="nav-link" to='/geoservicos'>Geoserviços</NavLink>
                <NavLink activeClassName='active' exact={true} className="nav-link" to='/downloads'>Downloads</NavLink> */}
              {/* </div> */}
            {/* </nav> */}
            <h1 className='text-center  mb-0 py-2 ' style={{backgroundColor: ''}}>Downloads</h1>
            <div className='container-fluid  '>
    
            <div className='py-5'>
                <div className='container'>
                  <div className='mx-auto'>
                      <CamadasDisponiveisListMenu
                        showAbstractInfo={true}
                        updateActiveLayers={this.updateActiveLayers}
                        showCheckbox={false}
                        showDownloads={true}
                      />
                  </div>
                </div>
            </div>
            </div>
        </div>
            {/* <div className='container-fluid'>
              <Footer/>
            </div> */}
            <script>
              
            </script>
        </>
      );

    }else{
      window.location.href = '/'; // Redirect to the home page
      return null;
    }
  }
}

const mapStateToProps = (store, ownProps) => {
  return {layersGeoserver: store.layersGeoserverState.layersGeoserver, metadataLayers: store.metadataLayersState.metadata}
}
const mapDispatchToProps = dispatch =>
  bindActionCreators({ setLayersGeoserver, setMetadataLayers}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Downloads);