import React from 'react';

class Modal extends React.Component {
  render() {
    return (
      <div className={this.props.displayModal ? 'shadow card' : 'shadow card d-none'} id='card-pmf' aria-hidden='true' >
        <div className='card-header card-header-title'>
          <button type='button' className='close' id='close-card-pmf' data-dismiss='card' aria-label='Close' onClick={this.props.closeModal}>
            <span aria-hidden='true'>&times;</span>
          </button>
          <h6 className='card-title' id='card-title-pmf'>{this.props.modalTitle}</h6>
        </div>
        <div className='card-body card-body-pmf' id='card-body-pmf'>
          <div id='card-content' className='content-card'>
            <p className='card-text'></p>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;