import React from 'react';
import ListItemRadioButton from '../ListItemRadioButton'

class TrocarMapaBaseListMenu extends React.Component {
  constructor(props) {
    super(props);
    this.updateSelectedRadio = this.updateSelectedRadio.bind(this);
    this.groupName = 'IMAGENS BASE'
    const username = window.sessionStorage.getItem('username');
    const password = window.sessionStorage.getItem('password');
    // Set default layer Base
    let defaultLayerBase = 'Mapa Fortaleza'
    // if (!username && !password) {
    //   defaultLayerBase = 'OpenStreetMap'
    // } 
    this.state = {
      selectedRadio: this.props.layerBase || defaultLayerBase
    }
  }

  updateSelectedRadio(selectedRadioName) {
    this.props.handleSetLayerBase(selectedRadioName)
    this.setState(
      { selectedRadio: selectedRadioName }
    )
  }

  render() {
    const username = window.sessionStorage.getItem('username');
    const password = window.sessionStorage.getItem('password');
    let ortofotos, imagebaseFortaleza;
    {
      imagebaseFortaleza = <ListItemRadioButton
        idName='imagebase-fortaleza' layerName='Mapa Fortaleza'
        layerSource='CAMADA_BASE:mapa_fortaleza'
        selected={this.state.selectedRadio}
        updateSelectedRadio={this.updateSelectedRadio}
        parentName={this.groupName}
      />
      ortofotos = <>
        {/* <ListItemRadioButton
            idName='imagebase-ortofoto-2001' layerName='Ortofoto 2001'
            layerSource='IMAGEAMENTO:ortofoto_2001'
            selected={ this.state.selectedRadio }
            updateSelectedRadio={this.updateSelectedRadio}
            parentName = {this.groupName}
          /> */}
        {/* <ListItemRadioButton
            idName='imagebase-ortofoto-2004' layerName='Ortofoto 2004'
            layerSource='IMAGEAMENTO:ortofoto_2004'
            selected={ this.state.selectedRadio }
            updateSelectedRadio={this.updateSelectedRadio}
            parentName = {this.groupName}
          /> */}
        <ListItemRadioButton
          idName='imagebase-ortofoto-2010' layerName='Ortofoto 2010'
          layerSource='IMAGEAMENTO:ortofoto_2010'
          selected={this.state.selectedRadio}
          updateSelectedRadio={this.updateSelectedRadio}
          parentName={this.groupName}
        />
        <ListItemRadioButton
          idName='imagebase-ortofoto-2016' layerName='Ortofoto 2016'
          layerSource='IMAGEAMENTO:ortofoto_2016'
          selected={this.state.selectedRadio}
          updateSelectedRadio={this.updateSelectedRadio}
          parentName={this.groupName}
        />
      </>
    }
    return (
      <div className='row'>
        <ListItemRadioButton
          idName='imagebase-nenhum' layerName='Nenhum'
          layerSource='Nenhum'
          selected={this.state.selectedRadio}
          updateSelectedRadio={this.updateSelectedRadio}
          parentName={this.groupName}
        />
        {imagebaseFortaleza}
        <ListItemRadioButton
          idName='imagebase-osm' layerName='OpenStreetMap'
          layerSource='OSM'
          selected={this.state.selectedRadio}
          updateSelectedRadio={this.updateSelectedRadio}
          parentName={this.groupName}
        />
        <ListItemRadioButton
          idName='imagebase-gmapsroads' layerName='Google Mapas'
          layerSource='XYZ'
          selected={this.state.selectedRadio}
          updateSelectedRadio={this.updateSelectedRadio}
          parentName={this.groupName}
        />
        {ortofotos}
      </div>
      // <ListMenu>
      //   <ListGroup groupName='Imagens Base' active={true} >
      //     <ListItemRadioButton
      //       idName='imagebase-nenhum' layerName='Nenhum'
      //       layerSource='Nenhum'
      //       selected={ this.state.selectedRadio }
      //       updateSelectedRadio={this.updateSelectedRadio}
      //       parentName = {this.groupName}
      //     />
      //     <ListItemRadioButton
      //       idName='imagebase-fortaleza'  layerName='Mapa Fortaleza'
      //       layerSource='CAMADA_BASE:mapa_fortaleza'
      //       selected={ this.state.selectedRadio }
      //       updateSelectedRadio={this.updateSelectedRadio}
      //       parentName = {this.groupName}
      //     />
      //     <ListItemRadioButton
      //       idName='imagebase-osm'  layerName='OpenStreetMap'
      //       layerSource='OSM'
      //       selected={ this.state.selectedRadio }
      //       updateSelectedRadio={this.updateSelectedRadio}
      //       parentName = {this.groupName}
      //     />
      //     <ListItemRadioButton
      //       idName='imagebase-gmapsroads' layerName='Google Mapas'
      //       layerSource='XYZ'
      //       selected={ this.state.selectedRadio }
      //       updateSelectedRadio={this.updateSelectedRadio}
      //       parentName = {this.groupName}
      //     />
      //     <ListItemRadioButton
      //       idName='imagebase-ortofoto-2001' layerName='Ortofoto 2001'
      //       layerSource='IMAGEAMENTO:ortofoto_2001'
      //       selected={ this.state.selectedRadio }
      //       updateSelectedRadio={this.updateSelectedRadio}
      //       parentName = {this.groupName}
      //     />
      //     <ListItemRadioButton
      //       idName='imagebase-ortofoto-2004' layerName='Ortofoto 2004'
      //       layerSource='IMAGEAMENTO:ortofoto_2004'
      //       selected={ this.state.selectedRadio }
      //       updateSelectedRadio={this.updateSelectedRadio}
      //       parentName = {this.groupName}
      //     />
      //     <ListItemRadioButton
      //       idName='imagebase-ortofoto-2010' layerName='Ortofoto 2010'
      //       layerSource='IMAGEAMENTO:ortofoto_2016'
      //       selected={ this.state.selectedRadio }
      //       updateSelectedRadio={this.updateSelectedRadio}
      //       parentName = {this.groupName}
      //     />
      //     <ListItemRadioButton
      //       idName='imagebase-ortofoto-2016' layerName='Ortofoto 2016'
      //       layerSource='IMAGEAMENTO:ortofoto_2016'
      //       selected={ this.state.selectedRadio }
      //       updateSelectedRadio={this.updateSelectedRadio}
      //       parentName = {this.groupName}
      //     />
      //   </ListGroup>
      // </ListMenu>
    )
  };
}

export default TrocarMapaBaseListMenu;